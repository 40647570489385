import React, { Component } from 'react'
import { Container, Content } from 'native-base'
import { View, Text, ScrollView, Image } from 'react-native'
import { styles } from './MatchStyle.js'
import { connect } from 'react-redux'
import GameContainer from '../../common/GameContainer'
import MatchHeader from '../components/MatchHeader'
import MatchCard from '../components/MatchCard'
import MatchDisclosure from '../components/MatchDisclosure'
import MatchFooter from '../components/MatchFooter'
import LoanDomain from '../../../client/domain/LoanDomain'
import AdvertiserDisclosure from '../../common/forms/AdvertiserDisclosure'
import Disclosures from '../../common/forms/Disclosures'
import * as WebBrowser from 'expo-web-browser';
import v from 'valib'
import { trackClick, trackUserProperty } from '../../../mixins/Track'
import Icon from 'react-native-vector-icons/FontAwesome';
import Loader from 'react-loader-spinner'
import BrowserSniff from '../../common/BrowserSniff'
import { buildRateData } from '../../../helpers/rate-helper'
import { sortLoans } from '../../../services/MatchService'
const _ = require('underscore')

const LOAN_AMOUNT_RANGE_THRESHOLD = 10000
const LOAN_AMOUNT_NEAR_THRESHOLD = 1000
const mapStateToProps = () => {
  return {
  }
}

const mapDispatchToProps = {
}

const MatchLoading = () => {
  return (
    <View style={[styles.matches, { marginTop: 20 }]}>
      <Text style={styles.headerTextMobileLarge}>Hang Tight!</Text>
      <Text style={styles.descriptionTextMobileLarge}>We're working on matching you with top loan offers.</Text>
      <Loader type="ThreeDots" color="rgba(67,70,96, .5)" height="75" width="100" />
    </View>
  )
}

class MatchContainer extends Component {
  constructor(props) {
    super(props)

    this._offerHandler = this._offerHandler.bind(this)
    this._transitionToDisclosure = this._transitionToDisclosure.bind(this)
    this._transitionBack = this._transitionBack.bind(this)
    this._transitionToNoMatches = this._transitionToNoMatches.bind(this)
    this._transitionToTerms = this._transitionToTerms.bind(this)
    this._transitionToPrivacyPolicy = this._transitionToPrivacyPolicy.bind(this)
    this._transitionToSupport = this._transitionToSupport.bind(this)
    this._transitionToMatchDisclosure = this._transitionToMatchDisclosure.bind(this)
    this._transitionToDisclosure = this._transitionToDisclosure.bind(this)
    this._transitionToAdDisclosure = this._transitionToAdDisclosure.bind(this)
    this._runValidation = this._runValidation.bind(this)

    this.state = {
      showDisclosure: false, // default false as we don't want to show disclosure on load
      showMatchDisclosure: false,
      showAdDisclosure: false,
      rateResults: [],
      rateBlockInProgress: true, // default true as should start with loading view
      selectedOffer: ''
    }
  }

  componentDidMount() {
    const { route, navigation } = this.props
    if(route.params !== undefined) {
      const { state } = route.params
      state.type = 'Offers'
      const loanDomain = new LoanDomain()

      loanDomain.getRates(buildRateData(state))
      .then((rates) => this.setState({ rateResults: rates }))
      .catch((error) => this._transitionToNoMatches)
      .finally(() => this.setState({ rateBlockInProgress: false }, this._runValidation))
    } else {
      this.setState({ rateResults: Test, rateBlockInProgress: false }, this._runValidation)
      //this._transitionToNoMatches()
    }
  }

  _runValidation() {
    const { loanOffers } = this.state.rateResults
    if(v.Array.isEmpty(loanOffers) || v.Array.isEmpty(_.compact(loanOffers.map((offer) => offer.preQualified === true || offer.preApproved === true ? offer : null)))) {
      this._transitionToNoMatches()
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
  }

  _offerHandler(url) {
    // trackUserProperty('loan_offer_clicked', 'true')
    trackClick({ source: 'Loan Offer Click'})
    WebBrowser.openBrowserAsync(url)
  }

  _transitionBack() {
    this.setState({
      showDisclosure: false,
      showAdDisclosure: false,
      showMatchDisclosure: false
    })
  }

  _transitionToMatchDisclosure(selectedOffer) {
    trackClick({ source: 'Loan Offer Disclosure Click'})
    this.setState(({ showMatchDisclosure }) => ({
      showMatchDisclosure: true,
      selectedOffer: selectedOffer
    }));
  }

  _transitionToAdDisclosure() {
    trackClick({ source: 'Loan Ad Disclosure Click'})
    this.setState(({ showAdDisclosure }) => ({
      showAdDisclosure: true
    }))
  }

  _transitionToDisclosure() {
    trackClick({ source: 'Loan Disclosures Click'})
    this.setState(({ showDisclosure }) => ({
      showDisclosure: true
    }));
  }

  _transitionToNoMatches() {
    const { navigation } = this.props
    navigation.navigate('Subscribe')
  }

  _transitionToTerms() {
    trackClick({ source: 'LO Terms Click', value: 'https://financialstress.com/terms/'})
    WebBrowser.openBrowserAsync('https://financialstress.com/terms/');
  }

  _transitionToPrivacyPolicy() {
    trackClick({ source: 'LO Privacy Policy Click', value: ' https://financialstress.com/privacy-policy/'})
    WebBrowser.openBrowserAsync(' https://financialstress.com/privacy-policy/');
  }


  _transitionToSupport() {
    trackClick({ source: 'LO Contact Us Click', value: 'https://financialstress.com/contact-us/'})
    WebBrowser.openBrowserAsync('https://financialstress.com/contact-us/');
  }

  // <View style={[styles.matchRow, {marginTop: 30}]}>
  //   <Text style={styles.consentText} onPress={this._transitionToNoMatches}>No Offers Available (Screen)</Text>
  // </View>

  render() {
    const { showDisclosure, showMatchDisclosure, showAdDisclosure, rateResults, rateBlockInProgress, selectedOffer } = this.state
    const { loanOffers, loanAmount } = rateResults
    const hasLoanOffers = !v.Array.isEmpty(loanOffers) ? !v.Array.isEmpty(_.compact(loanOffers.map((offer) => offer.preQualified === true || offer.preApproved === true ? offer : null))) : false

    if(showAdDisclosure) {
      return (
        <GameContainer>
          <AdvertiserDisclosure
            transitionBack={this._transitionBack}
          />
        </GameContainer>
      )
    }

    if(showMatchDisclosure) {
      return (
        <GameContainer>
          <MatchDisclosure
            offer={selectedOffer}
            transitionBack={this._transitionBack}
          />
        </GameContainer>
      )
    }

    if(showDisclosure) {
      return (
        <GameContainer>
          <Disclosures
            transitionBack={this._transitionBack}
          />
        </GameContainer>
      )
    }

    if(rateBlockInProgress || !hasLoanOffers) {
      return (
        <MatchLoadingQuery />
      )
    } else {
      const sortedLoanOffers = sortLoans(loanAmount, loanOffers)
      return (
        <MatchQuery
        loanAmount={loanAmount}
        loanOffers={sortedLoanOffers}
        offerHandler={this._offerHandler}
        transitionToTerms={this._transitionToTerms}
        transitionToPrivacyPolicy={this._transitionToPrivacyPolicy}
        transitionToSupport={this._transitionToSupport}
        transitionToAdDisclosure={this._transitionToAdDisclosure}
        transitionToDisclosure={this._transitionToDisclosure}
        transitionToMatchDisclosure = {this._transitionToMatchDisclosure}
      />
    )
  }

  };
}

const MatchLoadingQuery = (props) => {
  const { isMobileLargeDevice, isDesktopDevice, isMobileSmallDevice } = BrowserSniff()
  if(isMobileLargeDevice || isMobileSmallDevice ) {
    return (
      <View>
        <MatchHeader />
        <MatchLoading />
      </View>
    )
  } else {
    return (
      <GameContainer>
        <MatchHeader />
        <MatchLoading />
      </GameContainer>
    )
  }
}

const MatchQuery = (props) => {
  const { loanAmount, loanOffers, offerHandler,transitionToTerms, transitionToPrivacyPolicy,
    transitionToSupport, transitionToDisclosure, transitionToMatchDisclosure, transitionToAdDisclosure } = props
  const { isMobileLargeDevice, isDesktopDevice, isMobileSmallDevice } = BrowserSniff()
  const [topOffer] = loanOffers

  if(isMobileLargeDevice || isMobileSmallDevice ) {
    return (
      <View style={{ height: '100%'}}>
      <MatchHeader />
      <GameContainer>
      <View style={{ alignItems: 'center', justifyContent: 'center', width: '100%', marginTop: 10, marginLeft: 115}}>
        <Text style={styles.footerText} onPress={transitionToAdDisclosure}>Advertiser Disclosure</Text>
      </View>
        <View style={styles.matches}>
          <View style={styles.matchRow}>
            {loanOffers.map((offer) => {
                return (
                  <View key={offer.uuid} style={styles.matchChild}>
                    <MatchCard
                      offer={offer}
                      category={'Personal Loan'}
                      offerHandler={offerHandler}
                      transitionToMatchDisclosure={transitionToMatchDisclosure}
                      featured={topOffer.uuid === offer.uuid}
                      />
                  </View>)}
                )}
           </View>
        </View>
      <MatchFooter
       transitionToTerms={transitionToTerms}
       transitionToPrivacyPolicy={transitionToPrivacyPolicy}
       transitionToSupport={transitionToSupport}
       transitionToDisclosure={transitionToDisclosure}
       />
    </GameContainer>
  </View>
    )
  } else {
    return (
      <GameContainer>
        <MatchHeader />
        <View style={{ alignItems: 'center', justifyContent: 'center', marginTop: 10, marginLeft: 270}}>
          <Text style={styles.footerText} onPress={transitionToAdDisclosure}>Advertiser Disclosure</Text>
        </View>
        <View style={styles.matches}>
          <ScrollView >
            <View style={styles.matchRow}>
                {loanOffers.map((offer) => {
                  return (
                    <View key={offer.uuid} style={styles.matchChild}>
                      <MatchCard
                        offer={offer}
                        category={'Personal Loan'}
                        offerHandler={offerHandler}
                        transitionToMatchDisclosure={transitionToMatchDisclosure}
                        featured={topOffer.uuid === offer.uuid}
                        />
                    </View>)}
                  )}
             </View>
            </ScrollView>
        </View>
      <MatchFooter
       transitionToTerms={transitionToTerms}
       transitionToPrivacyPolicy={transitionToPrivacyPolicy}
       transitionToSupport={transitionToSupport}
       transitionToDisclosure={transitionToDisclosure}
       />
    </GameContainer>
    )
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(MatchContainer)
