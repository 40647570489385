import React, { Component } from 'react'
import ReactRedux, { connect } from 'react-redux'
import { styles } from './LaunchStyle.js'
import { StackActions, NavigationActions } from '@react-navigation/native'
import { Image, Keyboard, Animated, Easing, View } from 'react-native'
import FeaturedImage from '../components/FeaturedImage'
import GameContainer from '../../common/GameContainer'
import Header from '../components/Header'
import LaunchForm from '../components/LaunchForm'
import SubHeader from '../components/SubHeader'
import Footer from '../components/Footer'
import * as WebBrowser from 'expo-web-browser';
import { isEmailValid, isZipCodeValid } from '../../../helpers/validations'
import Error from '../../common/Error'
import { trackFocus, trackSubmit, trackClick, setUserID, trackError, trackUserProperty } from '../../../mixins/Track'
import BrowserSniff from '../../common/BrowserSniff'
import LoanDomain from '../../../client/domain/LoanDomain'
import { buildRateData } from '../../../helpers/rate-helper'
import { getParamsFromURL } from '../../../helpers/url-helper'
import * as Linking from 'expo-linking'
import AsyncStorage from '@react-native-community/async-storage'
import GetStarted from '../components/GetStarted'

const mapStateToProps = ({ session }) => {
  return {
  }
}

const mapDispatchToProps = {
}

const footerText = "Don't worry! Seeing your loan matches won't affect your credit score. "
const matchText = "Match with personal loan quotes in less than 2 minutes."

class LaunchContainer extends Component {

  state = {
    email: null,
    queryParams: {},
    zipcode: null,
    emailError: false,
    zipcodeError: false,
    emailValid: false,
    zipcodeValid: false,
    continueBtnValid: false,
    rateResults: [],
    errors: [],
    getStartedValid: false,
  }

  constructor(props) {
    super(props)
    this._transitionToGame = this._transitionToGame.bind(this)
    this._onChangeZipCode = this._onChangeZipCode.bind(this)
    this._onChangeEmail = this._onChangeEmail.bind(this)
    this._handlePolicyClick = this._handlePolicyClick.bind(this)
    this._handleTermsClick = this._handleTermsClick.bind(this)
    this._logoClicked = this._logoClicked.bind(this)
    this._eventHandler = this._eventHandler.bind(this)
    this.triggerShakeAnimation = this.triggerShakeAnimation.bind(this)
    this.runValidation = this.runValidation.bind(this)
    this.springValue = new Animated.Value(0.3)
    this.animatedValue = new Animated.Value(0)
    this.animatedValue2 = new Animated.Value(0)
  }

  bounce() {
    this.springValue.setValue(0.3)
     Animated.spring(
       this.springValue,
       {
         easing: Easing.inout,
         toValue: 1,
         friction: 1,
         duration: 1250,
         delay: 250,
       }
     ).start()
  }

  slideInLeft() {
    this.animatedValue.setValue(0)
    Animated.timing(
      this.animatedValue,
      {
        toValue: 1,
        duration: 500,
        easing: Easing.linear,
        delay: 250,
      }
    ).start()
  }

  slideInRight() {
    this.animatedValue2.setValue(0)
    Animated.timing(
      this.animatedValue2,
      {
        toValue: 1,
        duration: 500,
        easing: Easing.Linear
      }
    ).start()
  }

  componentWillMount() {
    this.animation = new Animated.Value(0)
  }

  componentDidMount() {
    getParamsFromURL().then((url) => {
      if (url) {
        let newURL = Linking.parse(url)
        this._setParams(newURL.queryParams)
      }
    })

    this.slideInLeft()
    this.slideInRight()
    this.bounce()
  }

  _logoClicked() {
    WebBrowser.openBrowserAsync('https://financialstress.com')
  }

  _eventHandler(type, field) {
    const { email, zipcode, emailValid, zipcodeValid } = this.state
    if(type === 'focus') {
      if (field === 'Email') {
        this.setState({ emailValid: true }, this.runValidation)
      } else if(field === 'Zipcode') {
        const isValid = isZipCodeValid(zipcode)
        this.setState({ zipcodeValid: true }, this.runValidation)
      }

      trackFocus({ source: `GS ${field} Field Focus` })
    } else {
      trackSubmit({ source: `GS ${field} Field Submit` })
    }
  }

  createLead(state) {
    const { zipcode, email } = state
    const loanDomain = new LoanDomain()
    return AsyncStorage.getItem('@SESSION_UUID').then((session_uuid) => {
      return loanDomain.getRates({
        "session_id": session_uuid,
        "type": "CreateLead",
        "offer_type": "loan",
        "zipcode": zipcode,
        "email": email,
        "loan_amount": 10000,
        "purpose": 'debt_consolidation',
        "provided_credit_rating": 'excellent'
      })
    })
  }

  runValidation() {
    const { emailValid, zipcodeValid } = this.state
    const valid = emailValid && zipcodeValid

    return this.setState({ continueBtnValid: valid })
  }

  _transitionToGame(target) {
    Keyboard.dismiss()
    if(target === 'B') {
      trackUserProperty('get_started_completed', 'true')
      trackClick({ source: 'GS Click' })
      return this.props.navigation.navigate('Match', { screen: 'Match', params: { rateResults: [], email: null, zipcode: null } })
    }
    const { zipcode, email } = this.state
    const isValid = isEmailValid(email) && isZipCodeValid(zipcode)

    trackClick({ source: 'GS Submit Click' })
    return this._transitionOrInvalidate(isValid)
  }

  _transitionOrInvalidate(isValid) {
    const { zipcode, email } = this.state

    if(isValid) {
      trackUserProperty('email', email)
      trackUserProperty('zipcode', zipcode.toString())
      trackUserProperty('get_started_completed', 'true')
      this.setState({ getStartedValid: true })

      this.createLead(this.state).then((rates) => {
        return this.props.navigation.navigate('Match', { screen: 'Match', params: { rateResults: rates, email: email, zipcode: zipcode } })
      })
    } else {
      !isEmailValid(email) ? this._setEmailError() : !isZipCodeValid(zipcode) ? this._setZipCodeError() : null
      this.triggerShakeAnimation()
      return this.setState({
          emailError: !isEmailValid(email), emailValid: isEmailValid(email),
          zipcodeError: !isZipCodeValid(zipcode), zipcodeValid: isZipCodeValid(zipcode)
      })
    }
  }

  _setEmailError() {
    trackClick({ source: 'GS Email Field Invalid', value: this.state.email})
    return this.setState(({ emailError }) => ({
      emailError: true,
      emailValid: false
    }));
  }

  _setZipCodeError() {
    trackClick({ source: 'GS Zipcode Field Invalid', value: this.state.zipcode})
    return this.setState(({ zipcodeError }) => ({
      zipcodeError: true,
      zipcodeValid: false
    }));
  }

  _onChangeZipCode(value) {
    this.setState(({ zipcode }) => ({
      zipcode: value
    }));
  }

  _onChangeEmail(value) {
    this.setState(({ email }) => ({
      email: value
    }));
  }

  _handleTermsClick() {
    WebBrowser.openBrowserAsync('https://financialstress.com/terms/')
  }

  _handlePolicyClick() {
    WebBrowser.openBrowserAsync('https://financialstress.com/privacy-policy/')
  }

  triggerShakeAnimation() {
    this.animation.setValue(0)
    Animated.timing(this.animation, {
      duration: 400,
      toValue: 3,
      ease: Easing.bounce
    }).start()
  }

  _setParams(params) {
    return this.setState(({ queryParams }) => ({
      queryParams: params
    }));
  }

  render() {
    const { errors, queryParams } = this.state
    const interpolated = this.animation.interpolate({
      inputRange: [0, .5, 1, 1.5, 2, 2.5, 3],
      outputRange: [0, -15, 0, 15, 0, -15, 0]
    })

    const slideInLeft = this.animatedValue.interpolate({
      inputRange: [0, 1],
      outputRange: [-300, 0]
    })

    const slideInRight = this.animatedValue2.interpolate({
      inputRange: [0, 1],
      outputRange: [-300, 0]
    })

    return (
      <Launch
        logoClicked={this._logoClicked}
        onChangeZipCode={this._onChangeZipCode}
        transitionToGame={this._transitionToGame}
        onChangeZipCode={this._onChangeZipCode}
        onChangeEmail={this._onChangeEmail}
        errors={errors}
        interpolated={interpolated}
        state={this.state}
        eventHandler={this._eventHandler}
        handlePolicyClick={this._handlePolicyClick}
        handleTermsClick={this._handleTermsClick}
        target={queryParams.target || 'A'}
        bounce={this.springValue}
        slideInRight={slideInRight}
        slideInLeft={slideInLeft}
      />
    )
  }
}

const Launch = (props) => {
  const { slideInRight, slideInLeft, bounce, target, logoClicked, onChangeZipCode, transitionToGame,
      onChangeEmail, errors, interpolated, state, eventHandler, text, handlePolicyClick, handleTermsClick } = props
  const { isMobileLargeDevice, isDesktopDevice, isMobileSmallDevice } = BrowserSniff()

  if(isMobileLargeDevice || isMobileSmallDevice ) {
      return (
        <View style={{
          overflow: 'hidden',
          backgroundColor:'white',
          alignItems: 'left',
          justifyContent: 'left', height: '100%'}}>
        <GetStarted
          slideInRight={slideInRight}
          slideInLeft={slideInLeft}
          bounce={bounce}
          transitionToGame={transitionToGame}/>
      </View>
      )
  } else {
    return (
    <GameContainer>
      <View style={{
        overflow: 'hidden'}}>
        <GetStarted
          slideInRight={slideInRight}
          slideInLeft={slideInLeft}
          bounce={bounce}
          transitionToGame={transitionToGame}/>
        </View>
    </GameContainer>
    )
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(LaunchContainer)
