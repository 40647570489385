import v from 'valib'
import AsyncStorage from '@react-native-community/async-storage'
import uuid from './client-uuid'

const buildRateData = (state) => {
  const { launchEmail, launchZipcode, firstName, lastName, address1, address2, city, zipcode, stateAbv, email, phoneNumber,
  birthDate, loanPurpose, loanAmount, propertyStatus, employmentStatus, educationLevel, employmentPayFrequency,
  annualIncome, creditRating, socialSecurity, type } = state
  if(v.Object.isEmpty(state)) { return {} }

  return {
    "session_id": uuid,
    "type": type,
    "offer_type": "loan",
    "first_name":  firstName.value,
    "last_name": lastName.value,
    "address1": address1.value,
    "address2": address2.value,
    "city": city.value,
    "zipcode": launchZipcode || zipcode.value,
    "state": stateAbv.value,
    "email": launchEmail || email.value,
    "primary_phone": phoneNumber.value,
    "date_of_birth": birthDate.value,
    "purpose": loanPurpose.value,
    "loan_amount": v.Object.hasKey(loanAmount, 'value') ? parseInt(loanAmount.value.split('$')[1].replace(',','')) : v.Object.hasKey(loanAmount, 'toolValue') ? parseInt(loanAmount.toolValue) : 0, // currency str
    "property_status": propertyStatus.value,
    "consentsToFcra": true,
    "consentsToTcpa": true,
    "tcpaLanguage": "We may receive a refferal fee for the services mentioned hereafter. You can read more in our advertising disclosure. By clicking Agree and Continue, I hereby consent to the E-Sign Agreement, the Credit Authorization Agreement, the Terms of Service and Privacy Policy, and am providing written consent under the FCRA for FinancialStress, and its partners to obtain consumer report information from my credit profile. I agree to be contacted by FinancialStress and its partners and their affiliated companies via the email I have provided above to explore various financial products and services I inquired about. Consent is not required as a condition to utilize Financial Stress, and you may choose to be contacted by an individual customer care by emailing info@financialstress.com.",
    "employment_status": employmentStatus.value,
    "education_level": educationLevel.value,
    "employment_pay_frequency": employmentPayFrequency.value,
    "annual_income": v.String.isEmpty(annualIncome.value) ? 0 : parseInt(annualIncome.value.split('$')[1].replace(',','')),
    "credit_rating": creditRating.value,
    "ssn": socialSecurity.value
  }
}

export { buildRateData }
