import validate from 'valib'
const _ = require('underscore')

const testEmailValidity = (email) => {
  const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return regex.test(String(email).toLowerCase())
}

const testBlankFields = (...fields) => {
  let hasBlankField = false
  for (let field of fields) {
    if (validate.String.isEmpty(field)) { hasBlankField = true }
  }
  return hasBlankField;
}

const isEmailValid = (email) => {
  return validate.String.isEmailLike(email)
}

const isZipCodeValid = (zipcode) => {
  return validate.String.isNumeric(zipcode) && validate.String.length.eq(zipcode, 5)
}

const isFieldValid = (field) => {
  return validate.Type.isString(field) && validate.String.length.gte(field, 3)
}

const isPhoneNumberValid = (field) => {
  const regex = /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/
  return regex.test(field)
}

const isBirthDateValid = (date) => {
  const regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/
  return regex.test(date)
}

const isCurrencyValid = (field) => {
  const regex = /^\$(\d{1,3}(\,\d{3})*|(\d+))(\.\d{2})?$/
  return regex.test(field)
}

const isSocialSecurityValid = (field) => {
  const regex = /^(?!000|666)[0-8][0-9]{2}-(?!00)[0-9]{2}-(?!0000)[0-9]{4}$/
  return regex.test(field)
}

export { testEmailValidity, isFieldValid, isZipCodeValid,
  isEmailValid, testBlankFields, isBirthDateValid, isPhoneNumberValid,
  isCurrencyValid, isSocialSecurityValid }
