import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import React from 'react'
import LaunchContainer from '../launch/containers/LaunchContainer'
import LoanContainer from '../loans/containers/LoanContainer'
import QuizContainer from '../quiz/containers/QuizContainer'
import SubscribeContainer from '../subscribe/containers/SubscribeContainer'
import MatchContainer from '../matches/containers/MatchContainer'

const RootStack = createStackNavigator();
const LaunchStack = createStackNavigator();
const QuizStack = createStackNavigator();
const LoanStack = createStackNavigator();
const SubscribeStack = createStackNavigator();
const MatchStack = createStackNavigator();

const LoanStackScreen = () => {
  return (
    <LoanStack.Navigator headerMode="none">
      <LoanStack.Screen name="Loan Application" component={LoanContainer} />
    </LoanStack.Navigator>
  );
}

const MatchStackScreen = () => {
  return (
    <MatchStack.Navigator headerMode="none">
      <MatchStack.Screen name="Loan Offers" component={MatchContainer} />
    </MatchStack.Navigator>
  );
}

const SubscribeStackScreen = () => {
  return (
    <SubscribeStack.Navigator headerMode="none">
      <SubscribeStack.Screen name="Subscribe" component={SubscribeContainer} />
    </SubscribeStack.Navigator>
  );
}

const QuizStackScreen = () => {
  return (
    <QuizStack.Navigator headerMode="none">
      <QuizStack.Screen name="Match" component={QuizContainer} />
    </QuizStack.Navigator>
  );
}

const LaunchStackScreen = () => {
  return (
    <LaunchStack.Navigator headerMode="none">
      <LaunchStack.Screen name="Get Started" component={LaunchContainer} />
    </LaunchStack.Navigator>
  );
}

export { LoanStackScreen, MatchStackScreen, SubscribeStackScreen,
  QuizStackScreen, LaunchStackScreen }
