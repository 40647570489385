import React, { Component } from 'react'
import { styles } from './SharedStyle.js'
import { Dimensions, View, Image, Text, ScrollView, TextInput, TouchableHighlight, TouchableOpacity } from 'react-native'
import { Button } from 'native-base'
import FontAwesomeIcon from 'react-native-vector-icons/FontAwesome';
import { TextInputMask } from 'react-native-masked-text'
import '@expo/match-media'
import MediaQuery from 'react-responsive'
import { Ionicons } from '@expo/vector-icons'
const ID = 7

class ConsentForm extends Component {
  render() {
    const { transitionToDisclosure,transitionToEmail, transitionToEAgreement,
      transitionToTerms, transitionToPrivacyPolicy, transitionToCreditAgreement,
      visibleScreen, completionHandler, onBack, onTextChange, onPickerChange,
      state, transitionToAdDisclosure } = this.props
    const { socialSecurity, socialSecurityValid, continueBtnValid } = state

    if (visibleScreen !== ID) { return null }

    return (
      <View style={styles.consent}>
        <View>
          <Text style={styles.headerTextMobileLarge}>What is Your Social Security Number?</Text>
        </View>

        <View>
          <Text style={styles.stepText}>Step {ID-1} of {ID-1}</Text>
          <Text style={styles.stepDisclosureText} onPress={transitionToAdDisclosure}>Advertiser Disclosure</Text>
        </View>

        <View style={{width: '100%', marginTop: 10, justifyContent: 'center', alignItems: 'center'}}>
          <Text style={[styles.consentIdentityText, { marginLeft: 40, marginRight: 40 }]}>This is a free service and will <Text style={styles.bold900Text}>NOT</Text> affect your credit score.</Text>
          <View style={socialSecurity.valid ? styles.securityContainerValid : socialSecurity.error ? styles.securityContainerError : styles.securityContainer}>
              <FontAwesomeIcon style={{marginLeft: 10, marginTop: 3, marginRight: 20}} name="lock" size={16} color="#434660" />
              <TextInputMask
                inlineImageLeft='search_icon'
                autoCorrect={false}
                secureTextEntry={true}
                returnKeyType={'done'}
                value={socialSecurity.value}
                type={'custom'}
                options={{
                  /**
                   * mask: (String | required | default '')
                   * the mask pattern
                   * 9 - accept digit.
                   * A - accept alpha.
                   * S - accept alphanumeric.
                   * * - accept all, EXCEPT white space.
                  */
                  mask: '999-99-9999',
                  obfuscated: true
                }}
                keyboardType={undefined}
                style={[styles.inputStyle, { outlineColor: 'transparent',outline: "none", outlineWidth: 0 }]}
                placeholder="###-##-####"
                onChangeText={(value) => onTextChange('socialSecurity', value)}
              />
          </View>

          <View style={{justifyContent: 'center', alignItems: 'center'}}>
            <Text style={[styles.consentIdentityText, { marginTop: 10, marginLeft: 40, marginRight: 40 }]}>To verify your identity and find the offers you're eligible for, we'll run a credit check.</Text>
            <View style={[styles.loanBox, {}]}>
              <Button
                disabled={!continueBtnValid}
                onPress={completionHandler}
                style={continueBtnValid ? [styles.actionModBtnValid, {marginTop: 10}] : [styles.actionModBtn, {marginTop: 10}]}
                full rounded>
                <Text uppercase={false} style={[styles.actionBtnText]}>Agree and Continue</Text>
              </Button>
            </View>

            <View style={[styles.loanBox, { marginTop: 15 }]}>
              <Text onPress={onBack} uppercase={false} style={[styles.actionBtnModText, { marginBottom: 10 }]}>Back</Text>
            </View>
        </View>

          <View style={styles.agreementView}>
            <Text style={styles.consentServiceText}>We may receive a refferal fee for the services mentioned hereafter. You can read more in our <Text style={styles.hyperlink} onPress={transitionToDisclosure}>advertising disclosure</Text>. By clicking Agree and Continue, I hereby consent to the <Text style={styles.hyperlink} onPress={transitionToEAgreement}>"E-Sign Agreement"</Text>, the <Text style={styles.hyperlink} onPress={transitionToCreditAgreement}>"Credit Authorization Agreement"</Text>, the <Text style={styles.hyperlink} onPress={transitionToTerms}>Terms of Service</Text> and <Text style={styles.hyperlink} onPress={transitionToPrivacyPolicy}>Privacy Policy</Text>, and am providing written consent under the FCRA for FinancialStress, and its partners to obtain consumer report information from my credit profile. I agree to be contacted by FinancialStress and its partners and their affiliated companies via the email I have provided above to explore various financial products and services I inquired about. Consent is not required as a condition to utilize Financial Stress, and you may choose to be contacted by an individual customer care by emailing <Text style={styles.hyperlink} onPress={transitionToEmail}>info@financialstress.com</Text>.</Text>
          </View>
        </View>
      </View>
    )
  }
}

export default ConsentForm
