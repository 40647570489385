import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import reducers, { defaultState } from '../modules/reducers'
import middleware from '../middleware'
const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const store = createStore(
  combineReducers(reducers),
  defaultState,
  composeEnhancers(applyMiddleware(...middleware))
)

export default store
