import React, { Component } from 'react'
import { styles } from './SharedStyle.js'
import { Dimensions, View, Image, Text, ScrollView, TextInput, TouchableHighlight } from 'react-native'
import { Button } from 'native-base'
import Icon from 'react-native-vector-icons/FontAwesome';
import { TextInputMask } from 'react-native-masked-text'
import { Picker } from 'react-native'
import { Ionicons } from '@expo/vector-icons'
import { pickerSelectStyles } from './CustomMenuStyle'
import RNPickerSelect, { defaultStyles } from 'react-native-picker-select'
import '@expo/match-media'
import MediaQuery from 'react-responsive'

const ID = 6
const loanplaceholder = {
  label: 'Debt Consolidation',
  value: 'debt_consolidation',
  color: '#C3E0BE',
}

const loanPurposes = [
  // {
  //   label: 'Debt Consolidation',
  //   value: 'debt_consolidation',
  // },
  {
    label: 'Credit Card Refinance',
    value: 'credit_card_refi',
  },
  {
    label: 'Home Improvement',
    value: 'home_improvement',
  },
  {
    label: 'Large Purchase',
    value: 'large_purchases',
  },
  {
    label: 'Student',
    value: 'student_loan_refi',
  },
  {
    label: 'Other',
    value: 'other',
  },
]

const creditRatingPlaceholder = {
  label: 'Excellent (720 +)',
  value: 'excellent',
  color: '#C3E0BE',
}

const creditRatings = [
  // label: 'Excellent (720 +)',
  // value: 'excellent'
  {
    label: 'Good (660-719)',
    value: 'good',
  },
  {
    label: 'Fair (620-659)',
    value: 'fair',
  },
  {
    label: 'Poor (<620)',
    value: 'poor',
  },
]

class LoanPurposeForm extends Component {
  render() {
    const { visibleScreen, onTextChange, onPickerChange, state, screenMap, onFocus, transitionToAdDisclosure } = this.props
    const { loanAmount, loanPurpose, creditRating, loanAmountValid,
      loanPurposeValid, creditRatingValid } = state
    if (visibleScreen !== ID) { return null }

    return (
      <View style={{justifyContent:'center', alignItems: 'center'}}>
        <Text style={[styles.headerTextMobileLarge]}>Loan Information</Text>

        <View>
          <Text style={styles.stepText}>Step {ID-1} of {screenMap.length - 1}</Text>
          <Text style={styles.stepDisclosureText} onPress={transitionToAdDisclosure}>Advertiser Disclosure</Text>
        </View>

        <View style={{width: '65%', marginTop: 10}}>
          <TextInputMask
            type={'money'}
            style={loanAmount.valid ? styles.itemValid : loanAmount.error ? styles.itemError : styles.item}
            placeholder="Loan Amount"
            value={loanAmount.value}
            options={{
              precision: 0,
              separator: '.',
              delimiter: ',',
              unit: '$',
              suffixUnit: ''
            }}
          onFocus={() => onFocus('loanAmount')}
          keyboardType={undefined}
          onChangeText={(value) => onPickerChange('loanAmount', value)}
          />
        </View>

        <View style={[styles.loanBox, {marginTop: 10}]}>
          <Text style={styles.actionTextMobileLarge}>Reason for Loan</Text>
        </View>
        <View style={{ width: '65%', marginTop: 5}}>
            <RNPickerSelect
                placeholder={loanplaceholder}
                items={loanPurposes}
                selectedValue={loanPurpose.value}
                onValueChange={(value) => onPickerChange('loanPurpose', value)}
                style={loanPurpose.valid ?
                  {
                    inputAndroid: {
                      fontSize: 14,
                      borderWidth: '2px',
                      borderStyle: 'solid',
                      borderColor: '#69B679',
                      borderRadius: 22,
                      color: '#101D42',
                      padding: 10,
                      paddingRight: 30,
                    },
                    iconContainer: {
                      top: 10,
                      right: 12,
                    },
                  } :
                  {
                    inputAndroid: {
                      fontSize: 14,
                      borderWidth: '2px',
                      borderStyle: 'solid',
                      borderColor: '#C3E0BE',
                      borderRadius: 22,
                      color: '#101D42',
                      padding: 10,
                      paddingRight: 30,
                    },
                    iconContainer: {
                      top: 10,
                      right: 12,
                    },
                  }}
                value={loanPurpose.value}
                useNativeAndroidPickerStyle={false}
                textInputProps={{ underlineColor: '#101D42' }}
                Icon={() => {
                  return <Ionicons name="md-arrow-down" size={24} color="grey" />;
                }}
              />
        </View>

        <View style={[styles.loanBox, {marginTop: 10}]}>
          <Text style={styles.actionTextMobileLarge}>Credit Rating</Text>
        </View>
        <View style={{ width: '65%', marginTop: 5}}>
          <RNPickerSelect
              placeholder={creditRatingPlaceholder}
              items={creditRatings}
              selectedValue={creditRating.value}
              onValueChange={(value) => onPickerChange('creditRating', value)}
              style={creditRating.valid ?
                {
                  inputAndroid: {
                    fontSize: 14,
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    borderColor: '#69B679',
                    borderRadius: 22,
                    color: '#101D42',
                    padding: 10,
                    paddingRight: 30,
                  },
                  iconContainer: {
                    top: 10,
                    right: 12,
                  },
                } :
                {
                  inputAndroid: {
                    fontSize: 14,
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    borderColor: '#C3E0BE',
                    borderRadius: 22,
                    color: '#101D42',
                    padding: 10,
                    paddingRight: 30,
                  },
                  iconContainer: {
                    top: 10,
                    right: 12,
                  },
                }}
              value={creditRating.value}
              useNativeAndroidPickerStyle={false}
              textInputProps={{ underlineColor: '#101D42' }}
              Icon={() => {
                return <Ionicons name="md-arrow-down" size={24} color="grey" />;
              }}
            />
        </View>
      </View>

    )
  }
}

export default LoanPurposeForm
