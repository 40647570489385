import React, { Component } from 'react'
import { styles } from './SharedStyle.js'
import { Dimensions, View, Image, Text, ScrollView, TextInput, TouchableHighlight } from 'react-native'
import { Button } from 'native-base'
import Icon from 'react-native-vector-icons/FontAwesome'
import HTML from 'react-native-render-html'

class MatchDisclosure extends Component {

  render() {
    const { transitionBack, offer } = this.props
    const { originator } = offer
    const { disclaimer, description } = originator

    return (
      <View style={styles.disclosureView}>
        <Button onPress={transitionBack} style={styles.closeBtn} full rounded>
          <Icon name="times" size={30} color="#000" />
        </Button>

        <Text style={[styles.headerText, {marginBottom: 10}]}>More Information</Text>
        <ScrollView style={{padding: 20}}>
          <HTML html={description} imagesMaxWidth={Dimensions.get('window').width} />
        </ScrollView>

        <Text style={styles.headerText}>Disclaimer</Text>
        <ScrollView style={{padding: 20}}>
          <HTML html={disclaimer} imagesMaxWidth={Dimensions.get('window').width} />
        </ScrollView>
      </View>
    )
  };
}

export default MatchDisclosure
