import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
  scrollView: {
  },

  headerText: {
    fontSize: '30px',
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#101D42',
    fontFamily: 'Nunito-Bold'
  },

  offerCard: {
    height: 235,
    width: 375,
    textAlign: 'center',
    shadowColor: "#000",
    shadowOffset: {
    	width: 0,
    	height: 6,
    },
    shadowOpacity: 0.37,
    shadowRadius: 7.49,
    elevation: 12,
    display: 'flex',
    backgroundColor: '#fff',
    borderRadius: '12px',
    marginRight: 20,
    marginLeft: 5,
    borderColor: '#69B679'
  },

  offerCardMobileLarge: {
    height: 225,
    width: 325,
    textAlign: 'center',
    shadowColor: "#000",
    shadowOffset: {
    	width: 0,
    	height: 6,
    },
    shadowOpacity: 0.37,
    shadowRadius: 7.49,
    elevation: 12,
    display: 'flex',
    backgroundColor: '#fff',
    borderRadius: '12px',
    marginRight: 20,
    marginLeft: 5,
    borderColor: '#69B679'
  },

  actionCardView: {
    width: '100%',
    maxWidth: '150px',
    maxHeight: '60px',
  },

  cardInfo: {
    alignItems: 'left',
    justifyContent: 'left',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginTop: 5,
  },

  cardActionSubText: {
    fontSize: '12px',
    color: '#101D42',
    textAlign: 'center',
    fontFamily: 'Nunito-Regular',
    userSelect: 'none',
  },

  cardActionText: {
    fontSize: '16px',
    marginLeft: 45,
    fontWeight: 'bold',
    color: '#101D42',
    textAlign: 'left',
    fontFamily: 'Nunito-Bold',
    userSelect: 'none',
  },

  cardDescriptionText: {
    fontSize: '12px',
    marginLeft: 15,
    color: '#101D42',
    textAlign: 'left',
    fontFamily: 'Nunito-Regular',
    userSelect: 'none',
  },

  cardDescriptionTextMobileLarge: {
    fontSize: '10px',
    marginLeft: 15,
    color: '#101D42',
    textAlign: 'left',
    width: 200,
    fontFamily: 'Nunito-Regular',
    userSelect: 'none',
  },


  headerOfferCardText: {
    fontSize: '18px',
    textAlign: 'center',
    color: '#101D42',
    fontFamily: 'Nunito-Regular',
    userSelect: 'none',
  },

  headerOfferCardTextMobileLarge: {
    fontSize: '14px',
    textAlign: 'center',
    color: '#101D42',
    fontFamily: 'Nunito-Regular',
    userSelect: 'none',
  },

  monthlyRateOfferCardText: {
    fontSize: '34px',
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#6AB678',
    fontFamily: 'Nunito-Black',
    userSelect: 'none',
  },

  monthlyRateOfferCardTextMobileLarge: {
    fontSize: '28px',
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#6AB678',
    fontFamily: 'Nunito-Black',
    userSelect: 'none',
  },

  brandView: {
    flex: 1,
    justifyContent: 'center',
    zIndex: 100000,
    alignItems: 'center',
    height: 20,
    marginTop: 10,
    marginBottom: 10
  },

  headerTextMobileLarge: {
    fontSize: '24px',
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#101D42',
    fontFamily: 'Nunito-Bold'
  },

  headerTextMobileSmall: {
    fontSize: '24px',
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#101D42',
    fontFamily: 'Nunito-Bold'
  },

  bold900Text: {
    fontSize: '11px',
    fontWeight: 'bold',
    color: '#101D42',
    fontFamily: 'Nunito-Black'
  },

  stepText: {
    fontSize: '16px',
    marginTop: '10px',
    textAlign: 'center',
    color: '#101D42',
    fontFamily: 'Nunito-Regular'
  },

  item: {
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: '#C3E0BE',
    borderRadius: '25px',
    marginBottom: '20px',
    marginLeft: '10px',
    marginRight: '10px',
    padding: '15px'
  },

  selector: {
    borderWidth: '3px',
    borderStyle: 'solid',
    borderColor: '#C3E0BE',
    borderRadius: 22,
    color: '101D42',
    width: 250,
    height: 50,
    padding: '10px',
  },

  actionBtn: {
    backgroundColor: '#C3E0BE',
    borderColor: '#C3E0BE',
    alignItems: 'center',
    marginBottom: '5px',
    textAlign: 'center'
  },

  actionBtnValid: {
    backgroundColor: '#69B679',
    borderColor: '#69B679',
    alignItems: 'center',
    textAlign: 'center'
  },

  actionBtnError: {
    backgroundColor: '#A63D40',
    borderColor: '#A63D40',
    alignItems: 'center',
    textAlign: 'center'
  },

  actionModBtn: {
    backgroundColor: '#C3E0BE',
    borderColor: '#C3E0BE',
    height: 50,
    width: 150
  },

  actionModBtnValid: {
    backgroundColor: '#69B679',
    borderColor: '#69B679',
    height: 50,
    width: 150
  },

  actionModBtnError: {
    backgroundColor: '#A63D40',
    borderColor: '#A63D40',
    height: 50,
    width: 150
  },

  colCenteredBtns: {
    height: 150,
    justifyContent: 'center',
    alignItems: 'center',
  },

  actionModBackBtn: {
    backgroundColor: 'transparent',
    height: 50,
    width: 150
  },

  actionMobileBtn: {
    backgroundColor: '#C3E0BE',
    borderColor: '#C3E0BE',
    alignItems: 'center',
    marginBottom: '5px',
    textAlign: 'center'
  },

  actionBtnText: {
    color: '#fff',
    alignItems: 'center'
  },

  actionBtnModText: {
    color: '#101D42',
    alignItems: 'center',
    textDecorationLine: 'underline'
  },

  consentText: {
    marginTop: '5px',
    fontSize: '13px',
    textAlign: 'left',
    marginLeft: 30,
    marginRight: 30,
    marginTop: 20,
    marginBottom: 10,
    color: '#101D42',
    fontFamily: 'Nunito-Regular'
  },

  consentView: {
    marginLeft: 50,
    marginRight: 50,
    textAlign: 'center',
    flexDirection: 'row',
  },

  consentMobileView: {
    marginLeft: 60,
    marginRight: 60,
    textAlign: 'center',
    flexDirection: 'row',
  },

 fieldHeader: {
    padding: '10px',
    fontSize: '16px',
    fontWeight: 'bold',
    textAlign: 'center'
  },

  fieldAlert: {
    padding: '5px',
    fontSize: '14px',
    fontWeight: 'bold',
    marginLeft: '20px',
    marginRight: '20px',
  },

  btnOptions: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
  },

  prequalForm: {
    height: 600
  },

  consent: {
    height: 750
  },

  marginTop10: {
    marginTop: '10px'
  },

  disclosureView: {
    height: 750
  },

  consentBoldText: {
    fontSize: '14px',
    textAlign: 'left',
    color: '#101D42',
    fontFamily: 'Nunito-Black'
  },

  fieldView: {
    marginTop: 10,
    marginBottom: 10
  },

  consentText: {
    marginTop: '5px',
    fontSize: '13px', // 11px?
    textAlign: 'left',
    marginLeft: 30,
    marginRight: 30,
    marginBottom: 10,
    color: '#101D42',
    fontFamily: 'Nunito-Regular'
  },

  disclosureText: {
    fontSize: '12px',
    padding: '10px',
    color: 'grey',
    textDecorationLine: 'underline'
  },

  offerDisclosureActionText: {
    fontSize: '10px',
    color: '#101D42',
    fontFamily: 'Nunito-Regular',
    textDecorationLine: 'underline',
    textAlign: 'left',
    marginTop: 10,
    userSelect: 'none'
  },

  offerDetails: {
    marginLeft: '10px',
    flexDirection: 'row',
  },

  offerHeaderActionText: {
    fontSize: '11px',
    color: '#101D42',
    fontFamily: 'Nunito-Regular',
    textAlign: 'left',
    marginLeft: 5,
    marginTop: '30px',
  },

  offerView: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },

  offerImage: {
    width: '20%',
    height: 20,
    marginLeft: 5
  },

  closeBtn: {
    textAlign: 'center',
    backgroundColor: '#fff'
  },

  mutedActionText: {
    textAlign: 'center',
    fontSize: '12px',
    height: '50px',
    color: '#101D42',
  },

  consentImage: {
    borderRadius: '10px',
    marginLeft: '30px',
    marginRight: '30px',
    height: 200
  },

  monthlyRateText: {
    fontSize: '42px',
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#6AB678',
    fontFamily: 'Nunito-Black'
  },

  monthlyRateMobileText: {
    fontSize: '40px',
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#6AB678',
    fontFamily: 'Nunito-Bold'
  },

  monthlyDescriptionText: {
    fontSize: '16px',
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#101D42',
    fontFamily: 'Nunito-SemiBold'
  },

  rateInfoText: {
    textAlign: 'center',
    fontSize: '11px',
    marginTop: '5px',
    flexShrink: 1,
    marginBottom: '10px',
    color: '#101D42',
    fontFamily: 'Nunito-Regular'
  },

  preLoadingOfferText: {
    textAlign: 'center',
    fontSize: '14px',
    marginTop: '5px',
    flexShrink: 1,
    color: '#101D42',
    fontFamily: 'Nunito-SemiBold',
    userSelect: 'none'
  },

  rateInfoTextSmall: {
    textAlign: 'center',
    fontSize: '9px',
    marginTop: '5px',
    marginBottom: '10px',
    color: '#101D42',
    fontFamily: 'Nunito-Regular'
  },

  boldActionText: {
    fontSize: '14px',
    marginBottom: 5,
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#101D42',
    fontFamily: 'Nunito-Black'
  },

  actionText: {
    fontSize: '16px',
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#101D42',
    fontFamily: 'Nunito-SemiBold',
    userSelect: 'none'
  },

  actionTextMobileLarge: {
    textAlign: 'center',
    fontSize: '15px',
    fontWeight: 'bold',
    height: '50px',
    color: '#101D42',
    fontFamily: 'Nunito-SemiBold',
    userSelect: 'none'
  },

  actionTextMobileSmall: {
    fontSize: '14px',
    fontWeight: 'bold',
    textAlign: 'center',
    marginLeft: '30px',
    marginRight: '30px',
    color: '#101D42',
    fontFamily: 'Nunito-SemiBold',
    userSelect: 'none'
  },

  actionRangeView: {
    backgroundColor: '#EBEBEB',
    width: '100%',
    maxWidth: '150px',
    maxHeight: '60px',
    marginTop: 25,
    padding: '8px',
  },

  actionRangeCreditView: {
    backgroundColor: '#EBEBEB',
    padding: '8px',
    width: '100%',
    maxWidth: '150px',
    maxHeight: '40px',
    marginTop: 25,
  },

  actionRangeText: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#101D42',
    textAlign: 'center',
    fontFamily: 'Nunito-Black',
    userSelect: 'none'
  },

  actionRangeTextMobile: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#101D42',
    textAlign: 'center',
    fontFamily: 'Nunito-Black',
    userSelect: 'none'
  },

  actionRangeCreditText: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#101D42',
    textAlign: 'center',
    width: '100%',
    fontFamily: 'Nunito-Black',
    userSelect: 'none'
  },

  actionRangeCreditTextMobile: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#101D42',
    textAlign: 'center',
    width: '100%',
    fontFamily: 'Nunito-Black',
    userSelect: 'none'
  },

  hyperlink: {
    color: '#134074',
    textDecorationLine: 'underline'
  },

  actionRangeMobileText: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginTop: '20px',
    color: '#101D42',
    backgroundColor: '#EBEBEB',
    padding: '10px',
    width: '125px',
    textAlign: 'center',
    fontFamily: 'Nunito-Bold',
    userSelect: 'none'
  },

  content: {
  },


  actionInfo: {
    flexDirection: 'row',
    marginLeft: '10px',
    marginRight: '10px',
    justifyContent: 'space-around',
  },

  toolActionInfo: {
    flexDirection: 'row',
    marginLeft: '10px',
    marginRight: '10px',
    justifyContent: 'space-around',
  },

  toolActionInfoMobileLarge: {
    flexDirection: 'row',
    marginLeft: '20px',
    marginRight: '20px',
    justifyContent: 'space-around',
  },

  toolActionInfoMobileSmall: {
    flexDirection: 'row',
    marginLeft: '20px',
    marginRight: '20px',
    justifyContent: 'space-around',
  },

  sliderMobile: {
    width: 325,
    marginRight: 20,
    marginTop: 20,
    height: 20,
    textAlign: 'center',
  },

  sliderMobileSmall: {
    width: 325,
    marginTop: 20,
    marginRight: 20,
    height: 10,
    textAlign: 'center',
  },

  sliderMobileLarge: {
    width: 325,
    marginRight: 20,
    marginTop: 20,
    height: 10,
    textAlign: 'center',
  },

  slider: {
    width: 350,
    marginRight: 20,
    marginTop: 20,
    height: 20,
    textAlign: 'center',
  },

  thumb: {
    width: 40,
    height: 40,
    backgroundColor: '#fff',
    borderColor: '#101D42',
    borderStyle: 'solid',
    borderWidth: 4,
    borderRadius: 50,
  },

  loanBox: {
    marginTop: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },

  btnBox: {
    alignItems: 'center',
    justifyContent: 'center',
  },

  picker: {
    width: '325px',
    borderColor: '#101D42',
    padding: '20px',
    height: 30,
    flex: 1,
    marginTop: '10px',
  },

  pickerItem: {
    fontSize: '12px',
    color: '#101D42',
  },

  social: {
    flexDirection: 'row'
  }
})
