import React, { Component } from 'react'
import { Container, Header, Content, Button, Text } from 'native-base';
import { styles } from './SharedStyle.js'
import { Image, View, ImageBackground } from 'react-native';
import BrowserSniff from '../../common/BrowserSniff'

const backgroundUri = require('./images/background.png')
const overlayUri = require('./images/overlay_logos.png')

const FeaturedImage = () => {
  const { isMobileLargeDevice, isDesktopDevice, isMobileSmallDevice } = BrowserSniff()

  if(isMobileLargeDevice) {
    return (
      <View>
        <ImageBackground
          style={styles.featuredImageMobileLarge}
          source={backgroundUri}
        >
        <Image
          style={{ alignItems: 'center', justifyContent: 'center', width: "100%", height: 225, zIndex: 10000}}
          source={overlayUri}
        />
      </ImageBackground>
    </View>
    )
  }

  if(isMobileSmallDevice) {
    return (
      <View>
        <ImageBackground
          style={styles.featuredImageMobileSmall}
          source={backgroundUri}
        >
        <Image
          style={{ alignItems: 'center', justifyContent: 'center', width: "80%", marginLeft: 35, height: 175, zIndex: 10000}}
          source={overlayUri}
        />
      </ImageBackground>
    </View>
    )
  }

  return (
    <View>
      <ImageBackground
        style={styles.featuredImage}
        source={backgroundUri}
      >
      <Image
        style={{ alignItems: 'center', justifyContent: 'center', width: "100%", height: 300, zIndex: 10000}}
        source={overlayUri}
      />
    </ImageBackground>
  </View>
  )
}
export default FeaturedImage
