import session from './session'

const reducers = { session }
export default reducers

export const defaultState = {
  session: {
    lead: {

    },
    events: []
  }
}
