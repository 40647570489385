import React, { Component } from 'react'
import { styles } from './SharedStyle.js'
import { Text, View, Image } from 'react-native';
import BrowserSniff from '../../common/BrowserSniff'

const imageUri = require('./images/logo.png')

const Header = (props) => {
  const { isMobileLargeDevice, isDesktopDevice, isMobileSmallDevice } = BrowserSniff()
  const { text } = props

  if(isMobileLargeDevice) {
    return (
      <Text
        style={styles.headerTextMobileLarge}>
        {text}
      </Text>
    )
  }

  if(isMobileSmallDevice) {
    return (
      <Text
        style={styles.headerTextMobileSmall}>
        {text}
      </Text>
    )
  }

  return (
    <Text
      style={styles.headerText}>
      {text}
    </Text>
  )
}

export default Header
