import session, { defaultState as sessionDefaultState } from './session/reducers'
import offers, { defaultState as offersDefaultState } from './offers/reducers'


const reducers = {
  ...session,
  ...offers,

}
export default reducers

export const defaultState = {
  ...sessionDefaultState,
  ...offersDefaultState,
}
