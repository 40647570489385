import React, { Component } from 'react'
import { styles } from './SharedStyle.js'
import { Dimensions, View, Image, Text, ScrollView, TextInput, TouchableHighlight } from 'react-native'
import { Button } from 'native-base'
import Icon from 'react-native-vector-icons/FontAwesome';

class SecurityDisclosure extends Component {

  render() {
    const { transitionBack } = this.props

    return (
      <View style={styles.disclosureView}>
        <Button onPress={transitionBack} style={styles.closeBtn} full rounded>
          <Icon name="times" size={30} color="#000" />
        </Button>

        <Text style={styles.headerText}>Security</Text>
        <Text style={styles.consentText}>We use 256-bit SSL protection and strict, high levels of security & encryption standards to keep your information safe.</Text>

      </View>
    )
  };
}

export default SecurityDisclosure
