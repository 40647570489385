// 'Swipe the card to the right your screen for "Yes". Swipe it left for "No".'
export default [
  {
    id: 10000000,
    relatedOffer: '',
    category: 'intro',
    segueId: null,
    question: 'Ready to get familiar with your finances?',
    subQuestion: 'To begin, swipe the card above right for YES.',
    description: 'Swipe the card left for NO.',
    image: require('./images/slide-2.png'),
    marketing_id: 'intro_onboard_completed',
  },
  {
    id: 1,
    relatedOffer: '',
    category: '',
    segueId: null,
    question: 'Do you feel overwhelmed by your current debt?',
    subQuestion: '',
    description: "",
    image: require('./images/slide-3.png'),
    marketing_id: 'feel_overwhelmed_by_debt',
  },
  {
    id: 2,
    relatedOffer: '',
    category: '',
    segueId: null,
    question: 'Be honest. Does your budget look like this?',
    subQuestion: '',
    description: "",
    image: require('./images/slide-4.png'),
    marketing_id: 'has_unorganized_budget',
  },
  {
    id: 3,
    relatedOffer: '',
    category: '',
    segueId: null,
    question: 'Do you have a car payment?',
    subQuestion: '',
    description: "",
    image: require('./images/slide-5.png'),
    marketing_id: 'has_car_payment',
  },
  {
    id: 4,
    relatedOffer: '',
    category: '',
    segueId: null,
    question: 'Do you have a mortgage?',
    subQuestion: '',
    description: "",
    image: require('./images/slide-6.png'),
    marketing_id: 'has_mortgage_payment',
  },
  {
    id: 5,
    relatedOffer: '',
    category: '',
    segueId: null,
    question: 'Are you currently employed?' ,
    subQuestion: '',
    description: "",
    image: require('./images/slide-7.png'),
    marketing_id: 'currently_employed',
  },
  {
    id: 6,
    relatedOffer: '',
    category: '',
    segueId: null,
    question: 'Do you have at least $1,000 in emergency savings?',
    subQuestion: '',
    description: "",
    image: require('./images/slide-8.png'),
    marketing_id: 'has_emergency_savings',
  },
  {
    id: 7,
    relatedOffer: '',
    category: '',
    segueId: null,
    question: 'Do you have student loan debt?',
    subQuestion: '',
    description: "",
    image: require('./images/slide-9.png'),
    marketing_id: 'has_student_loan_debt',
  },
  {
    id: 8,
    relatedOffer: '',
    category: '',
    segueId: null,
    question: 'Do you have life insurance?',
    subQuestion: '',
    description: "",
    image: require('./images/slide-10.png'),
    marketing_id: 'has_life_insurance',
  },
  {
    id: 9,
    relatedOffer: '',
    category: '',
    segueId: null,
    question: 'Have you checked your credit score in the last 60 days?',
    subQuestion: '',
    description: "",
    image: require('./images/slide-11.png'),
    marketing_id: 'viewed_credit_in_60_days',
  },
  {
    id: 10,
    relatedOffer: '',
    category: '',
    segueId: null,
    question: 'If you were approved for a loan today, would you feel like this?',
    subQuestion: '',
    description: "",
    image: require('./images/slide-12.png'),
    marketing_id: 'interest_loan_approval',
  },
]
