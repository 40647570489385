import constants from '../constants.js'

export default class GoogleDomain {
  getPlaces(data = {}) {
    const { zipcode, address1 } = data
    const proxyurl = "https://cors-anywhere.herokuapp.com/"
    return fetch((proxyurl + constants.REQUEST_PLACE_AUTOCOMPLETE_URL +
      `?key=${constants.GOOGLE_API_KEY}&input=${address1.value}&zipcode=${zipcode.value}&libraries=places`), {
      method: constants.GET_METHOD,
    })
    .then(places => places.json())
    .catch((error) => {
      throw error
    })
  }
}
