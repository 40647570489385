import { StyleSheet } from 'react-native'

export const pickerSelectStyles = StyleSheet.create({
  inputIOS: {
    fontSize: 14,
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: '#C3E0BE',
    borderRadius: 22,
    color: '#101D42',
    padding: 10,
    paddingRight: 30,
  },
  inputAndroid: {
    fontSize: 14,
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: '#C3E0BE',
    borderRadius: 22,
    color: '#101D42',
    padding: 10,
    paddingRight: 30,
  },
  inputValidated: {
    fontSize: 14,
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: '#69B679',
    borderRadius: 22,
    color: '#101D42',
    padding: 10,
    paddingRight: 30,
  },
  inputError: {
    fontSize: 14,
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: '#A63D40',
    borderRadius: 22,
    color: '#101D42',
    padding: 10,
    paddingRight: 30,
  }
});
