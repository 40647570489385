import React, { Component } from 'react'
import { TouchableOpacity, Text, Image, Button, ImageBackground } from 'react-native'
import { styles } from './SharedStyle.js'
import { View } from 'react-native'
import v from 'valib'
import BrowserSniff from '../../common/BrowserSniff'
import Currency from '../../../../lib/currency.min.js'

const backgroundColors = [
  'rgba(178, 228, 251, .1)',
]

const renderRandomBackground = () => {
   return backgroundColors[Math.floor(Math.random() * (backgroundColors.length)) + 1]
}

const prequalifiedImage = require('./images/preQualified.png')
const preapprovedImage = require('./images/preApproved.png')

class MatchCard extends Component {
  render() {
    const { offerHandler, category, transitionBack, transitionToMatchDisclosure, featured, offer } = this.props
    let offerLoading = v.Array.isEmpty(offer)

    if(!offerLoading ) {
      return (
      <MatchCardQuery
        offerHandler={offerHandler}
        category={category}
        transitionBack={transitionBack}
        transitionToMatchDisclosure={transitionToMatchDisclosure}
        featured={featured}
        offer={offer}
      />)
    } else {
      return (null)
    }
  }
}


const MatchCardQuery = (props) => {
  const { offerHandler, category, transitionBack, transitionToMatchDisclosure, featured, offer } = props
  const { originator, preQualified, preApproved, minApr, meanApr, maxApr, termLength,
    termUnit, minAmount, medianAmount, maxAmount, minMonthlyPayment,
    meanMonthlyPayment, maxMonthlyPayment, url, monthlyPayment } = offer
  const { images, name } = originator
  const { isMobileLargeDevice, isDesktopDevice, isMobileSmallDevice } = BrowserSniff()

  return (
      <View>
        <View style={(isMobileLargeDevice || isMobileSmallDevice) ? styles.recommendedViewMobile : styles.recommendedView}>
          {featured ? <Text style={styles.recommendedText}>RECOMMENDED</Text> : <Text style={styles.recommendedText}></Text>}
        </View>
          <TouchableOpacity
            style={(isMobileLargeDevice || isMobileSmallDevice) ? styles.itemMobile : styles.item}
            onPress={() => offerHandler(url)}
          >
          <ImageBackground style={{backgroundColor: backgroundColors[0], borderRadius: 12}}>
            <View>
              {featured ? <Image style={{marginTop: 15, width: '60%', height: 20 }} source={preApproved ? preapprovedImage : prequalifiedImage } /> : <View style={{marginTop: 15, width: '60%', height: 20 }}></View>}
              <Image style={(isMobileLargeDevice || isMobileSmallDevice) ? styles.offerImageMobile : styles.offerImage} source={{uri: images[0].url}} />
            </View>
          </ImageBackground>

          <View style={styles.offerDetails}>
            <Text style={styles.offerHeader}>${parseFloat(monthlyPayment || minMonthlyPayment || meanMonthlyPayment || maxMonthlyPayment || '0').toFixed(2)}</Text>
            <Text style={styles.offerHeaderActionText}>Est Mo/Pmt</Text>
          </View>

          <View style={styles.offerDetails}>
            <Text style={styles.offerActionHeader}>Loan Amount:</Text>
            <Text style={styles.offerActionText}>{Currency(maxAmount, { decimal: ',', precision: 0 }).format() || 'TBD'}</Text>
          </View>

          <View style={styles.offerDetails}>
            <Text style={styles.offerActionHeader}>APR:</Text>
            <Text style={styles.offerActionText}>{(minApr || meanApr || maxApr || 'TBD')}%</Text>
          </View>

          <View style={styles.offerDetails}>
            <Text style={styles.offerActionHeader}>Term:</Text>
            <Text style={styles.offerActionText}>{termLength || '...'} {termUnit || 'TBD'}s</Text>
          </View>

          <TouchableOpacity onPress={() => transitionToMatchDisclosure(offer)}>
            <Text style={styles.offerDisclosureActionText}>Disclosure and more info</Text>
          </TouchableOpacity>

        </TouchableOpacity>
      </View>
    )
  }

export default MatchCard
