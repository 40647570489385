import React, { Component } from 'react'
import { Animated, View, Text, PanResponder, Dimensions, SafeAreaView, Image } from 'react-native'
import { styles } from './SharedStyle.js'
import '@expo/match-media'
import MediaQuery from "react-responsive";

const SCREEN_WIDTH = Dimensions.get('window').width;
const SWIPE_THRESHOLD = 0.1 * SCREEN_WIDTH;
const SWIPE_OUT_DURATION = 250;

class SwipeContainer extends Component {
  static defaultProps = {
    onSwipeRight: () => {},
    onSwipeLeft: () => {}
  };

  constructor(props) {
    super(props);
    this.state = {
      index: 0,
      direction: 'none',
    };
    this.renderCardItem = this.renderCardItem.bind(this)

    this.position = new Animated.ValueXY()
    this.setPanHandler()
  }

  setPanHandler() {
    this._panResponder = PanResponder.create({
      onStartShouldSetPanResponder: (evt, gestureState) => true,
      onStartShouldSetPanResponderCapture: (evt, gestureState) =>
        true,
      onMoveShouldSetPanResponder: (evt, gestureState) => true,
      onMoveShouldSetPanResponderCapture: (evt, gestureState) =>
        true, // asks to be responder
      onPanResponderGrant: (evt, gesture) => {
        if (gesture.dx > SWIPE_THRESHOLD) {
           this.enableSwipeAnimate('right')
         } else if (gesture.dx < -SWIPE_THRESHOLD) {
           this.enableSwipeAnimate('left')
         } else {
           this.enableSwipeAnimate('right') // force animate to show YES animation on move if not in parameters
         }
      },
      onPanResponderMove: (evt, gesture) => {
        this.position.setValue({ x: gesture.dx, y: gesture.dy });
        if (gesture.dx > SWIPE_THRESHOLD) {
           this.enableSwipeAnimate('right')
         } else if (gesture.dx < -SWIPE_THRESHOLD) {
           this.enableSwipeAnimate('left')
         }
      },
      onPanResponderRelease: (evt, gesture) => {
        if (gesture.dx > SWIPE_THRESHOLD) {
           this.forceSwipe('right');
         } else if (gesture.dx < -SWIPE_THRESHOLD) {
           this.forceSwipe('left');
         } else {
           this.resetPosition();
           this.disableSwipeAnimate()
         }
      }
    });
  }

  enableSwipeAnimate(direction) {
    const { onSwipeAnimate } = this.props;
    onSwipeAnimate('enable', direction)
  }

  disableSwipeAnimate(direction) {
    const { onSwipeAnimate } = this.props;
    onSwipeAnimate('disable', direction)
  }

  onSwipeComplete(direction) {
    const { onSwipeLeft, onSwipeRight, onSwipeAnimate, data } = this.props;
    const quizCard = data[this.state.index];
    const yes = true;
    const no = false;
    const didSwipeLeftIntroScreen = (this.state.index === 0 && direction === 'left')
    if(didSwipeLeftIntroScreen) {
      return this.position.setValue({ x: 0, y: 0 });
    }

    direction === 'right' ? onSwipeRight(yes, quizCard) : onSwipeLeft(no, quizCard);
    this.position.setValue({ x: 0, y: 0 });
    this.setState({ index: this.state.index + 1 });
  }

  forceSwipe(direction) {
    const {  onSwipeAnimate } = this.props;
    onSwipeAnimate(direction)
    const x = direction === 'right' ? SCREEN_WIDTH  : -SCREEN_WIDTH;
    Animated.timing(this.position, {
      toValue: { x, y: 0 },
      duration: SWIPE_OUT_DURATION
    }).start(() => this.onSwipeComplete(direction));
  }

  resetPosition() {
    Animated.spring(this.position, {
      toValue: { x: 0, y: 0 }
    }).start();
  }

  getCardStyle() {
    const { position } = this;
    const rotate = position.x.interpolate({
      inputRange: [-SCREEN_WIDTH * 2.5, 0, SCREEN_WIDTH * 2.5],
      outputRange: ['-120deg', '0deg', '120deg']
    });

    return {
      ...position.getLayout(),
      transform: [{ rotate }]
    };
  }

renderCardItem(quiz, i) {
    if (i === this.state.index && quiz.id === 10000000) {
      return (
        <SafeAreaView style={[this.getCardStyle(), styles.cardStyle, { marginTop: 10 }]}
            key={quiz.id}>
        <Animated.View  style={[this.getCardStyle(), { alignItems: 'center', justifyContent: 'center', zIndex: 5000}]}
          {...this._panResponder.panHandlers}
        >
          {this.props.renderCard(quiz)}
        </Animated.View>

        <SafeAreaView style={{ alignItems: 'center', justifyContent: 'center'}}>
          <Text
            style={styles.questionText}>
            {quiz.question}
          </Text>

          <Text
            style={styles.subQuestionText}>
            To begin, swipe the card above <Text style={styles.bold900Text}>right</Text> for <Text style={styles.yesText}>YES</Text>.
          </Text>
          <Text
            style={styles.descriptionText}>
            Swipe the card <Text style={styles.bold900Text}>left</Text> for <Text style={styles.noText}>NO</Text>.
          </Text>
        </SafeAreaView>
      </SafeAreaView>
      )
   } else if (i === this.state.index) {
     return (
       <SafeAreaView style={[this.getCardStyle(), styles.cardStyle, { marginTop: 10 }]}
           key={quiz.id}>
       <Animated.View  style={[this.getCardStyle(), { alignItems: 'center', justifyContent: 'center', zIndex: 5000}]}
         {...this._panResponder.panHandlers}
       >
         {this.props.renderCard(quiz)}
       </Animated.View>

       <SafeAreaView style={{ alignItems: 'center', justifyContent: 'center'}}>
         <Text
           style={styles.questionText}>
           {quiz.question}
         </Text>

       </SafeAreaView>
     </SafeAreaView>
     )
   }
 }

  render() {
    return <View>{this.props.data.map(this.renderCardItem)}</View>;
  }
}

export default SwipeContainer;
