import React, { Component } from 'react'
import ReactRedux, { connect } from 'react-redux'
import { styles } from './QuizStyle.js'
import { StackActions, NavigationActions } from '@react-navigation/native'
import { Image, Button, View, Text, TouchableOpacity, Keyboard, Animated, Easing } from 'react-native'
import GameContainer from '../../common/GameContainer'
import SwipeContainer from '../../common/SwipeContainer'
import GameDisclosure from '../../common/forms/GameDisclosure'
import QuizData from '../../../mocks/QuizData'
import QuizCard from '../../common/QuizCard'
import OnPanAnimation from '../../common/OnPanAnimation'
import QuizProgressBar from '../components/QuizProgressBar'
import GreatNews from '../components/GreatNews'
import BrandImage from '../components/BrandImage'
import Icon from 'react-native-vector-icons/FontAwesome'
import { trackAnswer, trackCardView, trackFocus, trackSubmit, trackClick, setUserID, trackError, trackUserProperty } from '../../../mixins/Track'
import { getParamsFromURL } from '../../../helpers/url-helper'
import { buildRateData } from '../../../helpers/rate-helper'
import * as Linking from 'expo-linking'
import { isEmailValid, isZipCodeValid } from '../../../helpers/validations'
import AsyncStorage from '@react-native-community/async-storage'
import LoanDomain from '../../../client/domain/LoanDomain'

const footerText = "Don't worry! Seeing your loan matches won't affect your credit score. "
const progressCompletionThreshold = 1
const renderCards = ({question, subQuestion, description, image, category }) => {
 return (
   <QuizCard
    question={question}
    subQuestion={subQuestion}
    description={description}
    category={category}
    image={image}
    />
   );
 }

const mapStateToProps = ({ session }) => {
  return {
  }
}

const mapDispatchToProps = {
}


class QuizContainer extends Component {
  state = {
    yesCount: 0,
    noCount: 0,
    progress: 0.05, // starting at 0 might not be a good experience
    onPanTouch: false,
    direction: 'none',
    queryParams: {},
    email: null,
    zipcode: null,
    emailError: false,
    zipcodeError: false,
    emailValid: false,
    zipcodeValid: false,
    continueBtnValid: false,
    rateResults: [],
    errors: [],
    getStartedValid: false
  };

  constructor(props) {
    super(props)
    this._transitionToLoans = this._transitionToLoans.bind(this)
    this._transitionToGame = this._transitionToGame.bind(this)
    this._onChangeZipCode = this._onChangeZipCode.bind(this)
    this._onChangeEmail = this._onChangeEmail.bind(this)
    this._handlePolicyClick = this._handlePolicyClick.bind(this)
    this._handleTermsClick = this._handleTermsClick.bind(this)
    this._eventHandler = this._eventHandler.bind(this)
    this.triggerShakeAnimation = this.triggerShakeAnimation.bind(this)
    this.runValidation = this.runValidation.bind(this)
  }

  componentWillMount() {
    this.animation = new Animated.Value(0)
  }

  componentDidMount() {
    getParamsFromURL().then((url) => {
      if (url) {
        let newURL = Linking.parse(url)
        this._setParams(newURL.queryParams)
      }
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
  }

  handleResponse = (yes, quizCard) => {
    trackUserProperty(`${quizCard.marketing_id}`, `${yes}`)
    trackAnswer({ source: quizCard.question, value: yes ? 1 : 0 })

    if(yes) {
      this.setState(({ yesCount }) => ({
        yesCount: yesCount + 1
      }));
    } else {
      this.setState(({ noCount }) => ({
        noCount: noCount + 1
      }));
    }

    this.setState(({ progress }) => ({
      progress: ((this.state.yesCount + this.state.noCount) / QuizData.length)
    }));
  };

  configureAnimate = (state, swipeDirection) => {
    let visibility = false

    if (state === 'enable') {
      visibility = true
    }

    return this.setState(({ onPanTouch, direction }) => ({
      onPanTouch: visibility,
      direction: swipeDirection
    }));
  }

  _transitionToLoans() {
     trackClick({ source: 'Match Loans Button Click'})
     const { route, navigation } = this.props
     const { email, zipcode, rateResults } = route.params
     return this.props.navigation.navigate('Loan Application', { screen: 'Loan Application', params: { email: email, zipcode: zipcode, rateResults: rateResults} })
   }

   _setParams(params) {
     return this.setState({ queryParams: params })
   }

   _setEmailError() {
     trackClick({ source: 'Match Email Field Invalid', value: this.state.email})
     return this.setState(({ emailError }) => ({
       emailError: true,
       emailValid: false
     }));
   }

   _setZipCodeError() {
     trackClick({ source: 'Match Zipcode Field Invalid', value: this.state.zipcode})
     return this.setState(({ zipcodeError }) => ({
       zipcodeError: true,
       zipcodeValid: false
     }));
   }

   _onChangeZipCode(value) {
     this.setState(({ zipcode }) => ({
       zipcode: value
     }));
   }

   _onChangeEmail(value) {
     this.setState(({ email }) => ({
       email: value
     }));
   }

   _handleTermsClick() {
     WebBrowser.openBrowserAsync('https://financialstress.com/terms/')
   }

   _handlePolicyClick() {
     WebBrowser.openBrowserAsync('https://financialstress.com/privacy-policy/')
   }


   _eventHandler(type, field) {
     const { email, zipcode, emailValid, zipcodeValid } = this.state
     if(type === 'focus') {
       if (field === 'Email') {
         this.setState({ emailValid: true }, this.runValidation)
       } else if(field === 'Zipcode') {
         const isValid = isZipCodeValid(zipcode)
         this.setState({ zipcodeValid: true }, this.runValidation)
       }

       trackFocus({ source: `Match ${field} Field Focus` })
     } else {
       trackSubmit({ source: `Match ${field} Field Submit` })
     }
   }

   createLead(state) {
     const { zipcode, email } = state
     const loanDomain = new LoanDomain()
     return AsyncStorage.getItem('@SESSION_UUID').then((session_uuid) => {
       return loanDomain.getRates({
         "session_id": session_uuid,
         "type": "CreateLead",
         "offer_type": "loan",
         "zipcode": zipcode,
         "email": email,
         "loan_amount": 10000,
         "purpose": 'debt_consolidation',
         "provided_credit_rating": 'excellent'
       })
     })
   }

   runValidation() {
     const { emailValid, zipcodeValid } = this.state
     const valid = emailValid && zipcodeValid

     return this.setState({ continueBtnValid: valid })
   }

   _transitionToGame(target) {
     Keyboard.dismiss()
     const { zipcode, email } = this.state
     const isValid = isEmailValid(email) && isZipCodeValid(zipcode)

     trackClick({ source: 'Match Submit Click' })
     return this._transitionOrInvalidate(isValid)
   }

   _transitionOrInvalidate(isValid) {
     const { zipcode, email } = this.state

     if(isValid) {
       trackUserProperty('email', email)
       trackUserProperty('zipcode', zipcode.toString())
       trackUserProperty('match_completed', 'true')
       this.setState({ getStartedValid: true })
       this.createLead(this.state).then((rates) => {
         return this.props.navigation.navigate('Loan Application', { screen: 'Loan Application', params: { email: email, zipcode: zipcode, rateResults: rates} })
       })
     } else {
       !isEmailValid(email) ? this._setEmailError() : !isZipCodeValid(zipcode) ? this._setZipCodeError() : null
       this.triggerShakeAnimation()
       return this.setState({
           emailError: !isEmailValid(email), emailValid: isEmailValid(email),
           zipcodeError: !isZipCodeValid(zipcode), zipcodeValid: isZipCodeValid(zipcode)
       })
     }
   }

   triggerShakeAnimation() {
     this.animation.setValue(0)
     Animated.timing(this.animation, {
       duration: 400,
       toValue: 3,
       ease: Easing.bounce
     }).start()
   }

   render() {
     const { route, navigation } = this.props
     const { progress, onPanTouch, direction, yesCount, noCount, queryParams, errors } = this.state
     const interpolated = this.animation.interpolate({
       inputRange: [0, .5, 1, 1.5, 2, 2.5, 3],
       outputRange: [0, -15, 0, 15, 0, -15, 0]
     })

     if(onPanTouch) {
       return (
         <GameContainer>
           <BrandImage />
           <QuizProgressBar progress={progress}/>

           <SwipeContainer
             data={QuizData}
             renderCard={renderCards}
             onSwipeRight={this.handleResponse}
             onSwipeLeft={this.handleResponse}
             onSwipeAnimate={this.configureAnimate}
            />

           <OnPanAnimation direction={direction} responseCount={(yesCount+noCount)}/>
         </GameContainer>
       )
     }

     if(progress === progressCompletionThreshold) {
       return (
           <GreatNews
             target={queryParams.target || 'A'}
             transitionToLoans={this._transitionToLoans}
             onChangeZipCode={this._onChangeZipCode}
             transitionToGame={this._transitionToGame}
             onChangeZipCode={this._onChangeZipCode}
             onChangeEmail={this._onChangeEmail}
             errors={errors}
             interpolated={interpolated}
             state={this.state}
             eventHandler={this._eventHandler}
             handlePolicyClick={this._handlePolicyClick}
             handleTermsClick={this._handleTermsClick}
             footerText={footerText}
             progress={progress}
           />
       )

     } else {
       return (
         <GameContainer>
           <BrandImage />
           <QuizProgressBar progress={progress}/>
           <SwipeContainer
             data={QuizData}
             renderCard={renderCards}
             onSwipeRight={this.handleResponse}
             onSwipeLeft={this.handleResponse}
             onSwipeAnimate={this.configureAnimate}
            />
         </GameContainer>
       )
     }
   }
 }


export default connect(mapStateToProps, mapDispatchToProps)(QuizContainer)
