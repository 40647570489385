import React, { Component } from 'react'
import { styles } from './SharedStyle.js'
import { Text, View, FlatList } from 'react-native';
const _ = require('underscore')
import validate from 'valib'

const Item = ({ description }) => (
  <Text numberOfLines={2} style={styles.errorText}>*** {description}</Text>
);

const Error = ({ errors }) => {
  const renderError = (error) => (
    <Item description={error.item.description} />
  );

  return (validate.Array.isEmpty(errors)) ? (null) :
    (<View style={styles.errors}>
        <FlatList
          data={errors}
          renderItem={(error) => renderError(error)}
          keyExtractor={error => error.id}
        />
      </View>)
}

export default Error
