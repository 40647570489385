import React, { Component } from 'react'
import { styles } from './SharedStyle.js'
import { Dimensions, View, Image, Text, TouchableOpacity } from 'react-native'
import { Button } from 'native-base'
import Icon from 'react-native-vector-icons/FontAwesome';
import BrowserSniff from '../../common/BrowserSniff'

const logo = require('./images/logo.png')

const BrandImage = () => {
  return (
    <View style={styles.brandView}>
        <Image source={logo} style={{width: 150, height: 27}}/>
    </View>
  )
}

export default BrandImage
