import React, { Component } from 'react'
import { styles } from './SharedStyle.js'
import { Form, Item, Input, Label, Button, Text } from 'native-base';
import { Keyboard, TextInput, View, TouchableWithoutFeedback, Animated, Easing } from 'react-native';
import '@expo/match-media'
import MediaQuery from "react-responsive";
import { TextInputMask } from 'react-native-masked-text'
const v = require('valib')
//style={[styles.inputStyle, { outlineColor: 'transparent',outline: "none", outlineWidth: 0 }]}
class LaunchForm extends Component {
  constructor(props) {
    super(props)
  }

 //  <Button onPress={transitionToGame} style={[styles.continueBtn]} full rounded>
 //     <Text uppercase={false} style={styles.continueText}>Get Started</Text>
 // </Button>
  render() {
    const { removeFieldsFromView, interpolated, transitionToGame, onChangeEmail,
       onChangeZipCode, state, eventHandler, actionBtnText } = this.props
    const { getStartedValid, zipcode, email, zipcodeError, emailError, emailValid,
      zipcodeValid, continueBtnValid } = state

    const style = {
      transform: [
        { translateX: interpolated }
      ]
    }

    if(removeFieldsFromView) {
      return(
        <Form style={styles.launchForm}>
          <View>
          <TouchableWithoutFeedback onPress={() => transitionToGame('B')}>
            <Animated.View disabled={!continueBtnValid} style={[styles.continueBtnValid, style, { marginTop: 75, marginBottom: 125 }]}>
            <Text uppercase={false} style={styles.continueText}>Get Started</Text>
            </Animated.View>
          </TouchableWithoutFeedback>
         </View>
        </Form>
      )
    }

    return(
      <Form style={styles.launchForm}>
        <View>
          <TextInput
            style={!emailError ? styles.item : styles.itemError }
            onChangeText={text => onChangeEmail(text)}
            onFocus={() => eventHandler('focus', 'Email')}
            onBlur={() => eventHandler('blur', 'Email')}
            style={emailValid ? styles.itemValid : emailError ? styles.itemError : styles.item}
            keyboardType="email-address"
            returnKeyType='next'
            placeholder="Email Address"
            onKeyPress={(event) => {
              if(event.nativeEvent.key == "Enter"){
                transitionToGame()
              }
            }}
            value={email}
          />

          <TextInputMask
            type={'custom'}
            options={{
              /**
               * mask: (String | required | default '')
               * the mask pattern
               * 9 - accept digit.
               * A - accept alpha.
               * S - accept alphanumeric.
               * * - accept all, EXCEPT white space.
              */
              mask: '99999'
            }}
            style={zipcodeValid ? styles.itemValid : zipcodeError ? styles.itemError : styles.item}
            autoCorrect={false}
            onChangeText={(text) => onChangeZipCode(text)}
            onFocus={() => eventHandler('focus', 'Zipcode')}
            onBlur={() => eventHandler('blur', 'Zipcode')}
            onKeyPress={(event) => {
              if(event.nativeEvent.key == "Enter"){
                transitionToGame()
              }
            }}
            returnKeyType={'done'}
            placeholder="ZIP Code"
            value={zipcode}
            keyboardType={undefined}
          />

        <TouchableWithoutFeedback onPress={transitionToGame}>
          <Animated.View disabled={!continueBtnValid} style={continueBtnValid ? [styles.continueBtnValid, style] : [styles.continueBtn, style]}>
          <Text uppercase={false} style={styles.continueText}>{getStartedValid ? 'Loading...' : v.String.isEmpty(actionBtnText) ? 'Get Started' : actionBtnText}</Text>
          </Animated.View>
        </TouchableWithoutFeedback>
       </View>
      </Form>
    )
  };
}
export default LaunchForm
