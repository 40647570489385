const LOAN_AMOUNT_RANGE_THRESHOLD = 10000
const LOAN_AMOUNT_NEAR_THRESHOLD = 1000
const _ = require('underscore')

const inLoanRange = (loanAmount, offer) => {
  return ((loanAmount + LOAN_AMOUNT_RANGE_THRESHOLD) > offer.maxAmount &&
         (loanAmount - LOAN_AMOUNT_RANGE_THRESHOLD) < offer.maxAmount)
}

const isNearestOffer = (loanAmount, offer) => {
  return ((loanAmount + LOAN_AMOUNT_NEAR_THRESHOLD) > offer.maxAmount &&
         (loanAmount - LOAN_AMOUNT_NEAR_THRESHOLD) < offer.maxAmount)
}

const sortLoans = (loanAmount, offers) => {
  let bestMatchedOffers = []
  let hiddenMatchOffers = []
  let closestOfferSet = _.filter(offers, (offer) => inLoanRange(loanAmount, offer.maxAmount))
  if(_.isEmpty(closestOfferSet)) { closestOfferSet = offers } // edge case for when someone does not have any loan matches +- 10000

  const groupedProviders = _.groupBy(offers, (offer) => {
    return offer.originator.name
  })

  for(const key of _.keys(groupedProviders)) {
    const provider = groupedProviders[key]
    const groupedTerms = _.groupBy(provider, (offer) => {
      return offer.termLength
    })

    for (const term of _.keys(groupedTerms)) {
      const loanOffers = groupedTerms[term]
      const bestOffersFromTerm = getBestOffersFromTerm(loanAmount, loanOffers)
      const [topOffer] = bestOffersFromTerm
      bestOffersFromTerm.map((offer) => topOffer.uuid === offer.uuid ? null : hiddenMatchOffers.push(offer))
      bestMatchedOffers.push(topOffer)
    }
  }

  return sortByLowestMonthlyPayment(bestMatchedOffers)
}

const getBestOffersFromTerm = (loanAmount, loanOffers) => {
  return _.compact(loanOffers.map((offer) => offer.preQualified === true || offer.preApproved === true ? offer : null)).sort((a, b) => {
    return (  // TBD = 100000 for right now (largest number)
        (parseFloat(a.monthlyPayment || '100000') -
         parseFloat(b.monthlyPayment || '100000')) &&
        (isNearestOffer(loanAmount, a) === isNearestOffer(loanAmount, b) ? 0 : isNearestOffer(loanAmount, a) ? -1 : 1)
      )
    })
}

const sortByLowestMonthlyPayment = (bestMatchedOffers) => {
  return _.compact(bestMatchedOffers).sort((a, b) => {
    return (  // TBD = 100000 for right now (largest number)
        parseFloat(a.monthlyPayment || '100000') -
        parseFloat(b.monthlyPayment || '100000')
      )
    })
}

export { inLoanRange, getBestOffersFromTerm, sortByLowestMonthlyPayment, isNearestOffer, sortLoans }
