import React, { Component } from 'react'
import { Provider } from 'react-redux'
import store from '../store'
import * as Font from 'expo-font'
import { AppLoading } from 'expo'
import { NavigationContainer } from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'
import * as Linking from 'expo-linking'
import { trackPageView, trackUserProperty, setUserID } from '../mixins/Track'
import { View, Text, Button, Image } from 'react-native'
import { Asset } from 'expo-asset'
import QuizData from '../mocks/QuizData'
import AsyncStorage from '@react-native-community/async-storage'
import { LoanStackScreen, MatchStackScreen, SubscribeStackScreen,
  QuizStackScreen, LaunchStackScreen } from './navigation/Screens'
import uuid from '../helpers/client-uuid'

const _ = require('underscore')
const RootStack = createStackNavigator();

const cacheImages = (images) => {
  return images.map(image => {
    if (typeof image === 'string') {
      return Image.prefetch(image)
    } else {
      return Asset.fromModule(image).downloadAsync()
    }
  })
}

const cacheFonts = () => {
  return Font.loadAsync({
    'Nunito-Regular': require('../../assets/fonts/Nunito-Regular.ttf'),
    'Nunito-SemiBold': require('../../assets/fonts/Nunito-SemiBold.ttf'),
    'Nunito-Bold': require('../../assets/fonts/Nunito-Bold.ttf'),
    'Nunito-Black': require('../../assets/fonts/Nunito-Black.ttf'),
  })
}

const componentAssets = [
  require('./matches/components/images/background.png'),
  require('./matches/components/images/text.png'),
  require('./common/images/noAnimation.png'),
  require('./common/images/yesAnimation.png'),
  require('./loans/components/images/logo.png'),
  require('./quiz/components/images/background.png'),
  require('./quiz/components/images/logo.png'),
  require('./quiz/components/images/success.png'),
  require('./subscribe/components/images/financial_health.jpg'),
  require('./subscribe/components/images/logo.png'),
  require('./matches/components/images/eho-black.png')
]

const preLoadAssets = [
  require('./launch/components/images/logo.png'),
  require('./launch/components/images/overlay_logos.png'),
  require('./launch/components/images/background.png'),
]

const findRootStackBy = (target) => {
  if(target === 'B') {
    return (
      <RootStack.Navigator headerMode="none">
        <RootStack.Screen name="Match" component={QuizStackScreen} />
        <RootStack.Screen name="Subscribe" component={SubscribeStackScreen} />
        <RootStack.Screen name="Loan Application" component={LoanStackScreen} />
        <RootStack.Screen name="Loan Offers" component={MatchStackScreen} />
      </RootStack.Navigator>
    )
  } else {
    return (
      <RootStack.Navigator headerMode="none">
        <RootStack.Screen name="Get Started" component={LaunchStackScreen} />
        <RootStack.Screen name="Match" component={QuizStackScreen} />
        <RootStack.Screen name="Subscribe" component={SubscribeStackScreen} />
        <RootStack.Screen name="Loan Application" component={LoanStackScreen} />
        <RootStack.Screen name="Loan Offers" component={MatchStackScreen} />
      </RootStack.Navigator>
    )
  }
}


export default class App extends Component {
  state = {
    isReady: false,
    queryParams: {},
    uuid: ''
  }

  constructor(props) {
    super(props)
    this.routeNameRef = React.createRef()
    this.navigationRef = React.createRef()

    this.setup = this.setup.bind(this)
  }

  componentDidMount() {
    let images = QuizData.map(quiz => {
      return quiz.image
    })

   componentAssets.map(image => {
     return images.push(image)
   })

    cacheImages(images)
  }

  componentWillUnmount() {
  }


  setUUID() {
    setUserID(uuid)
    return AsyncStorage.setItem('@SESSION_UUID', uuid)
  }

  async _loadAssetsAsync() {
    let images = []

     preLoadAssets.map(image => {
       return images.push(image)
     })

    const imageAssets = cacheImages(images)
    const fontAssets = cacheFonts()

    await Promise.all([fontAssets, ...imageAssets])
 }

  setup() {
    this.setUUID()
    this._getParamsFromURL()
  }

  _getParamsFromURL() {
    Linking.getInitialURL().then((url) => {
      if (url) {
        let newURL = Linking.parse(url)
        this._setParams(newURL.queryParams)
      }
    }).catch(err => console.error('An error occurred getting params=', err));
  }

  _setParams(params) {
    return this.setState(({ queryParams }) => ({
      queryParams: params,
      isReady: true
    }));
  }

  // <RootStack.Screen name="Get Started" component={LaunchStackScreen} />
  // <RootStack.Screen name="Match" component={QuizStackScreen} />
  // <RootStack.Screen name="Subscribe" component={SubscribeStackScreen} />
  // <RootStack.Screen name="Loan Application" component={LoanStackScreen} />
  // <RootStack.Screen name="Loan Offers" component={MatchStackScreen} />
  // if(queryParams.key === 'PL1') {}

  render() {
    const { queryParams } = this.state
    const { target } = queryParams

    trackUserProperty('target', (target || 'none'))
    // most likely add query params to redux

    if (this.state.isReady) {
        return (
          <Provider store={store}>
            <NavigationContainer
                  ref={this.navigationRef}
                  onReady={(() => {
                    trackUserProperty('last_screen_viewed', this.navigationRef.current.getCurrentRoute().name)
                    console.log('FinancialStress.com Loan Match Tool - v1.0.1')
                    this.routeNameRef.current = this.navigationRef.current.getCurrentRoute().name
                    trackPageView({ source: this.navigationRef.current.getCurrentRoute().name })
                  })}
                  onStateChange={() => {
                    const previousRouteName = this.routeNameRef.current
                    const currentRouteName = this.navigationRef.current.getCurrentRoute().name

                    if (previousRouteName !== currentRouteName) {
                      trackUserProperty('last_screen_viewed', currentRouteName)
                      trackPageView({ source: currentRouteName })
                    }

                    this.routeNameRef.current = currentRouteName;
                  }}>
                {findRootStackBy(target)}
            </NavigationContainer>
          </Provider>
        )
    } else {
      return (
        <AppLoading
          startAsync={this._loadAssetsAsync}
          onFinish={this.setup}
        />
      )
    }
  }
}
