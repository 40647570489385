export default [
  {
    id: 1,
    name:'Avant',
    description:'Founded in Chicago in 2012 with a mission to lower barriers and the cost of borrowing.',
  },
  {
    id: 2,
    name:'Bestegg',
    description:'Founded in 2013 with a mission to find better ways to make money accessible to customers.',
  },
  {
    id: 3,
    name:'LendingClub',
    description:'Founded in 2006 with a mission to transform the banking system making credit more affordable and investing more rewarding.',
  },
  {
    id: 4,
    name:'LendingPoint',
    description:'Founded in 2014 with a mission to democratize credit and accelerate commerce.',
  },
  {
    id: 5,
    name:'LightStream',
    description:'A division of SunTrust Bank now Truist with a mission to keep the loan process simple with excellent rates for good credit customers.',
  },
  {
    id: 6,
    name:'Marcus',
    description:'The online banking lender of Goldman Sachs with a mission to allow consumers to take control of their financial lives.',
  },
  {
    id: 7,
    name:'OneMain Financial',
    description:'Founded in 1912 with a mission to be the leading provider of responsible lending products, with exceptional customer experience.',
  },
  {
    id: 8,
    name:'Prosper',
    description:'Founded in 2005 with a mission to advance financal well-being by making investing both financially and socially rewarding.',
  },
  {
    id: 9,
    name:'SoFi',
    description:'Founded in 2011 with a mission to help people reach financial independence to realize their ambitions.',
  },
  {
    id: 10,
    name:'Upgrade',
    description:'Founded in 2016 with a mission to help customers make good financial decisions so they can build the future they want.',
  },
  {
    id: 11,
    name:'OneMain',
    description:'Founded in 1912 with a mission to be the leading provider of responsible lending products, with exceptional customer experience.',
  },
]
