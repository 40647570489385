import React, { Component } from 'react'
import { styles } from './SharedStyle.js'
import { Dimensions, View, Image, Text, ScrollView, TextInput, TouchableOpacity, TouchableHighlight } from 'react-native'
import { Button } from 'native-base'
import Icon from 'react-native-vector-icons/FontAwesome';
import { Card, CardItem, Body } from 'native-base';

class AutoCompleteCard extends Component {

  render() {
    const { result, autoCompleteHandler } = this.props
    const { structured_formatting } = result
    const { main_text, secondary_text } = structured_formatting

    return (
    <TouchableOpacity onPress={()=>autoCompleteHandler(result)}>
      <Card>
         <CardItem>
           <Body>
             <Text>
              {main_text}
             </Text>
             <Text>
              {secondary_text}
             </Text>
           </Body>
         </CardItem>
        </Card>
      </TouchableOpacity>
    )
  }
}

export default AutoCompleteCard
