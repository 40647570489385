import React, { Component } from 'react'
import { styles } from './SharedStyle.js'
import { Dimensions, View, Image, Text, ScrollView, TextInput, TouchableHighlight } from 'react-native'
import { Button } from 'native-base'
import Icon from 'react-native-vector-icons/FontAwesome';

class IncomeDisclosure extends Component {

  render() {
    const { transitionBack } = this.props

    return (
      <View style={styles.disclosureView}>
        <Button onPress={transitionBack} style={styles.closeBtn} full rounded>
          <Icon name="times" size={30} color="#000" />
        </Button>

        <Text style={styles.headerText}>Income Disclosure</Text>
        <ScrollView style={{ padding: 10}}>
          <Text style={styles.consentText}>
          Your yearly income before taxes, Alimony, child support, or separate maintenance is optional and does not need to be included if you do not wish it to be considered as a basis for repaying the loan.  Increase any non-taxable income by 25%
          </Text>
        </ScrollView>

      </View>
    )
  };
}

export default IncomeDisclosure
