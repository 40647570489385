import React, { Component, useRef } from 'react'
import { Container, Content} from 'native-base'
import { Keyboard, PanResponder, Dimensions, Animated } from 'react-native'
import { connect } from 'react-redux'
import GameContainer from '../../common/GameContainer'
import PreQualForm from '../components/PreQualForm'
import ConsentForm from '../../common/forms/ConsentForm'
import AdvertiserDisclosure from '../../common/forms/AdvertiserDisclosure'
import Disclosures from '../../common/forms/Disclosures'
import SecurityDisclosure from '../../common/forms/SecurityDisclosure'
import IncomeDisclosure from '../../common/forms/IncomeDisclosure'
import LoanEstimateTool from '../components/LoanEstimateTool'
import { searchOfferRequest } from '../../../modules/offers/actions'
import * as WebBrowser from 'expo-web-browser';
import LoanDomain from '../../../client/domain/LoanDomain'
import GooglePlaceDomain from '../../../client/domain/GooglePlaceDomain'
import { getCreditRatingFromScore, getCreditRatingFromSlider, getCreditEnumFromKey } from '../../../helpers/credit-score'
import { isEmailValid, isZipCodeValid, isFieldValid, isBirthDateValid, isPhoneNumberValid, isCurrencyValid, isSocialSecurityValid } from '../../../helpers/validations'
import SCREEN_MAP from './LoanScreenMap'
import DEFAULT_STATE from './DefaultState'
import { trackFocus,  trackPageView, trackSubmit, trackLoanTool, trackClick, trackError, trackUserProperty } from '../../../mixins/Track'
import BrandImage from '../components/BrandImage'
import AdMatchDisclosure from '../components/AdMatchDisclosure'
import { buildRateData } from '../../../helpers/rate-helper'

const v = require('valib')
const _ = require('underscore')
const MS_PER_SEC = 1000
const MAX_INDEX = SCREEN_MAP.length + 1
const SCREEN_WIDTH = Dimensions.get('window').width;
const SWIPE_THRESHOLD = 0.1 * SCREEN_WIDTH;
const mapStateToProps = ( { session, offers }) => { return {} }
const mapDispatchToProps = {}

const canFetchAutoComplete = (key, value, autoCompleteSelected) => {
  return (key === "address1" || key === "address2")
        && value.length > 5 && !autoCompleteSelected
}

const sortRateResults = (loanOffers) => {
  if(v.Array.isEmpty(loanOffers)) { return [] }
  return loanOffers.sort((a, b) => {
    // TBD = 5000 for right now (largest number)
    return (parseFloat(a.minMonthlyPayment || a.monthlyPayment || a.meanMonthlyPayment || a.maxMonthlyPayment || '5000') -
          parseFloat(b.minMonthlyPayment ||  b.monthlyPayment || b.meanMonthlyPayment || b.maxMonthlyPayment || '5000'))
  })
}

class LoanContainer extends Component {
  constructor(props) {
    super(props)
    this.state = DEFAULT_STATE
    this.position = new Animated.ValueXY()
    this.setPanHandler()
    this.inputRefs = {
      educationPicker: null,
      employmentStatusPicker: null,
      employmentPayFrequencyPicker: null,
    }
    this.offerListRef = null
    this._transitionToDisclosure = this._transitionToDisclosure.bind(this)
    this._transitionToAdDisclosure = this._transitionToAdDisclosure.bind(this)
    this._transitionBack = this._transitionBack.bind(this)
    this._loanEstimateHandler = this._loanEstimateHandler.bind(this)
    this._transitionToEmail = this._transitionToEmail.bind(this)
    this._transitionToEAgreement = this._transitionToEAgreement.bind(this)
    this._transitionToTerms = this._transitionToTerms.bind(this)
    this._transitionToPrivacyPolicy = this._transitionToPrivacyPolicy .bind(this)
    this._transitionToCreditAgreement = this._transitionToCreditAgreement.bind(this)
    this._completionHandler = this._completionHandler.bind(this)
    this._onBack = this._onBack.bind(this)
    this._onTextChange = this._onTextChange.bind(this)
    this._onPickerChange = this._onPickerChange.bind(this)
    this._autoCompleteHandler = this._autoCompleteHandler.bind(this)
    this._onFocusHandler = this._onFocusHandler.bind(this)
    this._transitionToIncomeDisclosure = this._transitionToIncomeDisclosure.bind(this)
    this._transitionToMatchDisclosure = this._transitionToMatchDisclosure.bind(this)
    this.runValidation = this.runValidation.bind(this)
    this.getRates = this.getRates.bind(this)
    this._onViewableItemsChanged = this._onViewableItemsChanged.bind(this)
    this._onSlidingComplete = this._onSlidingComplete.bind(this)
    this._setOfferListRef = this._setOfferListRef.bind(this)
    this._resetOfferList = this._resetOfferList.bind(this)
    this._scrollToVisibleItem = this._scrollToVisibleItem.bind(this)
    this._scrollToVisibleItemWithAnimation = this._scrollToVisibleItemWithAnimation.bind(this)
    this.setPanHandler = this.setPanHandler.bind(this)
    this.disablePanResponder = this.disablePanResponder.bind(this)
    this._onSnapToItem = this._onSnapToItem.bind(this)
  }

  setup() {
    this.runValidation() // checks to see what has been configured initially for the screen map.
  }

  _setOfferListRef(ref) {
    this.offerListRef = ref
  }

  _resetOfferList() {
    const { sortedLoanOffers, visibleOffer } = this.state
    if(_.isNull(this._panResponder)) {
      return this.offerListRef.scrollToItem({ animated: true, item: sortedLoanOffers[0], viewPosition: 0.5 })
    }
    this.offerListRef.snapToItem(visibleOffer, false, true)
  }

  componentDidMount() {
    const { route, navigation } = this.props
    if(route.params !== undefined) {
      const { email, zipcode, rateResults } = route.params
      const { loanOffers } = rateResults
      this.setState({ launchEmail: email, launchZipcode: zipcode,
        sortedLoanOffers: sortRateResults(loanOffers), rateResults: rateResults })
    }

    this.setup()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
  }

  setPanHandler() {
    this._panResponder = PanResponder.create({
      onStartShouldSetPanResponder: (evt, gestureState) => true,
      onStartShouldSetPanResponderCapture: (evt, gestureState) => true,
      onMoveShouldSetPanResponder: (evt, gestureState) => true,
      onMoveShouldSetPanResponderCapture: (evt, gestureState) => true,
      onPanResponderGrant: (evt, gesture) => true,
      onPanResponderMove: (evt, gesture)=> true,
      onPanResponderRelease: (evt, gesture) => {
        this.position.setValue({ x: gesture.dx, y: gesture.dy });
        if (gesture.dx > SWIPE_THRESHOLD) {
           this.forceScroll('right')
         } else if (gesture.dx < - SWIPE_THRESHOLD) {
           this.forceScroll('left')
         }
      },
    })
  }

  disablePanResponder() {
    this._panResponder = null
  }

  _onViewableItemsChanged({ viewableItems }) {
    const { visibleOffer } = this.state
    if(_.isNull(this._panResponder)) {
      if(!(v.Array.isEmpty(viewableItems))) {
        return this.setState({ visibleOffer: viewableItems[0].index  })
      } else {
        return this.setState({ visibleOffer: visibleOffer + 1 })
      }
    }
  }

  _onSnapToItem(index) {
    return this.setState({ visibleOffer: index })
  }

  forceScroll(side) {
    const { visibleOffer, sortedLoanOffers } = this.state
    const index = visibleOffer
    let newIndex = 0

    // if(side === 'left') {
    //   if(index === 0) {
    //     newIndex = index + 1
    //   } else {
    //     newIndex = index - 1
    //   }
    // } else {
    //   if(index === sortedLoanOffers.length) {
    //     newIndex = 0
    //   } else {
    //     newIndex = index + 1
    //   }
    // }

    if(index === sortedLoanOffers.length) {
      newIndex = 0
    } else {
      newIndex = index + 1
    }

    const item = sortedLoanOffers[newIndex]
    return this.setState({ visibleOffer: newIndex }, this._scrollToVisibleItemWithAnimation)
  }


  _transitionBack() {
    const { currentScreenIndex } = this.state
    const isToolScreen = SCREEN_MAP[currentScreenIndex - 1].screen === "LoanEstimateTool"

    this.setState({
      showDisclosure: false,
      showMatchDisclosure: false,
      showAdDisclosure: false,
      showIncomeDisclosure: false,
    }, isToolScreen ? this._scrollToVisibleItem : null)
  }

  _scrollToVisibleItem() {
    const { sortedLoanOffers, currentScreenIndex, visibleOffer} = this.state
    if(_.isNull(this._panResponder)) {
      return this.offerListRef.scrollToItem({ animated: false, item: sortedLoanOffers[visibleOffer - 1], viewPosition: 0.5})
    }

    setTimeout(() =>this.offerListRef.snapToItem(visibleOffer, false, true), 250)
  }

  _scrollToVisibleItemWithAnimation() {
    const { sortedLoanOffers, currentScreenIndex, visibleOffer} = this.state
    if( visibleOffer === sortedLoanOffers.length ) {
      return this.offerListRef.snapToItem(0, true, true)
    }
    return this.offerListRef.snapToNext(true, true)
  }

  _loanEstimateHandler() {
    this.setState({ rateBlockInProgress: true, sortedLoanOffers: []}, this.getRates)
  }

  getRates() {
    const loanDomain = new LoanDomain()
    return loanDomain.getRates(buildRateData(this.state))
    .then((rates) => {
      const { loanOffers } = rates
      const sortedLoanOffers = sortRateResults(loanOffers)
      this.setState({ sortedLoanOffers: sortedLoanOffers, rateResults: rates })
    }).finally((rates) => this.setState({ rateBlockInProgress: false }, this._resetOfferList))
  }

  _transitionToMatchDisclosure(selectedOffer) {
    trackClick({ source: 'Loan Tool Match Disclosure Click' })
    this.setState({ selectedOffer: selectedOffer, showMatchDisclosure: true })
  }

  _onTextChange(key, value) {
    trackFocus({source: `Loan ${key} Field Focus` })
    const { autoCompleteSelected } = this.state

    if(canFetchAutoComplete(key, value, autoCompleteSelected)) {
      this.fetchAutoComplete()
    }

    return this.setState({ [key]: { id: key, value: value, valid: true, error: false }}, this.runValidation)
  }

  _onSlidingComplete() {
    this._loanEstimateHandler()
  }

  _onFocusHandler(key) {
    const { loanAmount } = this.state
    if(key === "zipcode") { return this.setState({ [key]: { value: this.state.launchZipcode, valid: true }}, this.runValidation) }
    if(key === "loanAmount") { return  this.setState({ [key]: { value: '$' + this.state.loanAmount.toolValue, valid: true }}, this.runValidation) }
  }

  _onPickerChange(key, value, type) {
    const { currentScreenIndex } = this.state
    trackFocus({ source: `Loan ${key} Selected`, value: value })
    if(value === "choose") { return } // this is a hotfix for how the RPickers are doing double callbacks for web with placeholder val

    if(SCREEN_MAP[currentScreenIndex - 1].screen === "LoanEstimateTool") {
      if(key === "loanAmount") {
        return this.setState({ [key]: { id: key, toolValue: value, valid: true, error: false, }})
      } else if(key === "loanPurpose") {
        this._loanEstimateHandler()
      } else if(key === "creditScore") {
        if(this.didCreditRatingChange(value)) {
          this.setCreditRating(key, value)
        }
      }
    }
    return this.setState({ [key]: { id: key, value: value, valid: true, error: false }}, this.runValidation)
  }

  fetchAutoComplete() {
    const googleDomain = new GooglePlaceDomain()
    let results = []
    return googleDomain.getPlaces(this.state)
      .then((data) => this.setState({ autoCompleteResults: data}))
  }

  didCreditRatingChange(value) {
    const { creditScore } = this.state
    return creditScore.value !== value
  }

  setCreditRating(key, value) {
    return this.setState({
      creditRating: {
        id: key,
        value: getCreditEnumFromKey(value),
        valid: true,
        error: false
      }
    })
  }

  _autoCompleteHandler(result) {
    const { zipcode, launchZipcode } = this.state
    const { main_text, secondary_text } = result.structured_formatting
    const [ city, state, country ] = secondary_text.split(',').map((value) => value.trim())

    trackClick({source: 'Address AutoComplete Selected' })
    this.setState({
      address1: { id: 'address1', value: main_text, valid: true, error: false },
      city: { id: 'city', value: city, valid: true, error: false },
      stateAbv: { id: 'stateAbv', value: state, valid: true, error: false },
      zipcode: { id: 'zipcode', value: (zipcode.value || launchZipcode), valid: true, error: false },
      autoCompleteResults: [],
      autoCompleteSelected: true,
    }, this.runValidation)
  }

  _transitionToIncomeDisclosure() {
    trackClick({source: 'Loan Application Income Disclosure Click' })
    this.setState(({ showIncomeDisclosure }) => ({
      showIncomeDisclosure: true
    }))
  }

  _transitionToDisclosure() {
    trackClick({source: 'Loan Application Disclosures Click' })
    this.setState(({ showDisclosure }) => ({
      showDisclosure: true
    }))
  }

  _transitionToAdDisclosure() {
    trackClick({source: 'Loan Application Ad Disclosure Click' })
    this.setState(({ showDisclosure }) => ({
      showAdDisclosure: true
    }))
  }

  _completionHandler() {
    const { address1, address2, zipcode, city, stateAbv,
      continueBtn, educationLevel, employmentStatus,
      employmentPayFrequency, firstName, lastName, birthDate,
      email, phoneNumber, annualIncome, loanAmount, loanPurpose,
      creditRating, propertyStatus, socialSecurity, currentScreenIndex } = this.state
    const { navigate } = this.props.navigation
    if ((currentScreenIndex + 1) === MAX_INDEX) {
      Keyboard.dismiss()
      trackSubmit({ source: 'Loan Application Button Submit' })
      if(isSocialSecurityValid(socialSecurity.value)) {
        trackUserProperty('consent_screen_completed', 'true')
        return navigate('Loan Offers', { screen: 'Loan Offers', params: { state: this.state } })
      } else {
        ([socialSecurity]).map((attribute) => {
          return this.setState(state => {
            let attr = attribute
            let valid = isSocialSecurityValid(socialSecurity.value)
            attr.error = !valid
            attr.valid = valid
            return {
              continueBtnValid: false,
              [attribute.id]: attr
            }
          })
        })
      }
    }

    // Screen search ........ validation check..... ew
    const screenName = SCREEN_MAP[currentScreenIndex - 1].screen
    trackSubmit({ source: `Loan ${screenName} Continue Click` })

    if(screenName === "LoanEstimateTool") {
      trackUserProperty('tool_screen_completed', 'true')
      return this.setState(({ currentScreenIndex }) => ({
         currentScreenIndex: (currentScreenIndex + 1),
         continueBtnValid: false
       }), this.runValidation)
    }
    if(screenName === "EmploymentForm") {
      trackUserProperty('employment_completed', 'true')
      return this.setState(({ currentScreenIndex }) => ({
         currentScreenIndex: (currentScreenIndex + 1),
         continueBtnValid: false
       }), this.runValidation)
    }
    if(screenName === "AddressForm") {
     if(isFieldValid(address1.value) && isZipCodeValid(zipcode.value) && isFieldValid(city.value)) {
        trackUserProperty('address_screen_completed', 'true')
        return this.setState(({ currentScreenIndex }) => ({
          currentScreenIndex: (currentScreenIndex + 1),
          continueBtnValid: false
        }), this.runValidation)
      } else {
      ([address1, zipcode, city]).map((attribute) => {
        return this.setState(state => {
          let attr = attribute
          attr.error = attribute.id === 'zipcode' ? !isZipCodeValid(attribute.value) : !isFieldValid(attribute.value)
          attr.valid = attribute.id === 'zipcode' ? isZipCodeValid(attribute.value) : isFieldValid(attribute.value)
          return {
            continueBtnValid: false,
            [attribute.id]: attr
          }
        })
      })
    }
   }

    if(screenName === "BasicInfoForm") {
      if(isFieldValid(firstName.value) && isFieldValid(lastName.value) && isPhoneNumberValid(phoneNumber.value) && isBirthDateValid(birthDate.value)) {
        trackUserProperty('personal_info_completed', 'true')
        return this.setState(({ currentScreenIndex }) => ({
          currentScreenIndex: (currentScreenIndex + 1),
          continueBtnValid: false
        }), this.runValidation)
      } else {
        ([firstName,lastName, phoneNumber, birthDate ]).map((attribute) => {
          return this.setState(state => {
            let attr = attribute
            let valid = attribute.id === 'birthDate' ? isBirthDateValid(attribute.value) :
            attribute.id === 'phoneNumber' ? isPhoneNumberValid(attribute.value) : isFieldValid(attribute.value)
            attr.error = !valid
            attr.valid = valid
            return {
              continueBtnValid: false,
              [attribute.id]: attr
            }
          })
        })
      }
    }

    if(screenName === "AboutYouForm") {
      if(isCurrencyValid(annualIncome.value)) {
        trackUserProperty('financial_info_completed', 'true')
        return this.setState(({ currentScreenIndex }) => ({
          currentScreenIndex: (currentScreenIndex + 1),
          continueBtnValid: false
        }), this.runValidation)
      } else {
        ([annualIncome]).map((attribute) => {
          return this.setState(state => {
            let attr = attribute
            let valid = isCurrencyValid(attribute.value)
            attr.error = !valid
            attr.valid = valid
            return {
              continueBtnValid: false,
              [attribute.id]: attr
            }
          })
        })
      }
    }

    if(screenName === "LoanPurposeForm") {
      if(isCurrencyValid(loanAmount.value)) {
        trackUserProperty('loan_screen_completed', 'true')
        return this.setState(({ currentScreenIndex }) => ({
          currentScreenIndex: (currentScreenIndex + 1),
          continueBtnValid: false
        }), this.runValidation)
      } else {
        ([loanAmount]).map((attribute) => {
          return this.setState(state => {
            let attr = attribute
            let valid = isCurrencyValid(attribute.value)
            attr.error = !valid
            attr.valid = valid
            return {
              continueBtnValid: false,
              [attribute.id]: attr
            }
          })
        })
      }
    }
  }

  runValidation() {
    const { currentScreenIndex } = this.state
    if(SCREEN_MAP[currentScreenIndex - 1].screen === "LoanEstimateTool") {
      if(_.isNull(this._panResponder)) {
        this._scrollToVisibleItem()
      }
    }
    this.isValid()
  }

  isValid() {
    const { address1, address2, zipcode, city, stateAbv,
      continueBtn, educationLevel, employmentStatus,
      employmentPayFrequency, firstName, lastName, birthDate,
      email, phoneNumber, annualIncome, loanAmount, loanPurpose,
      creditRating, propertyStatus, socialSecurity, currentScreenIndex } = this.state

    const screenName = SCREEN_MAP[currentScreenIndex - 1].screen
    switch(screenName) {
      case "LoanEstimateTool":
        return this.setState({
          continueBtnValid: true// true by default for tool
        })
      case "EmploymentForm":
        return this.setState({
          continueBtnValid: educationLevel.valid && employmentStatus.valid && employmentPayFrequency.valid
        })
      case "AddressForm":
        return this.setState({
          continueBtnValid: address1.valid && zipcode.valid && city.valid && stateAbv.valid
        })
      case "BasicInfoForm":
        return this.setState({
          continueBtnValid: firstName.valid && lastName.valid && birthDate.valid && phoneNumber.valid //email.valid
        })
      case "AboutYouForm":
        return this.setState({
          continueBtnValid: annualIncome.valid && propertyStatus.valid
        })
      case "LoanPurposeForm":
        return this.setState({
          continueBtnValid: loanAmount.valid
        })
      case "ConsentForm":
        return this.setState({
          continueBtnValid: socialSecurity.valid
        })
      default:
        break
    }
  }

  _onBack() {
    trackClick({ source: `Loan ${SCREEN_MAP[this.state.currentScreenIndex - 1].screen} Back Click`})
    return this.setState(({ currentScreenIndex }) => ({
      currentScreenIndex: (currentScreenIndex - 1),
    }), this.runValidation)
  }

  _transitionToEmail() {
    trackClick({ source: 'Contact Us Click', value: 'https://financialstress.com/contact-us/'})
    WebBrowser.openBrowserAsync('https://financialstress.com/contact-us/')
  }

  _transitionToEAgreement() {
    trackClick({ source: 'ESignature Click', value: 'https://financialstress.com/terms/#e-signature'})
    WebBrowser.openBrowserAsync('https://financialstress.com/terms/#e-signature')
  }

  _transitionToTerms() {
    trackClick({ source: 'Terms Click', value: 'https://financialstress.com/terms/'})
    WebBrowser.openBrowserAsync('https://financialstress.com/terms/')
  }

  _transitionToPrivacyPolicy() {
    trackClick({ source: 'Privacy Policy Click', value: ' https://financialstress.com/privacy-policy/'})
    WebBrowser.openBrowserAsync(' https://financialstress.com/privacy-policy/')
  }

  _transitionToCreditAgreement() {
    trackClick({ source: 'Credit Agreement Click', value: 'https://financialstress.com/credit-authorization-agreement/'})
    WebBrowser.openBrowserAsync('https://financialstress.com/credit-authorization-agreement/')
  }

  render() {
    const { showAdDisclosure, showMatchDisclosure, showIncomeDisclosure, showDisclosure,
      currentScreenIndex, autoCompleteResults, rateResults, selectedOffer } = this.state

    if(showDisclosure) {
      return (
        <GameContainer>
        <Disclosures
          transitionBack={this._transitionBack}
        />
      </GameContainer>
      )
    }

    if(showMatchDisclosure) {
      return (
        <GameContainer>
          <AdMatchDisclosure
            offer={selectedOffer}
            transitionBack={this._transitionBack}
          />
        </GameContainer>
      )
    }


    if(showAdDisclosure) {
      return (
        <GameContainer>
        <AdvertiserDisclosure
          transitionBack={this._transitionBack}
        />
      </GameContainer>
      )
    }

    if(showIncomeDisclosure) {
      return (
        <GameContainer>
        <IncomeDisclosure
          transitionBack={this._transitionBack}
        />
      </GameContainer>
      )
    }

    return (
      <GameContainer>
        <BrandImage />
        <LoanEstimateTool
          transitionToMatchDisclosure={this._transitionToMatchDisclosure}
          transitionToDisclosure={this._transitionToDisclosure}
          loanEstimateHandler={this._loanEstimateHandler}
          panResponder={this._panResponder}
          visibleScreen={currentScreenIndex}
          completionHandler={this._completionHandler}
          onTextChange={this._onTextChange}
          onSelectorChange={this._onPickerChange}
          onPickerChange={this._onPickerChange}
          onSlidingComplete={this._onSlidingComplete}
          onViewableItemsChanged={_.debounce(this._onViewableItemsChanged, 500)}
          setOfferListRef={this._setOfferListRef}
          scrollToVisibleItemWithAnimation={this._scrollToVisibleItemWithAnimation}
          disablePanResponder={this.disablePanResponder}
          setPanHandler={this.setPanHandler}
          onSnapToItem={this._onSnapToItem}
          state={this.state}
        />

        <PreQualForm
          visibleScreen={currentScreenIndex}
          screenMap={SCREEN_MAP}
          completionHandler={this._completionHandler}
          onBack={this._onBack}
          transitionToAdDisclosure={this._transitionToAdDisclosure}
          transitionToDisclosure={this._transitionToDisclosure}
          transitionToIncomeDisclosure={this._transitionToIncomeDisclosure}
          onTextChange={this._onTextChange}
          onPickerChange={this._onPickerChange}
          onFocus={this._onFocusHandler}
          state={this.state}
          inputRefs={this.inputRefs}
          autoCompleteResults={autoCompleteResults}
          autoCompleteHandler={this._autoCompleteHandler}
        />

        <ConsentForm
          transitionToAdDisclosure={this._transitionToAdDisclosure}
          transitionToDisclosure={this._transitionToDisclosure}
          transitionToEmail={this._transitionToEmail}
          transitionToEAgreement={this._transitionToEAgreement}
          transitionToTerms={this._transitionToTerms}
          transitionToPrivacyPolicy={this._transitionToPrivacyPolicy}
          transitionToCreditAgreement={this._transitionToCreditAgreement}
          visibleScreen={currentScreenIndex}
          completionHandler={this._completionHandler}
          onBack={this._onBack}
          onTextChange={this._onTextChange}
          onPickerChange={this._onPickerChange}
          state={this.state}
          />
      </GameContainer>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoanContainer)
