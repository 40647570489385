import React, { Component } from 'react'
import { styles } from './SharedStyle.js'
import { Dimensions, View, Image, Text, ScrollView, TextInput, TouchableHighlight } from 'react-native'
import { Button } from 'native-base'
import Icon from 'react-native-vector-icons/FontAwesome';

class Disclosures extends Component {

  render() {
    const { transitionBack } = this.props

    return (
      <View style={styles.disclosureView}>
        <Button onPress={transitionBack} style={styles.closeBtn} full rounded>
          <Icon name="times" size={30} color="#000" />
        </Button>

        <Text style={styles.headerText}>Additional Information</Text>
        <ScrollView style={{ padding: 10 }}>
          <Text style={styles.consentText}>
          We do not charge any fee or cost to submit a loan request on www.financialstress.com. The actual loan rate depends on the loan amount and term requested, as well as your credit score, usage and history. If you are offered a loan, you will have the opportunity to review the final offer made by lender or lending partner, and to accept the loan agreement made directly between you and the lender or lending partner. The lenders and lending partners we work with may charge an origination fee for facilitating the loan to you. For example, on a $10,000 loan with a 5% origination fee, you will incur a $500 origination fee and receive $9,500 upon successful issuance of your loan. Rate, terms and fees will be based on your individual credit history and underwritten by the lender and not by Edukate, Inc. / FinancialStress (“Edukate, Inc.”, “Edukate”, “FinancialStress”, “we”, “us”, “our”).
          </Text>
          <Text style={styles.consentText}>
          <Text style={styles.consentBoldText}>Representative Example:</Text> If you borrow $5,000 on a 36 month repayment term and at a 10% APR, the monthly repayment will be $161.34. Total repayment will be $5,808.24. Total interest paid will be $808.24.
          </Text>
          <Text style={styles.consentText}>
          <Text style={styles.consentBoldText}>APR Disclosure:</Text> The Annual Percentage Rate is the rate at which your loan accrues interest. It is based upon the amount of your loan, the cost of the loan, term of the loan, repayment amounts and timing of payments and payoff. By law, the lender or lending partner must show you the APR before you enter into the loan. States have laws that may limit the APR that the lender or lending partner can charge you. Rates will vary based on your credit worthiness, loan size, amongst other variables, with the lowest rates available to customers with excellent credit. Minimum and maximum loan amounts and APRs may vary according to state law and lender or lending partner. We recommend that you read the lender's and/or lending partner's personal terms and conditions in full before proceeding for a personal loan.
          </Text>
          <Text style={styles.consentText}>
          We recommend that you read the lender's and/or lending partner's personal terms and conditions, as well as any disclosures, in full before proceeding for any loan.
          </Text>
        </ScrollView>
      </View>
    )
  };
}

export default Disclosures
