import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({

  featuredImage: {
    height: '300px',
  },

  featuredImageMobileLarge: {
    height: '225px',
  },

  featuredImageMobileSmall: {
    height: '175px',
  },

  landingHeaderText: {
    fontSize: '28px',
    marginTop: '5px',
    textAlign: 'left',
    color: '#101D42',
    fontFamily: 'Nunito-Black'
  },

  landingHeaderTextMobile: {
    fontSize: '24px',
    textAlign: 'left',
    color: '#101D42',
    fontFamily: 'Nunito-Black'
  },

  landingFeatureView: {
  },

  mask: {
    width: '100%',
    justifyContent: 'flex-end',
  },

  landingFeaturedImage: {
    height: '425px',
    width: '100%',
    position: 'absolute',
    backgroundSize: 'contain',
    backgroundRepeat: 'inherit'
  },

  landingFeaturedImageMobile: {
    height: '325px',
    width: '100%',
    position: 'absolute',
    backgroundSize: 'contain',
    backgroundRepeat: 'inherit'
  },

  landingFeaturedImageMobileSmall: {
    height: '250px',
    width: '100%',
    position: 'absolute',
    backgroundSize: 'contain',
    backgroundRepeat: 'inherit'
  },

  landingBrandingText: {
    fontSize: '0.8em',
    textAlign: 'left',
    color: '#101D42',
    fontFamily: 'Nunito-SemiBold'
  },

  landingDescriptionText: {
    fontSize: '16px',
    textAlign: 'left',
    color: '#101D42',
    marginTop: 10,
    fontFamily: 'Nunito-Regular'
  },

  landingDescriptionTextMobile: {
    fontSize: '16px',
    textAlign: 'left',
    color: '#101D42',
    marginTop: 10,
    fontFamily: 'Nunito-Regular'
  },

  landingBoldText: {
    fontSize: '16px',
    textAlign: 'left',
    color: '#101D42',
    fontFamily: 'Nunito-Black'
  },

  landingBoldTextMobile: {
    fontSize: '16px',
    textAlign: 'left',
    color: '#101D42',
    fontFamily: 'Nunito-Black'
  },

  landingButton: {
    backgroundColor: '#7BB47F',
    padding: 20,
    borderRadius: 8,
    marginTop: 40,
    marginLeft: 10,
    textAlign: 'center',
    width: 200,
    boxShadow: '-5px 6px 2px 0px rgba(26, 56, 52, 1.0)',
  },

  landingButtonMobile: {
    backgroundColor: '#7BB47F',
    padding: 20,
    borderRadius: 8,
    marginTop: 20,
    marginLeft: 10,
    textAlign: 'center',
    width: 175,
    boxShadow: '-5px 6px 2px 0px rgba(26, 56, 52, 1.0)',
  },

  landingButtonText: {
    color: '#fff',
    fontSize: '19px',
    fontFamily: 'Nunito-Bold'
  },

  landingButtonTextMobile: {
    color: '#fff',
    fontSize: '16px',
    fontFamily: 'Nunito-Bold'
  },

  col: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },

  narrative: {
    width: '60%',
    flexDirection: 'row',
    padding: 40
  },

  narrativeMobile: {
    width: '65%',
    flexDirection: 'row',
    padding: 20
  },

  marketing: {
    width: '40%',
    flexDirection: 'row',
    marginTop: '80px',
    marginLeft: 10
  },

  marketingMobile: {
    width: '35%',
    flexDirection: 'row',
    marginTop: '40px',
  },

  headerText: {
    fontSize: '26px',
    marginTop: '20px',
    textAlign: 'center',
    marginLeft: 100,
    marginRight: 100,
    color: '#101D42',
    fontFamily: 'Nunito-SemiBold'
  },

  headerTextMobileLarge: {
    fontSize: '24px',
    marginTop: '10px',
    textAlign: 'center',
    marginLeft: 100,
    marginRight: 100,
    color: '#101D42',
    fontFamily: 'Nunito-SemiBold'
  },

  headerTextMobileSmall: {
    fontSize: '23px',
    marginTop: '8px',
    textAlign: 'center',
    marginLeft: 100,
    marginRight: 100,
    color: '#101D42',
    fontFamily: 'Nunito-SemiBold'
  },

  subHeaderText: {
    marginTop: 5,
    fontSize: '0.6875em',
    textAlign: 'center',
    color: '#101D42',
    fontFamily: 'Nunito-Regular'
  },

  launchForm: {
    marginBottom: '10px',
    marginTop: '20px'
  },

  transitionButton: {
    height: '60px',
    marginBottom: '10px'
  },

  footerText: {
    fontSize: '11px',
    textAlign: 'left',
  },

  footerTextMobileLarge: {
    fontSize: '11px',
    textAlign: 'left',
  },

  footerTextMobileSmall: {
    fontSize: '9px',
    textAlign: 'left',
  },

  item: {
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: '#C3E0BE',
    borderRadius: '25px',
    marginBottom: '10px',
    marginLeft: '80px',
    marginRight: '80px',
    padding: '15px',
    color: 'rgb(67, 70, 96)',
    outlineColor: 'transparent',
    fontSize: 12,
    paddingHorizontal: 25,
    outline: "none",
    outlineWidth: 0,
  },

  itemValid: {
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: '#69B679',
    borderRadius: '25px',
    marginBottom: '10px',
    marginLeft: '80px',
    marginRight: '80px',
    padding: '15px',
    color: 'rgb(67, 70, 96)',
    outlineColor: 'transparent',
    fontSize: 12,
    paddingHorizontal: 25,
    outline: "none",
    outlineWidth: 0,
  },


  itemError: {
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: '#A63D40',
    borderRadius: '25px',
    marginBottom: '10px',
    marginLeft: '80px',
    marginRight: '80px',
    padding: '15px',
    color: 'rgb(67, 70, 96)',
    outlineColor: 'transparent',
    fontSize: 12,
    paddingHorizontal: 25,
    outline: "none",
    outlineWidth: 0,
  },

  continueBtn: {
    backgroundColor: '#C3E0BE',
    alignItems: 'center',
    marginLeft: '100px',
    marginRight: '100px',
    padding: 10,
    borderRadius: 30,
    marginTop: 10,
    textAlign: 'center',
  },

  continueBtnValid: {
    backgroundColor: '#69B679',
    alignItems: 'center',
    marginLeft: '100px',
    marginRight: '100px',
    padding: 10,
    borderRadius: 30,
    marginTop: 10,
    textAlign: 'center',
  },

  continueText: {
    color: '#fff',
    alignItems: 'center',
    fontFamily: 'Nunito-Regular'
  },

  hyperlink: {
    color: '#69B679',
    marginLeft: 2,
    textDecorationLine: 'underline'
  },

})
