const employmentStatusPlaceholder = {
  label: 'Choose One...',
  value: 'choose',
  color: '#C3E0BE',
}

const employmentPayFrequencyPlaceholder = {
  label: 'Choose One...',
  value: 'choose',
  color: '#C3E0BE',
}

const educationLevelPlaceholder = {
  label: 'Choose One...',
  value: 'choose',
  color: '#C3E0BE',
}

const employmentStatuses = [
  {
    label: 'Employed',
    value: 'employed',
  },
  {
    label: 'Self Employed',
    value: 'self_employed',
  },
  {
    label: 'Retired',
    value: 'retired',
  },
  {
    label: 'Military',
    value: 'military',
  },
  {
    label: 'Not Employed',
    value: 'purchase',
  },
  {
    label: 'Other',
    value: 'other',
  },
]

const educationLevels = [
  {
    label: 'High School Diploma',
    value: 'high_school',
  },
  {
    label: "Associate's Degree",
    value: 'associates',
  },
  {
    label: "Bachelor's Degree",
    value: 'bachelors',
  },
  {
    label: "Master's Degree",
    value: 'masters',
  },
  {
    label: 'Other Graduate Degree',
    value: 'other_graduate_degree',
  },
  {
    label: 'Other',
    value: 'other',
  },
]

const employmentPayFrequencies = [
  {
    label: 'Once every week',
    value: 'once_every_week',
  },
  {
    label: "Once every other week",
    value: 'once_every_other_week',
  },
  {
    label: "Twice per month",
    value: 'twice_per_month',
  },
  {
    label: "Once per month",
    value: 'once_per_month',
  },
  {
    label: 'Not Employed',
    value: 'not_employed',
  },
  {
    label: 'Other',
    value: 'other',
  },
]

export { employmentStatusPlaceholder, employmentStatuses, educationLevelPlaceholder,
  employmentPayFrequencyPlaceholder, educationLevels, employmentPayFrequencies, }
