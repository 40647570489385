import actions from '../constants'

export default function offers(state = [], action) {
  switch (action.type) {
    default:
      return state

  case actions.SEARCH_OFFER_REQUEST:
    return {
      ...state,

    }

  case actions.SEARCH_OFFER_FAILURE:
    return {
      ...state,
      error: action.error
    }

  case actions.SEARCH_OFFER_SUCCESS:
    return {
      ...state,
      offers: action.offers
    }
  }
};
