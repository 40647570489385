import React, { Component } from 'react'
import { styles } from './SharedStyle.js'
import { Dimensions, View, Image, Text, ScrollView, TextInput, TouchableHighlight } from 'react-native'
import { Button } from 'native-base'
import Icon from 'react-native-vector-icons/FontAwesome';

class GameDisclosure extends Component {

  render() {
    const { transitionBack } = this.props

    return (
      <View style={styles.disclosureView}>
        <Button onPress={transitionBack} style={styles.closeBtn} full rounded>
          <Icon name="times" size={30} color="#000" />
        </Button>

        <Text style={styles.headerText}>How to Play</Text>
        <Text style={styles.consentText}>Swipe the card to the right for "Yes". Swipe it left for "No".</Text>

        <ScrollView>
          <Text style={styles.headerText}>Game Disclosure</Text>
          <Text style={styles.consentText}>We do not charge any fee or cost to submit a loan request on www.financialstress.com. The actual loan rate depends on the loan amount and term requested, as well as your credit score, usage and history. If you are offered a loan, you will have the opportunity to review the final offer made by lender or lending partner, and to accept the loan agreement made directly between you and the lender or lending partner. The lenders and lending partners we work with may charge an origination fee for facilitating the loan to you. For example, on a $10,000 loan with a 5% origination fee, you will incur a $500 origination fee and receive $9,500 upon successful issuance of your loan. Rate, terms and fees will be based on your individual credit history and underwritten by the lender and not by Even Financial, Inc. / Fiona ("Even”, “Even Financial”, ”Financialstress”, “we”, “us”, “our”)</Text>
        </ScrollView>
      </View>
    )
  };
}

export default GameDisclosure
