import React, { Component } from 'react'
import { styles } from './SharedStyle.js'
import { Dimensions, View, Image } from 'react-native'
import { Button } from 'native-base'
import { ListItem, CheckBox, Text, Body } from 'native-base'
import BrowserSniff from '../../common/BrowserSniff'

const imageUri = require('./images/financial_health.jpg')

const SubscribeForm = (props) => {
  const { isMobileLargeDevice, isDesktopDevice, isMobileSmallDevice } = BrowserSniff()
  const { transitionToArticle } = props

  if(isMobileLargeDevice) {
    return (
      <View style={styles.subscribe}>
        <Text style={styles.headerTextMobileLarge}>Sorry!</Text>
        <View style={{textAlign: 'center', marginLeft: '50px', marginRight: '50px'}}>
          <Text style={styles.actionTextMobileLarge}>At the moment, we couldn’t match you with a personal loan.</Text>
          <Text style={styles.actionTextMobileLarge}>We encourage you to check again if your loan needs or financial situation changes.</Text>
          <Text style={styles.actionTextMobileLarge}>If you want to boost your financial health, we invite you to check out our blog for tips and tricks to improve your situation.</Text>
        </View>


        <Image
          source={{uri: imageUri }}
          style={styles.subscribeImageMobileLarge}
          />

        <Button onPress={transitionToArticle} style={styles.actionBtn} full rounded>
          <Text  uppercase={false} style={styles.actionBtnText}>View Article</Text>
        </Button>

      </View>
    )
  }

  if(isMobileSmallDevice) {
    return (
      <View style={styles.subscribe}>
        <Text style={styles.headerTextMobileSmall}>Sorry!</Text>
        <View style={{textAlign: 'center', marginLeft: '50px', marginRight: '50px'}}>
          <Text style={styles.actionTextMobileSmall}>At the moment, we couldn’t match you with a personal loan.</Text>
          <Text style={styles.actionTextMobileSmall}>We encourage you to check again if your loan needs or financial situation changes.</Text>
          <Text style={styles.actionTextMobileSmall}>If you want to boost your financial health, we invite you to check out our blog for tips and tricks to improve your situation.</Text>
        </View>

        <Image
          source={{uri: imageUri }}
          style={styles.subscribeImageMobileSmall}
          />

        <Button onPress={transitionToArticle} style={styles.actionBtn} full rounded>
          <Text uppercase={false} style={styles.actionBtnText}>View Article</Text>
        </Button>

      </View>
    )
  }

  return (
    <View style={styles.subscribe}>
      <Text style={styles.headerText}>Sorry!</Text>
      <View style={{textAlign: 'center', marginLeft: '25px', marginRight: '25px'}}>
        <Text style={styles.actionText}>At the moment, we couldn’t match you with a personal loan.</Text>
        <Text style={styles.actionText}>We encourage you to check again if your loan needs or financial situation changes.</Text>
        <Text style={styles.actionText}>If you want to boost your financial health, we invite you to check out our blog for tips and tricks to improve your situation.</Text>
      </View>

      <Image
        source={{uri: imageUri }}
        style={styles.subscribeImage}
        />

      <Button onPress={transitionToArticle} style={styles.actionBtn} full rounded>
        <Text uppercase={false} style={styles.actionBtnText}>View Article</Text>
      </Button>

    </View>
  )
}

export default SubscribeForm
