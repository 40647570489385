// const EDUKATE_BASE_URL = 'http://api.local.edukate-dev.com:5000'
// const EDUKATE_BASE_URL = 'http://api.develop.edukate-dev.com'
const EDUKATE_BASE_URL = 'https://api.edukate.com'
const EDUKATE_DEMO_BASE_URL = 'https://api.edukate-demo.com'
const GOOGLE_PLACE_URL = 'https://maps.googleapis.com/maps/api'
const GOOGLE_API_KEY = 'AIzaSyBrGI-KS54Obsf-FItQw71Yk3DocuRbV5g'

const constants = {
  REQUEST_PLACE_AUTOCOMPLETE_URL: `${GOOGLE_PLACE_URL}/place/autocomplete/json`,
  REQUEST_RATE_TABLE_URL: `${EDUKATE_BASE_URL}/public/v1/rate_tables`,
  REQUEST_EVENT_TRACK_URL: `${EDUKATE_BASE_URL}/public/v1/lead_events`,
  REQUEST_DEMO_RATE_TABLE_URL: `${EDUKATE_DEMO_BASE_URL}/public/v1/rate_tables`,
  GOOGLE_API_KEY: 'AIzaSyBrGI-KS54Obsf-FItQw71Yk3DocuRbV5g',
  POST_METHOD: 'post',
  GET_METHOD: 'get',
  DELETE_METHOD: 'delete',
  CONTENT_TYPE_JSON: 'application/json',
}

export default constants
