import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
  matches: {
    justifyContent: 'center',
    alignItems: 'center',
  },

  matchRow: {
    flex: 1,
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 35,
    marginRight: 35
  },

  matchChild: {
    marginTop: 10,
    flexBasis: '50%',
    justifyContent: 'center',
    alignItems: 'center'
  },

  matchChildPlaceholder: {
    marginTop: 10,
    flexBasis: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: .2
  },

  footerText: {
    fontSize: '11px',
    textAlign: 'left',
    marginLeft: 10,
    marginBottom: 10,
    fontFamily: 'Nunito-Regular',
    color: "rgba(67,70,96, .5)",
    textDecorationLine: 'underline',
  },

  consentText: {
    fontSize: '11px',
    textAlign: 'left',
    marginLeft: 10,
    marginBottom: 10,
    fontFamily: 'Nunito-Regular',
    color: "#a7a7a7",
    textDecorationLine: 'underline',
  },

  headerTextMobileLarge: {
    fontSize: '28px',
    textAlign: 'center',
    marginLeft: 100,
    marginRight: 100,
    color: '#101D42',
    fontFamily: 'Nunito-Black'
  },


  descriptionTextMobileLarge: {
    fontSize: '20px',
    textAlign: 'center',
    marginLeft: 65,
    marginRight: 65,
    color: '#101D42',
    fontFamily: 'Nunito-Regular'
  },

})
