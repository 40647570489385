import constants from '../constants.js'
import * as Analytics from 'expo-firebase-analytics';

export default class LeadDomain {
  track(data) {
    const proxyurl = "https://cors-anywhere.herokuapp.com/"
    const formData = new FormData()
    for (let key in data) {
      formData.append(key, data[key]);
    }
    return fetch(constants.REQUEST_EVENT_TRACK_URL, {
      method: constants.POST_METHOD,
      body: formData,
    }).then(response => response.json())
    .catch((error) => {
      throw error
    })
  }
}
