import React, { Component } from 'react'
import { styles } from './SharedStyle.js'
import { Dimensions, View, Image, Text, TouchableOpacity, Animated, TouchableWithoutFeedback } from 'react-native'
import { Button } from 'native-base'
import Icon from 'react-native-vector-icons/FontAwesome';
import BrowserSniff from '../common/BrowserSniff'

const yesImageUri = require('./images/yesAnimation.png')
const noImageUri = require('./images/noAnimation.png')

const OnPanAnimation = (props) => {
  const { isMobileLargeDevice, isDesktopDevice, isMobileSmallDevice } = BrowserSniff()
  const { direction, responseCount } = props

    if(isMobileLargeDevice) {
      return (
        <View style={[styles.panAnimationView]} >
          <TouchableWithoutFeedback >
            {(direction === 'right') ?
            (<Image source={{uri: yesImageUri}} style={{ marginTop: 100, width: "65%", opacity: 0.8, height: 250 }}/>)
             :
            (<Image source={{uri: noImageUri}} style={{ marginTop: 100, width: "65%", opacity: 0.8, height: 250 }}/>)
           }
         </TouchableWithoutFeedback>
       </View>
      )
    }

    if(isMobileSmallDevice) {
      return (
        <View style={[styles.panAnimationView]} >
          <TouchableWithoutFeedback >
            {(direction === 'right') ?
            (<Image source={{uri: yesImageUri}} style={{ marginTop: 75, width: "65%", opacity: 0.8, height: 225 }}/>)
             :
            (<Image source={{uri: noImageUri}} style={{ marginTop: 75, width: "65%", opacity: 0.8, height: 225 }}/>)
           }
         </TouchableWithoutFeedback>
        </View>
      )
    }

  return (
     <View style={[styles.panAnimationView]} >
        <TouchableWithoutFeedback>
         {(direction === 'right') ?
         (<Image source={{uri: yesImageUri}} style={{ marginTop: 100, width: "65%", opacity: 0.8, height: 250 }}/>)
          :
         (<Image source={{uri: noImageUri}} style={{ marginTop: 100, width: "65%", opacity: 0.8, height: 250 }}/>)
        }
      </TouchableWithoutFeedback>
    </View>
  )
}

export default OnPanAnimation
