const SCREEN_MAP =
  [{
      id: 1,
      screen: 'LoanEstimateTool',
    },
    {
      id: 2,
      screen: 'EmploymentForm',
    },
    {
      id: 3,
      screen: 'AddressForm',
    },
    {
      id: 4,
      screen: 'BasicInfoForm',
    },
    {
      id: 5,
      screen: 'AboutYouForm',
    },
    {
      id: 6,
      screen: 'LoanPurposeForm',
    },
    {
      id: 7,
      screen: 'ConsentForm',
  }]

  export default SCREEN_MAP
