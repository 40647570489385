import React, { Component } from 'react'
import { styles } from './SharedStyle.js'
import { Dimensions, View, Image, Text, ScrollView, TextInput, Picker } from 'react-native'
import Slider from '@react-native-community/slider'
import { Button, DatePicker } from 'native-base'
import BasicInfoForm from '../../common/forms/BasicInfoForm'
import AddressForm from '../../common/forms/AddressForm'
import EmploymentForm from '../../common/forms/EmploymentForm'
import AboutYouForm from '../../common/forms/AboutYouForm'
import LoanPurposeForm from '../../common/forms/LoanPurposeForm'
import Icon from 'react-native-vector-icons/FontAwesome';

const PreQualForm = (props) => {

  const { transitionToIncomeDisclosure, transitionToAdDisclosure, transitionToDisclosure, transitionToConsentForm, visibleScreen, completionHandler,
    onBack, onTextChange, onPickerChange, state, autoCompleteResults, autoCompleteHandler,
    onFocus, screenMap, inputRefs } = props
  const { continueBtnValid } = state
  if (![2,3,4,5,6].includes(visibleScreen)) { return null }

  return (
    <View style={styles.prequalForm}>

      <EmploymentForm
        onFocus={onFocus}
        onTextChange={onTextChange}
        onPickerChange={onPickerChange}
        transitionToAdDisclosure={transitionToAdDisclosure}
        state={state}
        screenMap={screenMap}
        inputRefs={inputRefs}
        visibleScreen={visibleScreen}/>
      <AddressForm
        onFocus={onFocus}
        autoCompleteHandler={autoCompleteHandler}
        autoCompleteResults={autoCompleteResults}
        onTextChange={onTextChange}
        screenMap={screenMap}
        onPickerChange={onPickerChange}
        transitionToAdDisclosure={transitionToAdDisclosure}
        state={state}
        visibleScreen={visibleScreen}/>
      <BasicInfoForm
        onFocus={onFocus}
        onTextChange={onTextChange}
        onPickerChange={onPickerChange}
        transitionToAdDisclosure={transitionToAdDisclosure}
        state={state}
        screenMap={screenMap}
        visibleScreen={visibleScreen}/>
      <AboutYouForm
        transitionToIncomeDisclosure={transitionToIncomeDisclosure}
        transitionToAdDisclosure={transitionToAdDisclosure}
        screenMap={screenMap}
        onTextChange={onTextChange}
        onPickerChange={onPickerChange}
        state={state}
        visibleScreen={visibleScreen}/>
      <LoanPurposeForm
        screenMap={screenMap}
        transitionToAdDisclosure={transitionToAdDisclosure}
        onTextChange={onTextChange}
        onPickerChange={onPickerChange}
        onFocus={onFocus}
        state={state}
        visibleScreen={visibleScreen}/>

      <View style={styles.colCenteredBtns}>
        <View style={[styles.loanBox, {}]}>
          <Button
            onPress={completionHandler}
            style={continueBtnValid ? [styles.actionModBtnValid, {marginTop: 20}] : [styles.actionModBtn, {marginTop: 20}]}
            disabled={!continueBtnValid}
            full rounded>
            <Text uppercase={false} style={[styles.actionBtnText]}>Continue</Text>
          </Button>
        </View>

        <View style={[styles.loanBox, {marginTop: 15}]}>
          <Text onPress={onBack} uppercase={false} style={styles.actionBtnModText}>Back</Text>
        </View>
      </View>
    </View>
  )
}

export default PreQualForm
