import constants from '../constants.js'
import { getParamsFromURL } from '../../helpers/url-helper'
import * as Linking from 'expo-linking'

const jsonHeaders = {
  Accept: constants.CONTENT_TYPE_JSON,
  'Content-Type': constants.CONTENT_TYPE_JSON
}

export default class LoanDomain {
  getRates(data = {}) {
    const proxyurl = "https://cors-anywhere.herokuapp.com/"
    const formData = new FormData()
    for (let key in data) {
      formData.append(key, data[key]);
    }

    return getParamsFromURL().then((host_url) => {
      let targetedUrl = null
      if (host_url) {
        let newURL = Linking.parse(host_url)
        const { target } = newURL.queryParams
        if(target === 'demo') {
          targetedUrl = constants.REQUEST_DEMO_RATE_TABLE_URL
        } else {
          targetedUrl = constants.REQUEST_RATE_TABLE_URL
        }
      }

      return fetch(targetedUrl, {
        method: 'POST',
        body: formData,
      }).then(response => response.json())
      .catch((error) => {
        throw error
      })
    })

  }
}
