import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor:'#fff',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    margin: 0,
    padding: 0
  },

  headerText: {
    fontSize: '30px',
    fontWeight: 'bold',
    marginTop: '5px',
    textAlign: 'center',
    color: '#101D42',
    fontFamily: 'Nunito-Bold'
  },

  itemValid: {
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: '#69B679',
    borderRadius: '25px',
    marginTop: '10px',
    padding: '10px',
    outlineColor: 'transparent',
    outline: "none",
    outlineWidth: 0,
  },

  headerTextMobileLarge: {
    fontSize: '24px',
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#101D42',
    fontFamily: 'Nunito-Bold'
  },

  headerTextMobileSmall: {
    fontSize: '24px',
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#101D42',
    fontFamily: 'Nunito-Bold'
  },

  content: {
    borderStyle: 'solid',
    borderColor: '#fff',
    boxShadow: '0 3px 6px 0',
    width: '475px',
    overflow: 'hidden'
  },

  contentMobile: {
    borderWidth: '4px',
    borderStyle: 'solid',
    borderColor: '#fff',
    boxShadow: '0 3px 6px 0',
    width: '450px',
    overflow: 'hidden'
  },

  cardStyle: {
    position: 'absolute',
    height: 500,
    width: '100%',
    zIndex: 100000
  },

  introText: {
    fontSize: '24px',
    marginTop: '20px',
    textAlign: 'center',
    color: '#101D42',
    fontFamily: 'Nunito-Bold'
  },

  questionText: {
    fontSize: '24px',
    padding: 10,
    marginLeft: 50,
    marginRight: 50,
    marginTop: '15px',
    textAlign: 'center',
    color: '#141E3F',
    fontFamily: 'Nunito-Bold',
    userSelect: 'none'
  },

  subQuestionText: {
    fontSize: '14px',
    marginLeft: 30,
    marginRight: 30,
    fontWeight: 'bold',
    marginTop: '5px',
    textAlign: 'center',
    color: '#434660',
    fontFamily: 'Nunito-Regular'
  },

  descriptionText: {
    fontSize: '14px',
    marginLeft: 30,
    marginRight: 30,
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#434660',
    fontFamily: 'Nunito-Regular'
  },

  bold900Text: {
    fontWeight: 'bold',
    fontFamily: 'Nunito-Bold'
  },

  yesText: {
    color: '#69B679',
    fontFamily: 'Nunito-Bold'
  },

  noText: {
    color: '#f76465',
    fontFamily: 'Nunito-Bold'
  },

  card: {
    flex: 1,
    width: '65%',
    backgroundColor: 'transparent',
    textAlign: 'center',
    justifyContent: 'center',
  },

  featuredImage: {
    borderRadius: '16px',
    height: 375,
    boxShadow: '12px 14px 84px 20px rgba(0,0,0,0.18)',
    zIndex: 100000,
  },

  mobileFeaturedImage: {
    borderRadius: '16px',
    height: 300,
    boxShadow: '12px 14px 84px 20px rgba(0,0,0,0.18)',
    zIndex: 100000,
  },

  introFeaturedImage: {
    borderRadius: '12px',
    width: "90%",
    height: 300,
    boxShadow: '0 3px 6px 0',
  },

  introMobileFeaturedImage: {
    borderRadius: '12px',
    width: "90%",
    height: 225,
    boxShadow: '0 3px 6px 0',
  },

  errorText: {
    color: '#A63D40',
    fontSize: '12px',
    textAlign: 'center',
    marginBottom: 5
  },

  footerView: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    bottom: 20,
    zIndex: 10000
  },

  errors: {
    flex: 1,
    marginTop: 10
  },

  panAnimationView: {
    flex: 1,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },

  fieldHeader: {
     padding: '5px',
     fontSize: '16px',
     fontWeight: 'bold',
     textAlign: 'center'
   },

   closeBtn: {
     textAlign: 'center',
     backgroundColor: '#fff'
   },

   item: {
     borderWidth: '2px',
     borderStyle: 'solid',
     borderColor: '#C3E0BE',
     borderRadius: '25px',
     marginBottom: '10px',
     marginLeft: '30px',
     marginRight: '30px',
     padding: '15px',
     outlineColor: 'transparent',
     outline: "none",
     outlineWidth: 0,
   },

})
