import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
  scrollView: {
  },

  headerText: {
    fontSize: '30px',
    fontWeight: 'bold',
    marginTop: '5px',
    textAlign: 'center',
    color: '#101D42',
    fontFamily: 'Nunito-Bold'
  },

  headerTextMobileLarge: {
    fontSize: '24px',
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#101D42',
    fontFamily: 'Nunito-Bold'
  },

  headerTextMobileSmall: {
    fontSize: '24px',
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#101D42',
    fontFamily: 'Nunito-Bold'
  },

  bold900Text: {
    color: '#69B679',
    fontSize: '12px',
    fontWeight: 'bold',
    fontFamily: 'Nunito-Black'
  },

  stepText: {
    fontSize: '16px',
    marginTop: '10px',
    textAlign: 'center',
    color: '#101D42',
    fontFamily: 'Nunito-Regular'
  },

  stepText: {
    fontSize: '16px',
    marginTop: '10px',
    textAlign: 'center',
    color: '#101D42',
    fontFamily: 'Nunito-Regular'
  },

  stepDisclosureText: {
    fontSize: '12px',
    marginTop: '10px',
    textAlign: 'center',
    textDecorationLine: 'underline',
    color: '#101D42',
    fontFamily: 'Nunito-Regular'
  },

  securityContainer: {
    flexDirection: 'row',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: '#C3E0BE',
    borderRadius: '25px',
    marginTop: '10px',
    padding: '10px',
    width: '80%',
    outlineColor: 'transparent',
  },

  securityContainerValid: {
    flexDirection: 'row',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: '#69B679',
    borderRadius: '25px',
    marginTop: '10px',
    padding: '10px',
    width: '80%',
    outlineColor: 'transparent',
  },

  securityContainerError: {
    flexDirection: 'row',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: '#A63D40',
    borderRadius: '25px',
    marginTop: '10px',
    padding: '10px',
    width: '80%',
    outlineColor: 'transparent',
  },

  inputStyle: {
    flex: 1,
  },

  consentHeaderText: {
    fontSize: '26px',
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#101D42',
    marginTop: 5,
    fontFamily: 'Nunito-Bold'
  },

  actionText: {
    textAlign: 'center',
    fontSize: '14px',
    height: '50px',
    marginTop: 20,
    padding: 20,
    color: '#101D42',
    fontFamily: 'Nunito-Regular'
  },

  actionTextMobileLarge: {
    textAlign: 'center',
    fontSize: '14px',
    height: '30px',
    marginTop: 10,
    color: '#101D42',
    fontFamily: 'Nunito-Regular'
  },


  actionTextMobileSmall: {
    textAlign: 'center',
    fontSize: '14px',
    height: '30px',
    marginTop: 10,
    color: '#101D42',
    fontFamily: 'Nunito-Regular'
  },

  actionModBtn: {
    backgroundColor: '#C3E0BE',
    borderColor: '#C3E0BE',
    height: 50,
    width: 200
  },

  actionModBtnValid: {
    backgroundColor: '#69B679',
    borderColor: '#69B679',
    height: 50,
    width: 200
  },

  actionModBtnError: {
    backgroundColor: '#A63D40',
    borderColor: '#A63D40',
    height: 50,
    width: 150
  },

  btnOptions: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
  },

  consentActionText: {
    textAlign: 'center',
    fontSize: '13px',
    fontWeight: 'bold',
    marginLeft: 30,
    marginRight: 30,
    marginBottom: 20,
    height: '5px',
    color: '#101D42',
    fontFamily: 'Nunito-SemiBold'
  },

  headerMobileText: {
    fontSize: '22px',
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#101D42',
    fontFamily: 'Nunito-Bold'
  },

  colCenteredBtns: {
    height: 150,
    justifyContent: 'center',
    alignItems: 'center',
  },

  actionMobileText: {
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: 'bold',
    height: '30px',
    marginLeft: 15,
    marginRight: 15,
    color: '#101D42',
    fontFamily: 'Nunito-SemiBold'
  },

  item: {
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: '#C3E0BE',
    borderRadius: '25px',
    marginTop: '10px',
    padding: '10px',
    outlineColor: 'transparent',
    outline: "none",
    outlineWidth: 0,
  },

  itemValid: {
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: '#69B679',
    borderRadius: '25px',
    marginTop: '10px',
    padding: '10px',
    outlineColor: 'transparent',
    outline: "none",
    outlineWidth: 0,
  },
  itemError: {
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: '#A63D40',
    borderRadius: '25px',
    marginTop: '10px',
    padding: '10px',
    outlineColor: 'transparent',
    outline: "none",
    outlineWidth: 0,
  },
  securityItem: {
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: '#C3E0BE',
    borderRadius: '25px',
    marginLeft: '15px',
    marginRight: '30px',
    padding: '15px',
    marginBottom: '0px',
    width: 250
  },

  selector: {
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: '#C3E0BE',
    borderRadius: '22px',
    marginBottom: '10px',
    marginLeft: '30px',
    marginRight: '30px',
    padding: '15px',
    outlineColor: 'transparent',
    backgroundColor: 'transparent'
  },

  actionBtn: {
    backgroundColor: '#C3E0BE',
    borderColor: '#C3E0BE',
    borderStyle: 'solid',
    borderWidth: '1px',
    alignItems: 'center',
    marginBottom: '5px',
    marginLeft: '60px',
    marginRight: '60px',
    textAlign: 'center'
  },

  actionBtnText: {
    color: '#fff',
    alignItems: 'center'
  },

 fieldHeader: {
    padding: '5px',
    fontSize: '16px',
    fontWeight: 'bold',
    textAlign: 'center'
  },

  fieldAlert: {
    fontSize: '10px',
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#8b0000',
    padding: 5,
  },

  prequalForm: {
    flex: 1
  },

  consent: {
    height: 600
  },

  marginTop25: {
    marginTop: '25px'
  },

  marginTop10: {
    marginTop: '10px'
  },

  disclosureView: {
    height: 750
  },

  fieldView: {
    marginTop: 10,
    marginBottom: 10
  },

  consentText: {
    marginTop: '5px',
    fontSize: '13px',
    textAlign: 'left',
    marginLeft: 30,
    marginRight: 30,
    marginTop: 20,
    marginBottom: 10,
    color: '#101D42',
    fontFamily: 'Nunito-Regular'
  },

  consentBoldText: {
    fontSize: '14px',
    textAlign: 'left',
    color: '#101D42',
    fontFamily: 'Nunito-Black'
  },


  consentIdentityText: {
    fontSize: '12px',
    textAlign: 'center',
    padding: 5,
    marginTop: 10,
    color: '#101D42',
    fontFamily: 'Nunito-Regular'
  },

  consentServiceText: {
    fontSize: '10px',
    textAlign: 'center',
    padding: 5,
    color: '#101D42',
    fontFamily: 'Nunito-Regular'
  },

  hyperlink: {
    color: '#69B679',
    textDecorationLine: 'underline'
  },

  disclosureText: {
    fontSize: '12px',
    padding: '10px'
  },

  closeBtn: {
    textAlign: 'center',
    backgroundColor: '#fff'
  },

  mutedActionText: {
    textAlign: 'center',
    fontSize: '13px',
    height: '50px',
    color: '#101D42',
  },

  consentImage: {
    borderRadius: '15px',
    marginLeft: '80px',
    marginRight: '80px',
    height: 140
  },

  consentMobileImage: {
    borderRadius: '15px',
    marginLeft: '100px',
    marginRight: '100px',
    height: 90
  },

  monthlyRateText: {
    fontSize: '50px',
    fontWeight: 'bold',
    marginTop: '10px',
    textAlign: 'center',
    color: '#101D42',
    fontFamily: 'Nunito-Bold'
  },

  monthlyDescriptionText: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginTop: '10px',
    textAlign: 'center',
    color: '#101D42',
    fontFamily: 'Nunito-SemiBold'
  },

  rateInfoText: {
    textAlign: 'center',
    fontSize: '11px',
    marginLeft: '75px',
    marginRight: '75px',
    marginTop: '10px',
    marginBottom: '20px',
    color: '#101D42',
    fontFamily: 'Roboto'
  },

  actionText: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginTop: '5px',
    textAlign: 'center',
    marginLeft: '40px',
    marginRight: '40px',
    color: '#101D42',
    fontFamily: 'Nunito-SemiBold'
  },

  labelText: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginTop: '5px',
    marginLeft: '40px',
    marginRight: '40px',
    color: '#101D42',
    fontFamily: 'Nunito-SemiBold'
  },

  consentView: {
    marginLeft: 60,
    marginRight: 60,
    flexDirection: 'row',
  },

  agreementView: {
    marginLeft: 60,
    marginRight: 60,
    marginTop: 10,
    flexDirection: 'row',
  },

  actionRangeText: {
    fontSize: '18px',
    fontWeight: 'bold',
    marginTop: '20px',
    color: '#101D42',
    backgroundColor: '#EBEBEB',
    padding: '15px',
    width: '150px',
    textAlign: 'center',
    fontFamily: 'Nunito-Bold'
  },

  content: {
  },

  actionInfo: {
    marginTop: '5px',
    flexDirection: 'row',
    marginLeft: '10px',
    marginRight: '10px',
    justifyContent: 'space-around'
  },

  sliderMobile: {
    padding: '25px',
    width: 400,
    height: 5,
    textAlign: 'center',

  },

  slider: {
    padding: '30px',
    width: 450,
    height: 50,
    textAlign: 'center',
  },

  thumb: {
    width: 40,
    height: 40,
    backgroundColor: '#fff',
    borderColor: '#101D42',
    borderStyle: 'solid',
    borderWidth: 4,
    borderRadius: 50,
  },

  loanBox: {
    marginTop: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },

  actionBtnModText: {
    color: '#101D42',
    alignItems: 'center',
    textDecorationLine: 'underline'
  },

  picker: {
    width: '325px',
    borderColor: '#101D42',
    padding: '20px',
    height: 30,
    flex: 1,
    marginTop: '10px',
    marginBottom: '10px'
  },

  pickerItem: {
    fontSize: '12px',
    color: '#101D42',
    backgroundColor: 'white'
  },

  social: {
    flexDirection: 'row',
    justifyContent: 'center'
  }
})
