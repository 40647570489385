import React, { Component, useRef } from 'react'
import Slider from '@react-native-community/slider'
import { View, Text, Picker, StyleSheet, Image, Animated, TouchableWithoutFeedback, FlatList, TouchableOpacity, Dimensions, ScrollView, Platform } from 'react-native'
import { getCreditRatingFromSlider } from '../../../helpers/credit-score'
import { styles } from './SharedStyle'
import { Button } from 'native-base'
import BrowserSniff from '../../common/BrowserSniff'
import { Ionicons } from '@expo/vector-icons'
import RNPickerSelect, { defaultStyles } from 'react-native-picker-select'
import Currency from '../../../../lib/currency.min.js'
import Loader from 'react-loader-spinner'
import validate from 'valib'
import OfferCard from './OfferCard'
import PreloadingCard from './PreloadingCard'
const v = require('valib')
import * as Animatable from 'react-native-animatable'
import { scrollInterpolator, animatedStyles } from './animations'
import Carousel from 'react-native-snap-carousel'

// import {
//   PanGestureHandler,
//   State,
//   FlatList,
// } from 'react-native-gesture-handler';
const CARD_WIDTH = Dimensions.get('window').width * 0.9
const CARD_HEIGHT = Dimensions.get('window').height * 0.7
const SPACING_FOR_CARD_INSET = Dimensions.get('window').width * 0.1 - 10
const ID = 1

const placeholder = {
  label: 'Debt Consolidation',
  value: 'debt_consolidation',
  color: '#C3E0BE',
}

const loanPurposes = [
  {
    label: 'Credit Card Refinance',
    value: 'credit_card_refi',
  },
  {
    label: 'Home Improvement',
    value: 'home_improvement',
  },
  {
    label: 'Large Purchase',
    value: 'large_purchases',
  },
  {
    label: 'Student Loan Refinance',
    value: 'student_loan_refi',
  },
  {
    label: 'Other',
    value: 'other',
  }
]

const LoanEstimateTool = (props) =>  {
    const { isMobileLargeDevice, isDesktopDevice, isMobileSmallDevice } = BrowserSniff()
    const { onSnapToItem, disablePanResponder, setPanHandler, onViewableItemsChanged, transitionToDisclosure, loanEstimateHandler, visibleScreen,
      completionHandler, onBack, onPickerChange, onTextChange, state, onSelectorChange,
      panResponder, transitionToMatchDisclosure, onSlidingComplete, setOfferListRef, scrollToVisibleItemWithAnimation } = props
    const { loanAmount, creditScore, loanPurpose, continueBtnValid, rateResults,
      rateBlockInProgress, sortedLoanOffers } = state
    const loanAmountText = Currency(loanAmount.toolValue, { decimal: ',', precision: 0 }).format()
    const hasLoanOffers = !v.Array.isEmpty(sortedLoanOffers)
    const onViewRef = useRef((viewableItems)=> {
      onViewableItemsChanged(viewableItems)
    })
    const viewConfigRef = useRef({ minimumViewTime: 400, itemVisiblePercentThreshold: 95  })

    if (visibleScreen !== ID) { return null }

    if(isMobileLargeDevice) {
      return (
        <View style={styles.content}>
          <View>
           <FlatList
             contentContainerStyle={{height: 235, width: '100%', justifyContent: 'left', alignItems: 'center'}}
             pagingEnabled={true}
             snapToAlignment={"left"}
             snapToInterval={325 + 20}
             decelerationRate={0}
             showsHorizontalScrollIndicator={false}
             horizontal
             refreshing={!rateBlockInProgress}
             initialScrollIndex={0}
             data={sortedLoanOffers}
             extraData={!rateBlockInProgress}
             ref={setOfferListRef}
             ListEmptyComponent={<PreloadingCard/>}
             getItemLayout={(data, index) => (
              { length: 250, offset: 250 * index, index}
             )}
             simultaneousHandlers={panResponder}
             onViewableItemsChanged={onViewRef.current}
             viewabilityConfig={viewConfigRef.current}
             keyExtractor={offer => offer.uuid}
             onScrollToIndexFailed={()=>{}}
             renderItem={({ item }) => (
              <OfferCard
                panResponder={panResponder}
                disablePanResponder={disablePanResponder}
                setPanHandler={setPanHandler}
                transitionToMatchDisclosure={transitionToMatchDisclosure}
                offer={item} />
             )}
           />
          </View>

          <View style={styles.loanBox}>
            <Text style={styles.boldActionText}>Reason for Loan</Text>
            <View style={{ marginLeft: 125, marginRight: 125 }}>
              <RNPickerSelect
                    placeholder={placeholder}
                    items={loanPurposes}
                    selectedValue={loanPurpose.value}
                    onValueChange={(value) => onPickerChange('loanPurpose', value)}
                    style={loanPurpose.valid ?
                      {
                        inputAndroid: {
                          fontSize: 14,
                          borderWidth: '2px',
                          borderStyle: 'solid',
                          borderColor: '#69B679',
                          borderRadius: 22,
                          color: '#101D42',
                          padding: 10,
                          paddingRight: 30,
                        },
                        iconContainer: {
                          top: 10,
                          right: 12,
                        },
                      } :
                      {
                        inputAndroid: {
                          fontSize: 14,
                          borderWidth: '2px',
                          borderStyle: 'solid',
                          borderColor: '#C3E0BE',
                          borderRadius: 22,
                          color: '#101D42',
                          padding: 10,
                          paddingRight: 30,
                        },
                        iconContainer: {
                          top: 10,
                          right: 12,
                        },
                      }}
                    value={loanPurpose.value}
                    useNativeAndroidPickerStyle={false}
                    textInputProps={{ underlineColor: '#101D42' }}
                    Icon={() => {
                      return <Ionicons name="md-arrow-down" size={24} color="grey" />;
                    }}
                  />
            </View>
          </View>

          <View style={styles.toolActionInfoMobileLarge}>
            <Text style={[styles.actionText, {marginTop: '35px'}]}>Loan Amount</Text>
            <View style={styles.actionRangeView}>
              <Text style={styles.actionRangeTextMobile}>{loanAmountText}</Text>
            </View>
          </View>
          <View style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
            <Slider
              style={styles.sliderMobileLarge}
              thumbStyle={styles.thumb}
              minimumValue={1000}
              maximumValue={40000}
              step={500}
              value={loanAmount.toolValue}
              onSlidingComplete={onSlidingComplete}
              onValueChange={(value) => onPickerChange('loanAmount', value)}
              minimumTrackTintColor="#101D42"
              maximumTrackTintColor="#101D42"
            />
          </View>
          <View style={styles.toolActionInfoMobileLarge}>
            <Text style={[styles.actionText, {marginTop: '35px'}]}>Credit Rating</Text>
            <View style={styles.actionRangeView}>
              <Text style={styles.actionRangeCreditTextMobile}>{getCreditRatingFromSlider(creditScore.value)}</Text>
            </View>
          </View>
          <View style={{width: '100%', justifyContent: 'center', alignItems: 'center' }}>

            <Slider
              style={styles.sliderMobileLarge}
              minimumValue={1}
              step={1}
              value={creditScore.value}
              thumbStyle={styles.thumb}
              maximumValue={4}
              onSlidingComplete={onSlidingComplete}
              onValueChange={(value) => onPickerChange('creditScore', value)}
              minimumTrackTintColor="#101D42"
              maximumTrackTintColor="#101D42"
            />
          </View>

          <Button onPress={completionHandler} style={continueBtnValid ?
            [styles.actionBtnValid, { marginTop: '30px', marginLeft: 100, marginRight: 100 }] :
            [styles.actionBtn, { marginTop: '30px', marginLeft: 100, marginRight: 100 }]}
            full rounded>
            <Text uppercase={false} style={styles.actionBtnText}>Continue</Text>
          </Button>

          <View style={styles.btnBox}>
            <Text onPress={transitionToDisclosure} style={styles.disclosureText}>APR Disclosures & Information</Text>
          </View>
        </View>

      )
    }

    if(isMobileSmallDevice) {
      return (
        <View style={styles.content}>
          <View>
           <FlatList
             contentContainerStyle={{height: 235, width: '100%', justifyContent: 'left', alignItems: 'center'}}
             pagingEnabled={true}
             snapToAlignment={"left"}
             snapToInterval={325 + 20}
             decelerationRate={0}
             showsHorizontalScrollIndicator={false}
             horizontal
             refreshing={!rateBlockInProgress}
             initialScrollIndex={0}
             data={sortedLoanOffers}
             extraData={!rateBlockInProgress}
             ref={setOfferListRef}
             ListEmptyComponent={<PreloadingCard/>}
             getItemLayout={(data, index) => (
               { length: 235, offset: 235 * index, index}
              )}
             onViewableItemsChanged={onViewRef.current}
             viewabilityConfig={viewConfigRef.current}
             keyExtractor={offer => offer.uuid}
             onScrollToIndexFailed={()=>{}}
             renderItem={({ item }) => (
              <OfferCard
                panResponder={panResponder}
                disablePanResponder={disablePanResponder}
                setPanHandler={setPanHandler}
                transitionToMatchDisclosure={transitionToMatchDisclosure}
                offer={item} />
             )}
           />
          </View>
          <View style={styles.loanBox}>
            <Text style={styles.boldActionText}>Reason for Loan</Text>
            <View style={{ marginLeft: 125, marginRight: 125 }}>
              <RNPickerSelect
                    placeholder={placeholder}
                    items={loanPurposes}
                    selectedValue={loanPurpose.toolValue}
                    onValueChange={(value) => onPickerChange('loanPurpose', value)}
                    style={loanPurpose.valid ?
                      {
                        inputAndroid: {
                          fontSize: 14,
                          borderWidth: '2px',
                          borderStyle: 'solid',
                          borderColor: '#69B679',
                          borderRadius: 22,
                          color: '#101D42',
                          padding: 10,
                          paddingRight: 30,
                        },
                        iconContainer: {
                          top: 10,
                          right: 12,
                        },
                      }  :
                      {
                        inputAndroid: {
                          fontSize: 14,
                          borderWidth: '2px',
                          borderStyle: 'solid',
                          borderColor: '#C3E0BE',
                          borderRadius: 22,
                          color: '#101D42',
                          padding: 10,
                          paddingRight: 30,
                        },
                        iconContainer: {
                          top: 10,
                          right: 12,
                        },
                      }}
                    value={loanPurpose.value}
                    useNativeAndroidPickerStyle={false}
                    textInputProps={{ underlineColor: '#101D42' }}
                    Icon={() => {
                      return <Ionicons name="md-arrow-down" size={24} style={{ marginRight: 5 }} color="grey" />;
                    }}
                  />
            </View>
          </View>

          <View style={styles.toolActionInfoMobileSmall}>
            <Text style={[styles.actionText, {marginTop: '35px'}]}>Loan Amount</Text>
            <View style={styles.actionRangeView}>
              <Text style={styles.actionRangeTextMobile}>{loanAmountText}</Text>
            </View>
          </View>
          <View style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
            <Slider
              style={styles.sliderMobileSmall}
              thumbStyle={styles.thumb}
              minimumValue={1000}
              maximumValue={40000}
              step={500}
              value={loanAmount.toolValue}
              onSlidingComplete={onSlidingComplete}
              onValueChange={(value) => onPickerChange('loanAmount', value)}
              minimumTrackTintColor="#101D42"
              maximumTrackTintColor="#101D42"
            />
          </View>

          <View style={styles.toolActionInfoMobileSmall}>
            <Text style={[styles.actionText, {marginTop: '35px'}]}>Credit Rating</Text>
            <View style={styles.actionRangeView}>
              <Text style={styles.actionRangeCreditTextMobile}>{getCreditRatingFromSlider(creditScore.value)}</Text>
            </View>
          </View>
          <View style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
            <Slider
              style={styles.sliderMobileSmall}
              minimumValue={1}
              step={1}
              value={creditScore.value}
              thumbStyle={styles.thumb}
              maximumValue={4}
              onSlidingComplete={onSlidingComplete}
              onValueChange={(value) => onPickerChange('creditScore', value)}
              minimumTrackTintColor="#101D42"
              maximumTrackTintColor="#101D42"
            />
          </View>

          <Button onPress={completionHandler}
           full rounded
           style={continueBtnValid ?
            [styles.actionBtnValid, { marginTop: '30px', marginLeft: 100, marginRight: 100 }] :
            [styles.actionBtn, { marginTop: '30px', marginLeft: 100, marginRight: 100 }]}>
            <Text uppercase={false} style={styles.actionBtnText}>Continue</Text>
          </Button>

          <View style={styles.btnBox}>
            <Text onPress={transitionToDisclosure} style={styles.disclosureText}>APR Disclosures & Information</Text>
          </View>
        </View>
      )
    }

    return (
      <View style={styles.content}>
        <View>
        { rateBlockInProgress || v.Array.isEmpty(sortedLoanOffers) ? <PreloadingCard/> :
          <Carousel
            layout={"default"}
            ref={setOfferListRef}
            data={sortedLoanOffers}
            renderItem={({ item }) => (
             <OfferCard
             panResponder={panResponder}
             disablePanResponder={disablePanResponder}
             setPanHandler={setPanHandler}
             transitionToMatchDisclosure={transitionToMatchDisclosure}
             offer={item} />
            )}
            sliderWidth={'450'}
            itemWidth={375}
            containerCustomStyle={{ height: 250 }}
            inactiveSlideShift={0}
            firstItem={0}
            scrollInterpolator={scrollInterpolator}
            slideInterpolatedStyle={animatedStyles}
            enableSnap={true}
            lockScrollWhileSnapping={true}
          />
        }
        </View>
        <View style={styles.loanBox}>
          <Text style={styles.boldActionText}>Reason for Loan</Text>
          <View style={{ marginLeft: 125, marginRight: 125 }}>
            <RNPickerSelect
                  placeholder={placeholder}
                  items={loanPurposes}
                  selectedValue={loanPurpose.value}
                  onValueChange={(value) => onSelectorChange('loanPurpose', value)}
                  style={loanPurpose.valid ?
                    {
                      inputAndroid: {
                        fontSize: 14,
                        borderWidth: '2px',
                        borderStyle: 'solid',
                        borderColor: '#69B679',
                        borderRadius: 22,
                        color: '#101D42',
                        padding: 10,
                        paddingRight: 30,
                      },
                      iconContainer: {
                        top: 10,
                        right: 12,
                      },
                    }  :
                    {
                      inputAndroid: {
                        fontSize: 14,
                        borderWidth: '2px',
                        borderStyle: 'solid',
                        borderColor: '#C3E0BE',
                        borderRadius: 22,
                        color: '#101D42',
                        padding: 10,
                        paddingRight: 30,
                      },
                      iconContainer: {
                        top: 10,
                        right: 12,
                      },
                    }}
                  value={loanPurpose.value}
                  useNativeAndroidPickerStyle={false}
                  textInputProps={{ underlineColor: '#101D42' }}
                  Icon={() => {
                    return <Ionicons name="md-arrow-down" size={20} color="grey" />;
                  }}
                />
          </View>
        </View>
        <View style={styles.toolActionInfo}>
          <Text style={[styles.actionText, {marginTop: '35px'}]}>Loan Amount</Text>
          <View style={styles.actionRangeView}>
            <Text style={styles.actionRangeText}>{loanAmountText}</Text>
          </View>
        </View>
        <View style={{  width: '100%',  justifyContent: 'center', alignItems: 'center' }}>
          <Slider
            style={styles.slider}
            thumbStyle={styles.thumb}
            minimumValue={1000}
            maximumValue={40000}
            step={500}
            value={loanAmount.toolValue}
            onSlidingComplete={onSlidingComplete}
            onValueChange={(value) => onPickerChange('loanAmount', value)}
            minimumTrackTintColor="#101D42"
            maximumTrackTintColor="#101D42"
          />
        </View>
        <View style={styles.toolActionInfo}>
          <Text style={[styles.actionText, {marginTop: '35px'}]}>Credit Rating</Text>
          <View style={styles.actionRangeCreditView}>
            <Text style={styles.actionRangeCreditText}>{getCreditRatingFromSlider(creditScore.value)}</Text>
          </View>
        </View>
        <View style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
          <Slider
            style={styles.slider}
            minimumValue={1}
            step={1}
            value={creditScore.value}
            thumbStyle={styles.thumb}
            maximumValue={4}
            onSlidingComplete={onSlidingComplete}
            onValueChange={(value) => onPickerChange('creditScore', value)}
            minimumTrackTintColor="#101D42"
            maximumTrackTintColor="#101D42"
          />
        </View>

        <Button
          onPress={completionHandler}
          style={continueBtnValid ?
          [styles.actionBtnValid, { marginTop: '25px', marginLeft: 100, marginRight: 100 }] :
          [styles.actionBtn, { marginTop: '25px', marginLeft: 100, marginRight: 100 }]}
          disabled={!continueBtnValid}
          full rounded>
          <Text uppercase={false} style={styles.actionBtnText}>Continue</Text>
        </Button>

        <View style={styles.loanBox}>
          <Text onPress={transitionToDisclosure} style={styles.disclosureText}>APR Disclosures & Information</Text>
        </View>
      </View>

    )
}

const pickerSelectStyles = StyleSheet.create({
  inputIOS: {
    fontSize: 14,
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: '#C3E0BE',
    borderRadius: 22,
    color: '101D42',
    padding: 10,
    paddingRight: 30,
  },
  inputAndroid: {
    fontSize: 14,
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: '#C3E0BE',
    borderRadius: 22,
    color: '#101D42',
    padding: 10,
    paddingRight: 30,
  },
});
export default LoanEstimateTool
