import React, { Component } from 'react'
import { styles } from './SharedStyle.js'
import { Dimensions, View, Image, Text, ScrollView, TextInput, TouchableHighlight } from 'react-native'
import { Button } from 'native-base'
import Icon from 'react-native-vector-icons/FontAwesome';

class AdvertiserDisclosure extends Component {

  render() {
    const { transitionBack } = this.props

    return (
      <View style={styles.disclosureView}>
        <Button onPress={transitionBack} style={styles.closeBtn} full rounded>
          <Icon name="times" size={30} color="#000" />
        </Button>

        <Text style={styles.headerText}>Advertiser Disclosure</Text>
        <ScrollView style={{ padding: 10}}>
          <Text style={styles.consentText}>
          The offers that appear are from companies which Edukate, Inc. / FinancialStress and its partners receive compensation. This compensation may influence the selection, appearance, and order of appearance of the offers listed below. However, this compensation also facilitates the provision by FinancialStress of certain services to you at no charge. The offers shown below do not include all financial services companies or all of their available product and service offerings.
          </Text>
        </ScrollView>

        <Text style={styles.headerText}>Additional Information</Text>
        <ScrollView style={{ padding: 10 }}>
          <Text style={styles.consentText}>
          We do not charge any fee or cost to submit a loan request on www.financialstress.com. The actual loan rate depends on the loan amount and term requested, as well as your credit score, usage and history. If you are offered a loan, you will have the opportunity to review the final offer made by lender or lending partner, and to accept the loan agreement made directly between you and the lender or lending partner. The lenders and lending partners we work with may charge an origination fee for facilitating the loan to you. For example, on a $10,000 loan with a 5% origination fee, you will incur a $500 origination fee and receive $9,500 upon successful issuance of your loan. Rate, terms and fees will be based on your individual credit history and underwritten by the lender and not by Edukate, Inc. / FinancialStress (“Edukate, Inc.”, “Edukate”, “FinancialStress”, “we”, “us”, “our”).
          </Text>
        </ScrollView>
      </View>
    )
  };
}

export default AdvertiserDisclosure
