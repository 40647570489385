import React, { Component } from 'react'
import { styles } from './SharedStyle.js'
import { Dimensions, View, Image, Text, ScrollView, TextInput, TouchableHighlight } from 'react-native'
import { Button } from 'native-base'
import Icon from 'react-native-vector-icons/FontAwesome';
import { TextInputMask } from 'react-native-masked-text'
import '@expo/match-media'
import MediaQuery from 'react-responsive'
import BrowserSniff from '../BrowserSniff'

const ID = 4

// <View style={{width: '65%'}}>
//   <TextInput
//     ref={ref => this._emailRef = ref}
//     style={email.valid ? styles.itemValid : email.error ? styles.itemError : styles.item}
//     placeholder="Email"
//     value={email.value}
//     onFocus={() => onFocus('email')}
//     onChangeText={(value) => onTextChange('email', value)}
//     onSubmitEditing={() => {
//      this._phoneNumberRef.getElement().focus()
//    }}
//   />
// </View>

class BasicInfoForm extends Component {

  render() {
    const { transitionToAdDisclosure, visibleScreen, onTextChange, onPickerChange, state, onFocus, screenMap } = this.props
    const { firstName, lastName, phoneNumber, email, birthDate, firstNameValid,
      lastNameValid, birthDateValid, emailValid, phoneNumberValid } = state

    if (visibleScreen !== ID) { return null }
    return (
        <View style={{justifyContent:'center', alignItems: 'center'}}>
          <Header />

          <View>
            <Text style={styles.stepText}>Step {ID-1} of {screenMap.length-1}</Text>
            <Text style={styles.stepDisclosureText} onPress={transitionToAdDisclosure}>Advertiser Disclosure</Text>
          </View>

          <View style={{width: '65%', marginTop: 10}}>
            <TextInput
              style={firstName.valid ? styles.itemValid : firstName.error ? styles.itemError : styles.item}
              placeholder="First Name"
              value={firstName.value}
              onChangeText={(value) => onTextChange('firstName', value)}
              onSubmitEditing={() => {
               this._lastNameRef.getElement().focus()
             }}
            />
          </View>

          <View style={{width: '65%'}}>
            <TextInput
              ref={ref => this._lastNameRef = ref}
              style={lastName.valid ? styles.itemValid : lastName.error ? styles.itemError : styles.item}
              placeholder="Last Name"
              value={lastName.value}
              onChangeText={(value) => onTextChange('lastName', value)}
              onSubmitEditing={() => {
               this._emailRef.getElement().focus()
             }}
            />
          </View>

          <View style={{width: '65%'}}>
            <TextInputMask
              style={birthDate.valid ? styles.itemValid : birthDate.error ? styles.itemError : styles.item}
              type={'datetime'}
              placeholder="Birth Date (MM/DD/YYYY)"
              keyboardType={undefined}
              options={{
                format: 'MM/DD/YYYY'
              }}
              value={birthDate.value}
              onChangeText={(value) => onTextChange('birthDate', value)}
              onSubmitEditing={() => {
                this._phoneNumberRef.getElement().focus()
             }}
             />
          </View>

          <View style={{width: '65%'}}>
            <TextInputMask
              ref={ref => this._phoneNumberRef = ref}
              style={phoneNumber.valid ? styles.itemValid : phoneNumber.error ? styles.itemError : styles.item}
              type={'custom'}
              options={{
                /**
                 * mask: (String | required | default '')
                 * the mask pattern
                 * 9 - accept digit.
                 * A - accept alpha.
                 * S - accept alphanumeric.
                 * * - accept all, EXCEPT white space.
                */
                withDDD: true,
                dddMask: '(999) ',
                mask: '(999)-999-9999'
              }}
              keyboardType={undefined}
              value={phoneNumber.value}
              placeholder="Phone Number"
              onChangeText={(value) => onTextChange('phoneNumber', value)}
              onSubmitEditing={() => {
                this._phoneNumberRef.getElement().blur()
             }}
            />
          </View>
      </View>
    )
  }
}

const Header = () => {
  const { isMobileLargeDevice, isDesktopDevice, isMobileSmallDevice } = BrowserSniff()
  return <Text style={styles.headerTextMobileLarge}>Personal Information</Text>
}

export default BasicInfoForm
