import React, { Component } from 'react'
import { styles } from './SharedStyle.js'
import { Animated, Dimensions, View, Image, Text, TouchableOpacity } from 'react-native'
import { Button } from 'native-base'
import Icon from 'react-native-vector-icons/FontAwesome'
import BrowserSniff from '../../common/BrowserSniff'
import LoanProviderInfo from '../../../mocks/LoanProviderInfo'
import Loader from 'react-loader-spinner'
import * as Animatable from 'react-native-animatable'
const v = require('valib')

const findBestRates = (offer = {}) => {
  const monthlyPayment = offer.minMonthlyPayment || offer.monthlyPayment ||offer.meanMonthlyPayment|| offer.maxMonthlyPayment
  const apr = offer.minApr || offer.meanApr || offer.maxApr
  const termUnit = offer.termUnit
  const termLength = offer.termLength
  return { monthlyPayment, apr, termUnit, termLength }
}

const OfferCard = (props) => {
  const { disablePanResponder, setPanHandler, transitionToMatchDisclosure, offer, panResponder } = props
  const { isMobileLargeDevice, isDesktopDevice, isMobileSmallDevice } = BrowserSniff()
  if(v.Array.isEmpty(offer)) { return(null) }

  const { monthlyPayment, apr, termUnit, termLength } = findBestRates(offer)
  const { originator, url } = offer
  const { images, name } = originator
  const [provider] = LoanProviderInfo.filter((provider) => { return provider.name === name })
  const description =  v.String.isEmpty(provider.description) ? '' : provider.description

  if(isMobileLargeDevice || isMobileSmallDevice) {
    disablePanResponder()
    return (
      <Animated.View style={[styles.offerCardMobileLarge, {left: 45}]}>
        <View style={{flex: 2}}>
        <Animatable.View
          animation="bounceIn"
          easing="lightSpeedIn">
        <View style={{ marginTop: 10 }}>
          <Text style={styles.monthlyRateOfferCardTextMobileLarge}>${parseFloat(monthlyPayment).toFixed(2)}</Text>
        </View>
          <View style={[styles.offerView, {marginBottom: 5}]}>
              <Text style={styles.headerOfferCardTextMobileLarge}>Est. Monthly Payment</Text>
          </View>
        </Animatable.View>
          <View style={styles.cardInfo}>
            <Image style={{ width: 100, marginLeft: 35, marginRight: 100, height: 50}} source={{ uri: images[0].url }}/>
            <View style={styles.actionCardView}>
              <Text style={[styles.cardActionText, { marginLeft: 10 }]}>{termLength} {termUnit}s</Text>
              <Text style={[styles.cardActionSubText, { marginRight: 50 }]}>Term of Loan</Text>
            </View>
          </View>
          <View style={styles.cardInfo}>
            <Text style={[styles.cardDescriptionTextMobileLarge]}>{description}</Text>
            <View style={[styles.actionCardView, { marginTop: 20 }]}>
              <Text style={[styles.cardActionText]}>{parseFloat(apr).toFixed(2)}%</Text>
              <Text style={styles.cardActionSubText}>Fixed APR</Text>
            </View>
          </View>
          <View style={{justifyContent: 'center', alignItems: 'center', width: '100%'}}>
            <Text onPress={() => transitionToMatchDisclosure(offer)} style={styles.offerDisclosureActionText}>Advertiser Disclosure & Disclaimer</Text>
          </View>
        </View>
      </Animated.View>
    )
  }

  setPanHandler()
  return (
    <Animated.View style={[styles.offerCard]}
      {...panResponder.panHandlers}>
      <View style={{flex: 2}}>
      <Animatable.View
        animation="bounceIn"
        easing="lightSpeedIn">
        <View style={{ marginTop: 10 }}>
          <Text style={styles.monthlyRateOfferCardText}>${parseFloat(monthlyPayment).toFixed(2)}</Text>
        </View>
          <View style={styles.offerView}>
            <Text style={styles.headerOfferCardText}>Est. Monthly Payment</Text>
          </View>
       </Animatable.View>
        <View style={styles.cardInfo}>
          <Image style={{ width: 125, marginLeft: 10, marginRight: 100, height: 50}} source={{ uri: images[0].url }}/>
          <View style={styles.actionCardView}>
            <Text style={[styles.cardActionText, { marginLeft: 37 }]}>{termLength} {termUnit}s</Text>
            <Text style={styles.cardActionSubText}>Term of Loan</Text>
          </View>
        </View>
        <View style={styles.cardInfo}>
          <Text style={[styles.cardDescriptionText]}>{description}</Text>
          <View style={[styles.actionCardView, { marginTop: 20 }]}>
            <Text style={[styles.cardActionText, { marginLeft: 45 }]}>{parseFloat(apr).toFixed(2)}%</Text>
            <Text style={[styles.cardActionSubText]}>Fixed APR</Text>
          </View>
        </View>
        <View style={{justifyContent: 'center', alignItems: 'center', width: '100%'}}>
          <Text onPress={() => transitionToMatchDisclosure(offer)} style={styles.offerDisclosureActionText}>Advertiser Disclosure & Disclaimer</Text>
        </View>
      </View>
    </Animated.View>
  )
}

export default OfferCard
