import React, { Component } from 'react'
import { styles } from './SharedStyle.js'
import { Dimensions, StyleSheet, Text, View, Image, ImageBackground, Animated, TouchableWithoutFeedback } from 'react-native';
import BrowserSniff from '../../common/BrowserSniff'
import Svg, { Path, Circle, Rect } from 'react-native-svg';

const backgroundUri = require('./images/background.png')
const overlayUri = require('./images/overlay_logos.png')
const logoUri = require('./images/logo.png')
const handUri = require('./images/hand.png')

const mask = {
  width: 475,
  height: 50,
  bgColor: '#fff',
};

const maskMobile = {
  width: Dimensions.get('window').width,
  height: 50,
  bgColor: '#fff',
};

const maskMobileSmall = {
  width: Dimensions.get('window').width,
  height: 35,
  bgColor: '#fff',
};


const GetStarted = (props) => {
  const { isMobileLargeDevice, isDesktopDevice, isMobileSmallDevice } = BrowserSniff()
  const { slideInRight, slideInLeft, bounce, transitionToGame } = props

  if(isMobileLargeDevice) {
    return (
      <View style={{backbgoundColor: '#fff'}}>
        <View>
          <View style={{  height: 325, width: '100%', justifyContent: 'flex-end'}}>
              <ImageBackground
                style={styles.landingFeaturedImageMobile}
                source={backgroundUri}
              >
              <Image
                style={{ alignItems: 'center', justifyContent: 'center', width: "100%", height: 310, zIndex: 10000}}
                source={overlayUri}
              />
            </ImageBackground>
            <View style={styles.mask}>
              <Svg width={maskMobile.width} height={maskMobile.height}>
                <Path
                  fill={maskMobile.bgColor}
                  d={`M 0 0 L 0 ${maskMobile.height} L ${maskMobile.width} ${maskMobile.height} L ${maskMobile.width} 0 A ${maskMobile.width / 2} ${maskMobile.height / 2} 0 0 1 ${maskMobile.width / 2} ${maskMobile.height / 2} A ${maskMobile.width / 2} ${maskMobile.height / 2} 0 0 1 0 0 z `} />
              </Svg>
          </View>

        </View>
      </View>

      <View style={styles.col}>

        <View style={styles.narrativeMobile}>
          <View style={{width: '100%'}}>
            <Animated.View style={[styles.headerView, {marginLeft: slideInLeft}]}>
              <Text
                style={styles.landingHeaderTextMobile}>
                Match With Personal Loans
              </Text>
            </Animated.View>

            <Animated.View style={[styles.descriptionView, {marginLeft: slideInLeft}]}>
              <Text
                style={styles.landingDescriptionTextMobile}>
                Swipe through questions about your money. Match with personal loan quotes in less than <Text style={styles.landingBoldTextMobile}>2 minutes!</Text>
              </Text>
            </Animated.View>

            <View>
              <TouchableWithoutFeedback onPress={() => transitionToGame('B')}>
                <Animated.View style={[styles.landingButtonMobile, { transform: [{scale: bounce}] }]}>
                  <Text uppercase={false} style={styles.landingButtonTextMobile}>Get Started</Text>
                </Animated.View>
              </TouchableWithoutFeedback>
           </View>

           <View style={{flexDirection: 'row', flex: 1, marginLeft: 10, marginTop: 70, alignItems: 'left', justifyContent: 'left', width: '100%'}}>
             <Text
               style={styles.landingBrandingText}>
               Presented by:
             </Text>
             <Image
               style={{marginLeft: 10, marginTop: 0, width: 110, height: 20}}
               source={logoUri} />
           </View>
         </View>

          <Animated.View style={[styles.marketingMobile, {marginLeft: slideInRight}]}>
            <Image
              style={{ zoom: 0.7, marginLeft: 10, width: '285px', height: 500, zIndex: 10000}}
              source={handUri}
            />
          </Animated.View>
        </View>
      </View>
    </View>
    )
  }

  if(isMobileSmallDevice) {
    return (
      <View style={{backbgoundColor: '#fff'}}>
        <View>
          <View style={{  height: 250, width: '100%', justifyContent: 'flex-end'}}>
              <ImageBackground
                style={styles.landingFeaturedImageMobileSmall}
                source={backgroundUri}
              >
              <Image
                style={{ alignItems: 'center', justifyContent: 'center', width: "100%", height: 245, zIndex: 10000}}
                source={overlayUri}
              />
            </ImageBackground>
            <View style={styles.mask}>
              <Svg width={maskMobileSmall.width} height={maskMobileSmall.height}>
                <Path
                  fill={maskMobileSmall.bgColor}
                  d={`M 0 0 L 0 ${maskMobileSmall.height} L ${maskMobileSmall.width} ${maskMobileSmall.height} L ${maskMobileSmall.width} 0 A ${maskMobileSmall.width / 2} ${maskMobileSmall.height / 2} 0 0 1 ${maskMobileSmall.width / 2} ${maskMobileSmall.height / 2} A ${maskMobileSmall.width / 2} ${maskMobileSmall.height / 2} 0 0 1 0 0 z `} />
              </Svg>
          </View>
        </View>
      </View>

      <View style={styles.col}>

        <View style={styles.narrativeMobile}>
          <View style={{width: '100%'}}>
            <Animated.View style={[styles.headerView, {marginLeft: slideInLeft}]}>
              <Text
                style={styles.landingHeaderTextMobile}>
                Match With Personal Loans
              </Text>
            </Animated.View>

            <Animated.View style={[styles.descriptionView, {marginLeft: slideInLeft}]}>
              <Text
                style={styles.landingDescriptionTextMobile}>
                Swipe through questions about your money. Match with personal loan quotes in less than <Text style={styles.landingBoldTextMobile}>2 minutes!</Text>
              </Text>
            </Animated.View>

            <View>
              <TouchableWithoutFeedback onPress={() => transitionToGame('B')}>
                <Animated.View style={[styles.landingButtonMobile, { transform: [{scale: bounce}] }]}>
                  <Text uppercase={false} style={styles.landingButtonTextMobile}>Get Started</Text>
                </Animated.View>
              </TouchableWithoutFeedback>
           </View>

           <View style={{flexDirection: 'row', flex: 1, marginLeft: 10, marginTop: 30, alignItems: 'left', justifyContent: 'left', width: '100%'}}>
             <Text
               style={styles.landingBrandingText}>
               Presented by:
             </Text>
             <Image
               style={{marginLeft: 10, marginTop: 0, width: 110, height: 20}}
               source={logoUri} />
           </View>
         </View>

          <Animated.View style={[styles.marketingMobile, {marginLeft: slideInRight}]}>
            <Image
              style={{ zoom: 0.7,  marginLeft: 15, width: '285px', height: 500, zIndex: 10000}}
              source={handUri}
            />
          </Animated.View>
        </View>
      </View>
    </View>
    )
  }

  return (
    <View>
      <View>
        <View style={{  height: 425, width: '100%', justifyContent: 'flex-end'}}>
            <ImageBackground
              style={styles.landingFeaturedImage}
              source={backgroundUri}
            >
            <Image
              style={{ alignItems: 'center', justifyContent: 'center', width: "100%", height: 410, zIndex: 10000}}
              source={overlayUri}
            />
          </ImageBackground>
          <View style={styles.mask}>
            <Svg width={mask.width} height={mask.height}>
              <Path
                fill={mask.bgColor}
                d={`M 0 0 L 0 ${mask.height} L ${mask.width} ${mask.height} L ${mask.width} 0 A ${mask.width / 2} ${mask.height / 2} 0 0 1 ${mask.width / 2} ${mask.height / 2} A ${mask.width / 2} ${mask.height / 2} 0 0 1 0 0 z `} />
            </Svg>
        </View>
      </View>
    </View>

    <View style={styles.col}>

      <View style={styles.narrative}>
        <View style={{width: '100%'}}>
          <Animated.View style={[styles.headerView, {marginLeft: slideInLeft}]}>
            <Text
              style={styles.landingHeaderText}>
              Match With Personal Loans
            </Text>
          </Animated.View>

          <Animated.View style={[styles.descriptionView, {marginLeft: slideInLeft}]}>
            <Text
              style={styles.landingDescriptionText}>
              Swipe through questions about your money. Match with personal loan quotes in less than <Text style={styles.landingBoldText}>2 minutes!</Text>
            </Text>
          </Animated.View>

          <View>
            <TouchableWithoutFeedback onPress={() => transitionToGame('B')}>
              <Animated.View style={[styles.landingButton, { transform: [{scale: bounce}] }]}>
                <Text uppercase={false} style={styles.landingButtonText}>Get Started</Text>
              </Animated.View>
            </TouchableWithoutFeedback>
         </View>

         <View style={{flexDirection: 'row', flex: 1, marginLeft: 10, marginTop: 80, alignItems: 'left', justifyContent: 'left', width: '100%'}}>
           <Text
             style={styles.landingBrandingText}>
             Presented by:
           </Text>
           <Image
             style={{marginLeft: 10, marginTop: 0, width: 110, height: 20}}
             source={logoUri} />
         </View>
       </View>

        <Animated.View style={[styles.marketing, {marginLeft: slideInRight}]}>
          <Image
            style={{ zoom: 0.7, marginLeft: 50, width: '318px', height: 550, zIndex: 10000}}
            source={handUri}
          />
        </Animated.View>
      </View>
    </View>
  </View>
  )
}

export default GetStarted
