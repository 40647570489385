import React, { Component } from 'react'
import { Container, Content} from 'native-base';
import { Image } from 'react-native'
import { styles } from './SharedStyle.js'
import { connect } from 'react-redux'
import '@expo/match-media'
import MediaQuery from 'react-responsive'

const mapStateToProps = () => {
  return {
  }
}

const mapDispatchToProps = {
}

class GameContainer extends Component {

  constructor(props) {
    super(props)
  }

  render() {
    return (

      <Container
        style={styles.container}>
        <MediaQuery minDeviceWidth={992}>
          <DesktopContent>{this.props.children}</DesktopContent>
        </MediaQuery>

        <MediaQuery maxDeviceWidth={767}>
          <MobileContent>{this.props.children}</MobileContent>
        </MediaQuery>

      </Container>
    )
  };
}

const DesktopContent = ({ children }) => {
  return (
    <Content
      style={styles.content}
      children={children}>
    </Content>
  );
}

const MobileContent = ({ children }) => {
  return (
    <Content
      style={styles.contentMobile}
      children={children}>
    </Content>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(GameContainer)
