import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
  featuredImage: {
    height: '300px',
  },

  featuredImageMobileLarge: {
    height: '225px',
  },

  featuredImageMobileSmall: {
    height: '175px',
  },

  headerText: {
    fontSize: '24px',
    marginTop: '20px',
    color: '#141E3F',
    fontFamily: 'Nunito-Bold'
  },

  subHeaderText: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#101D42',
    marginTop: '20px',
    fontFamily: 'Nunito-Regular'
  },

  progressBar: {
    alignItems: 'center',
  },

  actionText: {
    fontSize: '16px',
    marginTop: '20px',
    color: '#434660',
    fontFamily: 'Nunito-Regular'
  },

  actionTextSmall: {
    fontSize: '14px',
    marginTop: '5px',
    color: '#434660',
    fontFamily: 'Nunito-Regular'
  },

  actionBtn: {
    backgroundColor: '#69B679',
    borderColor: '#69B679',
    borderStyle: 'solid',
    borderWidth: '1px',
    alignItems: 'center',
    marginTop: '20px',
    marginLeft: '110px',
    marginRight: '110px',
    padding: 30,
    textAlign: 'center'
  },

  actionBtn2: {
    marginTop: '10',
    marginTop: '15px',
    borderColor: '#101D42',
    borderStyle: 'solid',
    borderWidth: '1px',
    alignItems: 'center',
    marginBottom: '5px',
    textAlign: 'center',
    marginLeft: '50px',
    marginRight: '50px',
    padding: 50,
    height: 50,
    backgroundColor: 'transparent'
  },

  actionBtnText: {
    color: '#fff',
    alignItems: 'center'
  },

  actionBtnText2: {
    color: '#101D42',
    alignItems: 'center'
  },

  actionImage: {
    borderRadius: '10px',
    width: "70%",
    height: 300
  },

  actionImageMobileLarge: {
    borderRadius: '10px',
    width: "75%",
    height: 250
  },

  actionImageMobileSmall: {
    borderRadius: '10px',
    width: "70%",
    height: 175
  },

  logoImage: {
    width: 40,
    textAlign: 'center',
    height: 30,
  },

  action: {
    flex: 1,
    backgroundColor:'#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },

  brandView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    height: 30,
    marginTop: 10,
    marginBottom: 10
  },

  launchForm: {
    marginBottom: '10px',
    marginTop: '20px'
  },

  transitionButton: {
    height: '60px',
    marginBottom: '10px'
  },

  footerText: {
    fontSize: '11px',
    textAlign: 'left',
  },

  footerTextMobileLarge: {
    fontSize: '11px',
    textAlign: 'left',
  },

  footerTextMobileSmall: {
    fontSize: '9px',
    textAlign: 'left',
  },

  item: {
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: '#C3E0BE',
    borderRadius: '25px',
    marginBottom: '10px',
    marginLeft: '80px',
    marginRight: '80px',
    padding: '15px',
    color: 'rgb(67, 70, 96)',
    outlineColor: 'transparent',
    fontSize: 12,
    paddingHorizontal: 25,
    outline: "none",
    outlineWidth: 0,
  },

  itemValid: {
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: '#69B679',
    borderRadius: '25px',
    marginBottom: '10px',
    marginLeft: '80px',
    marginRight: '80px',
    padding: '15px',
    color: 'rgb(67, 70, 96)',
    outlineColor: 'transparent',
    fontSize: 12,
    paddingHorizontal: 25,
    outline: "none",
    outlineWidth: 0,
  },


  itemError: {
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: '#A63D40',
    borderRadius: '25px',
    marginBottom: '10px',
    marginLeft: '80px',
    marginRight: '80px',
    padding: '15px',
    color: 'rgb(67, 70, 96)',
    outlineColor: 'transparent',
    fontSize: 12,
    paddingHorizontal: 25,
    outline: "none",
    outlineWidth: 0,
  },

  continueBtn: {
    backgroundColor: '#C3E0BE',
    alignItems: 'center',
    marginLeft: '200px',
    marginRight: '200px',
    padding: 10,
    borderRadius: 30,
    marginTop: 10,
    textAlign: 'center',
  },

  continueBtnValid: {
    backgroundColor: '#69B679',
    alignItems: 'center',
    marginLeft: '150px',
    marginRight: '150px',
    padding: 10,
    borderRadius: 30,
    marginTop: 10,
    textAlign: 'center',
  },

  continueText: {
    color: '#fff',
    fontSize: '11px',
    alignItems: 'center',
    fontFamily: 'Nunito-Regular'
  },

  hyperlink: {
    color: '#69B679',
    marginLeft: 2,
    textDecorationLine: 'underline'
  },
})
