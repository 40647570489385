import React, { Component } from 'react'
import { styles } from './SharedStyle.js'
import { Text, View } from 'react-native';
import MediaQuery from "react-responsive";
import BrowserSniff from '../../common/BrowserSniff'
//View our Privacy Policy and Terms of Use.
const Footer = (props) => {
  const { isMobileLargeDevice, isDesktopDevice, isMobileSmallDevice } = BrowserSniff()
  const { text, handlePolicyClick, handleTermsClick, removeFieldsFromView } = props

  if(removeFieldsFromView) {
    return (null)
  }

  if(isMobileLargeDevice) {
    return (
      <View style={{  flex: 1,  marginTop: 10, marginLeft: 50, marginRight: 40, height: 20, backgroundColor: '#fff' }}>
        <Text
          style={styles.footerTextMobileLarge}>
          {text}
          View our
          <Text style={styles.hyperlink} onPress={handlePolicyClick}>Privacy Policy </Text>
          and
          <Text style={styles.hyperlink} onPress={handleTermsClick}>Terms of Use</Text>
          .
        </Text>
    </View>
    )
  }

  if(isMobileSmallDevice) {
    return (
      <View style={{  flex: 1, marginLeft: 70, marginRight: 50, height: 20, backgroundColor: '#fff' }}>
      <Text
        style={styles.footerTextMobileSmall}>
        {text}
        View our
        <Text style={styles.hyperlink} onPress={handlePolicyClick}>Privacy Policy </Text>
        and
        <Text style={styles.hyperlink} onPress={handleTermsClick}>Terms of Use</Text>
        .
      </Text>
    </View>
    )
  }

  return (
    <View style={{  flex: 1,  marginTop: 10, marginLeft: 50, marginRight: 40, height: 20, backgroundColor: '#fff' }}>
      <Text
        style={styles.footerText}>
        {text}
        View our
        <Text style={styles.hyperlink} onPress={handlePolicyClick}>Privacy Policy </Text>
        and
        <Text style={styles.hyperlink} onPress={handleTermsClick}>Terms of Use</Text>
        .
      </Text>
  </View>
  )
}

export default Footer
