import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
  headerText: {
    fontSize: '26px',
    marginTop: '20px',
    textAlign: 'center',
    marginLeft: 100,
    marginRight: 100,
    color: '#101D42',
    fontFamily: 'Nunito-SemiBold'
  },

  headerTextMobileLarge: {
    fontSize: '24px',
    marginTop: '10px',
    textAlign: 'center',
    marginLeft: 100,
    marginRight: 100,
    color: '#101D42',
    fontFamily: 'Nunito-SemiBold'
  },

  headerTextMobileSmall: {
    fontSize: '23px',
    marginTop: '8px',
    textAlign: 'center',
    marginLeft: 100,
    marginRight: 100,
    color: '#101D42',
    fontFamily: 'Nunito-SemiBold'
  },

  featuredImage: {
    height: '175px',
  },

  featuredImageMobileLarge: {
    height: '125px',
  },

  featuredImageMobileSmall: {
    height: '100px',
  },

  infoText: {
    marginTop: 20,
    fontSize: '13px',
    textAlign: 'center',
    color: '#101D42',
    fontFamily: 'Nunito-Regular'
  },

  item: {
    textAlign: 'center',
    shadowColor: "#000",
    shadowOffset: {
    	width: 0,
    	height: 6,
    },
    shadowOpacity: 0.37,
    shadowRadius: 7.49,

    elevation: 12,
    display: 'flex',
    backgroundColor: '#fff',
    borderRadius: '12px',
    marginBottom: '10px',
    marginLeft: '10px',
    marginRight: '10px',
    height: 250,
    width: '190px'
  },

  itemMobile: {
    textAlign: 'center',
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 6,
    },
    shadowOpacity: 0.37,
    shadowRadius: 7.49,

    elevation: 12,
    display: 'flex',
    backgroundColor: '#fff',
    borderRadius: '12px',
    marginBottom: '10px',
    marginLeft: '10px',
    marginRight: '10px',
    height: 250,
    width: '175px'
  },

  disclosureView: {
    height: "100%",
    backgroundColor: '#fff'
  },

  consentText: {
    marginTop: '5px',
    fontSize: '13px',
    textAlign: 'left',
    marginLeft: 30,
    marginRight: 30,
    marginTop: 20,
    marginBottom: 10,
    fontFamily: 'Nunito-Regular'
  },

  footerText: {
    fontSize: '11px',
    textAlign: 'left',
    marginLeft: 10,
    marginBottom: 10,
    fontFamily: 'Nunito-Regular',
    color: "rgba(67,70,96, .5)",
    textDecorationLine: 'underline',
  },

  closeBtn: {
    textAlign: 'center',
    backgroundColor: '#fff'
  },

  offerImage: {
    marginLeft: 10,
    width: '80%',
    height: 75,
  },

  offerImageMobile: {
    marginLeft: 10,
    width: '90%',
    height: 75,
  },

  offerHeader: {
    marginTop: '10px',
    marginBottom: '20px',
    fontSize: '22px',
    color: '#101D42',
    textAlign: 'left',
    color: '#101D42',
    fontFamily: 'Nunito-Black'
  },

  offerHeaderActionText: {
    fontSize: '11px',
    color: '#101D42',
    fontFamily: 'Nunito-Regular',
    textAlign: 'left',
    marginLeft: 5,
    marginTop: '20px',
  },

  offerCategory: {
    fontSize: '11px',
    marginLeft: '10px',
    color: '#101D42',
    fontFamily: 'Nunito-Bold',
    textAlign: 'left',
    marginBottom: 10,
  },

  offerDetails: {
    marginLeft: '10px',
    flexDirection: 'row',
  },

  offerActionHeader: {
    fontSize: '12px',
    color: '#101D42',
    fontFamily: 'Nunito-Black',
    textAlign: 'left'
  },

  offerActionText: {
    fontSize: '12px',
    color: '#101D42',
    fontFamily: 'Nunito-Regular',
    textAlign: 'left',
    marginLeft: 5
  },

  continueBtn: {
    marginTop: '10px',
    backgroundColor: '#C3E0BE',
    alignItems: 'center',
    marginLeft: '10px',
    marginRight: '10px',
    textAlign: 'center'
  },

  continueText: {
    color: '#fff',
    alignItems: 'center'
  },

  offerDisclosureActionText: {
    fontSize: '10px',
    marginTop: 10,
    marginBottom: '10px',
    textAlign: 'center',
    color: '#101D42',
    textDecorationLine: 'underline',
    fontFamily: 'Nunito-Bold'
  },

  offerApprovalText: {
    fontSize: '12px',
    marginTop: 5,
    textAlign: 'center',
    color: '#101D42',
    fontFamily: 'Nunito-Bold'
  },

  recommendedText: {
    fontSize: '10px',
    textAlign: 'center',
    color: '#fff',
    fontFamily: 'Nunito-Bold'
  },

  matchRow: {
    flex: 1,
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 35,
    marginRight: 35
  },

  recommendedView: {
    marginLeft: 30,
    width: '70%',
    backgroundColor: '#4AA46E',
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5
  },

  recommendedViewMobile: {
    marginLeft: 30,
    marginRight: 30,
    width: '70%',
    backgroundColor: '#4AA46E',
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5
  }
})
