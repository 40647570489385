const DEFAULT_STATE = {
  showDisclosure: false,
  currentScreenIndex: 1,
  loanPurpose: { id: 'loanPurpose', value: 'debt_consolidation', valid: true, error: false },
  monthlyRate: { id: 'monthlyRate', value: '$113.40', valid: true, error: false },
  educationLevel: { id: 'educationLevel', value: '', valid: false, error: false },
  employmentStatus: { id: 'employmentStatus', value: '', valid: false, error: false },
  employmentPayFrequency: { id: 'employmentPayFrequency', value: '', valid: false, error: false },
  address1: { id: 'address1', value: '', valid: false, error: false },
  address2: { id: 'address2', value: '', valid: false, error: false },
  zipcode: { id: 'zipcode', value: '', valid: false, error: false },
  city: { id: 'city', value: '', valid: false, error: false },
  stateAbv: { id: 'stateAbv', value: '', valid: false, error: false },
  firstName: { id: 'firstName', value: '', valid: false, error: false },
  lastName: { id: 'lastName', value: '', valid: false, error: false },
  email: { id: 'email', value: '', valid: false, error: false },
  phoneNumber: { id: 'phoneNumber', value: '', valid: false, error: false },
  birthDate: { id: 'birthDate', value: '', valid: false, error: false },
  annualIncome: { id: 'annualIncome', value: '', valid: false, error: false },
  propertyStatus: { id: 'propertyStatus', value: '', valid: false, error: false },
  annualRate: { id: 'annualRate', value: '4.99%', valid: true, error: false },
  loanAmount: { id: 'loanAmount', toolValue: 10000, valid: false, error: false },
  term:  { id: 'term', value: '72 months', valid: true, error: false },
  creditScore: { id: 'creditScore', value: 3, valid: true, error: false },
  creditRating: { id: 'creditRating', value: 'good', valid: true, error: false },
  socialSecurity: { id: 'socialSecurity', value: '', valid: false, error: false },
  autoCompleteResults: [],
  autoCompleteSelected: false,
  rateResults: [],
  rateBlockInProgress: false,
  launchEmail: '',
  launchZipcode: '',
  showIncomeDisclosure: false,
  showAdDisclosure: false,
  showMatchDisclosure: false,
  selectedOffer: null,
  sortedLoanOffers: [],
  type: 'Tool',
  visibleOffer: 0
}

export default DEFAULT_STATE
