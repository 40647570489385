import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
  headerText: {
    fontSize: '30px',
    marginTop: '10px',
    textAlign: 'center',
    color: '#101D42',
    fontFamily: 'Nunito-Bold'
  },

  headerTextMobileLarge: {
    fontSize: '24px',
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#101D42',
    fontFamily: 'Nunito-Bold'
  },

  headerTextMobileSmall: {
    fontSize: '24px',
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#101D42',
    fontFamily: 'Nunito-Bold'
  },

  brandView: {
    flex: 1,
    justifyContent: 'center',
    zIndex: 100000,
    alignItems: 'center',
    height: 20,
    marginTop: 10,
    marginBottom: 10
  },

  actionText: {
    fontSize: '18px',
    color: '#101D42',
    marginTop: '20px',
    fontFamily: 'Nunito-Regular'
  },

  actionTextMobileLarge: {
    fontSize: '16px',
    color: '#101D42',
    marginTop: '20px',
    fontFamily: 'Nunito-Regular'
  },

  actionTextMobileSmall: {
    fontSize: '16px',
    color: '#101D42',
    marginTop: '20px',
    fontFamily: 'Nunito-Regular'
  },

  actionBtn: {
    backgroundColor: '#69B679',
    alignItems: 'center',
    marginLeft: '125px',
    marginRight: '125px',
    marginTop: 20,
    textAlign: 'center',
  },

  anchorText: {
    fontSize: '16px',
    color: '#134074',
    fontFamily: 'Roboto',
  },

  actionBtnText: {
    color: '#fff',
    alignItems: 'center'
  },

  listBox: {
    marginLeft: '50px',
    marginRight: '50px',
  },

  checkbox: {
    backgroundColor: '#101D42'
  },

  checkboxText: {
    fontSize: '13px',
  },

  subscribeImage: {
    width: '60%',
    borderRadius: '30px',
    marginTop: '10px',
    height: 175
  },

  subscribeImageMobileLarge: {
    width: '60%',
    borderRadius: '30px',
    marginTop: '20px',
    height: 150
  },

  subscribeImageMobileSmall: {
    width: '60%',
    borderRadius: '30px',
    marginTop: '20px',
    height: 150
  },

  subscribe: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  }
})
