import React, { Component } from 'react'
import { styles } from './SharedStyle.js'
import { Dimensions, View, Image, Text, ScrollView, TextInput, TouchableHighlight } from 'react-native'
import { Button } from 'native-base'
import Icon from 'react-native-vector-icons/FontAwesome';
import { TextInputMask } from 'react-native-masked-text'
import { Picker } from 'react-native'
import { Ionicons } from '@expo/vector-icons'
import { pickerSelectStyles } from './CustomMenuStyle'
import RNPickerSelect, { defaultStyles } from 'react-native-picker-select'
import '@expo/match-media'
import MediaQuery from 'react-responsive'
import FontAwesomeIcon from 'react-native-vector-icons/FontAwesome';
import BrowserSniff from '../BrowserSniff'

const ID = 5
const propertyStatusPlaceholder = {
  label: 'Choose one...',
  value: 'choose',
  color: '#C3E0BE',
}

const propertyStatuses = [
  {
    label: 'Rent',
    value: 'rent'
  },
  {
    label: 'Own with Mortgage',
    value: 'own_with_mortgage'
  },
  {
    label: 'Own',
    value: 'own'
  }
]

const PropertyStatusPicker = (props) => {
    const { propertyStatuses, propertyStatus, onTextChange, propertyStatusValid } = props
    const { isMobileLargeDevice, isDesktopDevice, isMobileSmallDevice } = BrowserSniff()
    return (<View style={{ width: '65%', marginTop: (isDesktopDevice || isMobileLargeDevice) ? 10 : 5}}>
      <RNPickerSelect
        placeholder={propertyStatusPlaceholder}
        items={propertyStatuses}
        selectedValue={propertyStatus.value}
        onValueChange={(value) => onTextChange('propertyStatus', value)}
        style={propertyStatus.valid ?
          {
            inputAndroid: {
              fontSize: 14,
              borderWidth: '2px',
              borderStyle: 'solid',
              borderColor: '#69B679',
              borderRadius: 22,
              color: '#101D42',
              padding: 10,
              paddingRight: 30,
            },
            iconContainer: {
              top: 10,
              right: 12,
            },
          }  :
          {
            inputAndroid: {
              fontSize: 14,
              borderWidth: '2px',
              borderStyle: 'solid',
              borderColor: '#C3E0BE',
              borderRadius: 22,
              color: '#101D42',
              padding: 10,
              paddingRight: 30,
            },
            iconContainer: {
              top: 10,
              right: 12,
            },
          }}
        value={propertyStatus.value}
        useNativeAndroidPickerStyle={false}
        textInputProps={{ underlineColor: '#101D42' }}
        Icon={() => {
          return <Ionicons name="md-arrow-down" size={24} style={{ marginRight: 5 }} color="grey" />;
        }}
      />
    </View>)
}

class AboutYouForm extends Component {
  constructor() {
    super()
    this._propertStatus = null
  }
  render() {
    const { transitionToAdDisclosure, visibleScreen, onTextChange, onPickerChange, state, screenMap, transitionToIncomeDisclosure } = this.props
    const { annualIncome, propertyStatus, annualIncomeValid, propertyStatusValid } = state

    if (visibleScreen !== ID) { return null }

    return (
      <View style={{justifyContent:'center', alignItems: 'center'}}>
        <Text style={styles.headerTextMobileLarge}>Financial Information</Text>

        <View>
          <Text style={styles.stepText}>Step {ID-1} of {screenMap.length-1}</Text>
          <Text style={styles.stepDisclosureText} onPress={transitionToAdDisclosure}>Advertiser Disclosure</Text>
        </View>

        <View style={{width: '65%', marginTop: 10}}>
          <Text onPress={transitionToIncomeDisclosure} style={{textAlign: 'right', marginRight: 20}}><FontAwesomeIcon name="question-circle" size={16} color="#69B679" /></Text>
          <TextInputMask
            ref={ref => this._annualIncomeRef = ref}
            type={'money'}
            style={annualIncome.valid ? styles.itemValid : annualIncome.error ? styles.itemError : styles.item}
            placeholder="Annual Income"
            value={annualIncome.value}
            keyboardType={undefined}
            options={{
             precision: 0,
             separator: '.',
             delimiter: ',',
             unit: '$',
             suffixUnit: ''
            }}
            onChangeText={(value) => onTextChange('annualIncome', value)}
            onSubmitEditing={() => {
             this._propertStatus.getElement().focus()
           }}
          />
        </View>

        <View style={[styles.loanBox, {marginTop: 10}]}>
          <Text style={styles.actionTextMobileLarge}>Do you rent or own your home?</Text>
        </View>
        <PropertyStatusPicker
          propertyStatusValid={propertyStatusValid}
          propertyStatuses={propertyStatuses}
          propertyStatus={propertyStatus}
          onTextChange={onTextChange}
          />
    </View>
    )
  }
}

export default AboutYouForm
