import React, { Component } from 'react'
import { styles } from './SharedStyle.js'
import { Animated, Dimensions, View, Image, Text, ScrollView, TextInput, TouchableOpacity, TouchableHighlight } from 'react-native'
import { Button } from 'native-base'
import Icon from 'react-native-vector-icons/FontAwesome';
import AutoCompleteCard from './AutoCompleteCard'
import '@expo/match-media'
import MediaQuery from 'react-responsive'

class AutoCompleteSelector extends Component {
  state = {
      opacity: new Animated.Value(0),
    }

  onLoad = () => {
    Animated.timing(this.state.opacity, {
      toValue: 1,
      duration: 150,
      useNativeDriver: true,
    }).start();
  }

  constructor() {
    super()
    this.onLoad()
  }

  componentDidMount() {
    this._address1Ref.focus()
  }

  render() {
    const {  address1, onTextChange, autoCompleteResults, autoCompleteHandler, autoCompleteExitHandler} = this.props

    return (
      <Animated.View onLoad={this.onLoad}
      style={[
        {
          marginLeft: '50px',
          marginRight: '50px',
          backgroundColor: 'white',
          opacity: this.state.opacity,
          transform: [
            {
              scale: this.state.opacity.interpolate({
                inputRange: [0, 1],
                outputRange: [0.8, 1],
              })
            },
          ],
        },
      ]}>

      <Text style={styles.headerTextMobileLarge}>What is Your Address?</Text>

      <TextInput
        ref={ref => this._address1Ref = ref}
        style={styles.itemValid}
        placeholder="Street Address 1"
        value={address1.value}
        onChangeText={(value) => onTextChange('address1', value)}
        onSubmitEditing={() => {
         this._address2Ref.getElement().focus()
       }}
      />
      <View style={{flex: 1}}>
        {autoCompleteResults.predictions.map((result) => {
          return (<AutoCompleteCard
            autoCompleteHandler={autoCompleteHandler}
            result={result}/>
          )}
        )}
      </View>

      <Button onPress={autoCompleteExitHandler} style={styles.closeBtn} full rounded>
        <Icon name="times" size={30} color="#000" />
      </Button>
    </Animated.View>
    )
  }
}

export default AutoCompleteSelector
