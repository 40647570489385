import React, { Component } from 'react'
import { styles } from './SharedStyle.js'
import { pickerSelectStyles } from './CustomMenuStyle'
import { Dimensions, View, Image, Text, ScrollView, TextInput, TouchableHighlight, Picker } from 'react-native'
import { Button } from 'native-base'
import Icon from 'react-native-vector-icons/FontAwesome';
import { TextInputMask } from 'react-native-masked-text'
import { Ionicons } from '@expo/vector-icons'
import RNPickerSelect from 'react-native-picker-select'
import BrowserSniff from '../../common/BrowserSniff'
import { employmentStatusPlaceholder, employmentStatuses, educationLevelPlaceholder,
  employmentPayFrequencyPlaceholder, educationLevels, employmentPayFrequencies } from './EmploymentData'
const ID = 2

const EmploymentForm = (props) => {
  const { isMobileLargeDevice, isDesktopDevice, isMobileSmallDevice } = BrowserSniff()
  const { visibleScreen, transitionToAdDisclosure, onTextChange, onPickerChange, state, screenMap, inputRefs } = props
  const { employmentStatus, educationLevel, employmentPayFrequency,
    educationLevelValid, employmentStatusValid, employmentPayFrequencyValid  } = state

  if (visibleScreen !== ID) { return null }

  if(isMobileSmallDevice) {
    return (
      <View>

        <Text style={[styles.headerTextMobileSmall]}>Education & Employment</Text>
        <View>
          <Text style={styles.stepText}>Step {ID-1} of {screenMap.length -1}</Text>
          <Text style={styles.stepDisclosureText} onPress={transitionToAdDisclosure}>Advertiser Disclosure</Text>
        </View>
        <View style={styles.loanBox}>
         <Text style={styles.actionTextMobileSmall}>What is your highest level of education?</Text>
         <View style={{ width: '65%'}}>
           <RNPickerSelect
                 placeholder={educationLevelPlaceholder}
                 items={educationLevels}
                 selectedValue={educationLevel.value}
                 onValueChange={(value) => onPickerChange('educationLevel', value)}
                 style={educationLevel.valid ?
                   {
                     inputAndroid: {
                       fontSize: 14,
                       borderWidth: '2px',
                       borderStyle: 'solid',
                       borderColor: '#69B679',
                       borderRadius: 22,
                       color: '#101D42',
                       padding: 10,
                       paddingRight: 30,
                     },
                     iconContainer: {
                       top: 10,
                       right: 12,
                     },
                   }  :
                   {
                     inputAndroid: {
                       fontSize: 14,
                       borderWidth: '2px',
                       borderStyle: 'solid',
                       borderColor: '#C3E0BE',
                       borderRadius: 22,
                       color: '#101D42',
                       padding: 10,
                       paddingRight: 30,
                     },
                     iconContainer: {
                       top: 10,
                       right: 12,
                     },
                   }}
                 value={educationLevel.value}
                 useNativeAndroidPickerStyle={false}
                 textInputProps={{ underlineColor: '#101D42' }}
                 Icon={() => {
                   return <Ionicons name="md-arrow-down" size={24} style={{ marginRight: 5 }} color="grey" />;
                 }}
               />
         </View>
        </View>

        <View style={styles.loanBox}>
          <Text style={styles.actionTextMobileSmall}>What's your employment status?</Text>
          <View style={{ width: '65%'}}>
            <RNPickerSelect
                  placeholder={employmentStatusPlaceholder}
                  items={employmentStatuses}
                  selectedValue={employmentStatus.value}
                  onValueChange={(value) => onPickerChange('employmentStatus', value)}
                  style={employmentStatus.valid ?
                    {
                      inputAndroid: {
                        fontSize: 14,
                        borderWidth: '2px',
                        borderStyle: 'solid',
                        borderColor: '#69B679',
                        borderRadius: 22,
                        color: '#101D42',
                        padding: 10,
                        paddingRight: 30,
                      },
                      iconContainer: {
                        top: 10,
                        right: 12,
                      },
                    } :
                    {
                      inputAndroid: {
                        fontSize: 14,
                        borderWidth: '2px',
                        borderStyle: 'solid',
                        borderColor: '#C3E0BE',
                        borderRadius: 22,
                        color: '#101D42',
                        padding: 10,
                        paddingRight: 30,
                      },
                      iconContainer: {
                        top: 10,
                        right: 12,
                      },
                    }}
                  value={employmentStatus}
                  useNativeAndroidPickerStyle={false}
                  textInputProps={{ underlineColor: '#101D42' }}
                  Icon={() => {
                    return <Ionicons name="md-arrow-down" size={24} style={{ marginRight: 5 }} color="grey" />;
                  }}
                />
          </View>
        </View>

        <View style={styles.loanBox}>
          <Text style={styles.actionTextMobileSmall}>How often do you get paid?</Text>
          <View style={{ width: '65%'}}>
            <RNPickerSelect
                  placeholder={employmentPayFrequencyPlaceholder}
                  items={employmentPayFrequencies}
                  selectedValue={employmentPayFrequency.value}
                  onValueChange={(value) => onPickerChange('employmentPayFrequency', value)}
                  style={employmentPayFrequency.valid ?
                    {
                      inputAndroid: {
                        fontSize: 14,
                        borderWidth: '2px',
                        borderStyle: 'solid',
                        borderColor: '#69B679',
                        borderRadius: 22,
                        color: '#101D42',
                        padding: 10,
                        paddingRight: 30,
                      },
                      iconContainer: {
                        top: 10,
                        right: 12,
                      },
                    }  :
                    {
                      inputAndroid: {
                        fontSize: 14,
                        borderWidth: '2px',
                        borderStyle: 'solid',
                        borderColor: '#C3E0BE',
                        borderRadius: 22,
                        color: '#101D42',
                        padding: 10,
                        paddingRight: 30,
                      },
                      iconContainer: {
                        top: 10,
                        right: 12,
                      },
                    }}
                  value={employmentPayFrequency.value}
                  useNativeAndroidPickerStyle={false}
                  textInputProps={{ underlineColor: '#101D42' }}
                  Icon={() => {
                    return <Ionicons name="md-arrow-down" size={24} style={{ marginRight: 5 }} color="grey" />;
                  }}
                />
          </View>
        </View>

      </View>
    )
  }

  return (
    <View>

      <Text style={[styles.headerTextMobileLarge]}>Education & Employment</Text>

      <View>
        <Text style={styles.stepText}>Step {ID-1} of {screenMap.length -1}</Text>
        <Text style={styles.stepDisclosureText} onPress={transitionToAdDisclosure}>Advertiser Disclosure</Text>
      </View>

      <View style={styles.loanBox}>
       <Text style={styles.actionTextMobileLarge}>What is your highest level of education?</Text>

       <View style={{ width: '65%'}}>
         <RNPickerSelect
               placeholder={educationLevelPlaceholder}
               items={educationLevels}
               selectedValue={educationLevel.value}
               onValueChange={(value) => onPickerChange('educationLevel', value)}
               style={educationLevel.valid ?
                 {
                   inputAndroid: {
                     fontSize: 14,
                     borderWidth: '2px',
                     borderStyle: 'solid',
                     borderColor: '#69B679',
                     borderRadius: 22,
                     color: '#101D42',
                     padding: 10,
                     paddingRight: 30,
                   },
                   iconContainer: {
                     top: 10,
                     right: 12,
                   },
                 }  :
                 {
                   inputAndroid: {
                     fontSize: 14,
                     borderWidth: '2px',
                     borderStyle: 'solid',
                     borderColor: '#C3E0BE',
                     borderRadius: 22,
                     color: '#101D42',
                     padding: 10,
                     paddingRight: 30,
                   },
                   iconContainer: {
                     top: 10,
                     right: 12,
                   },
                 }}
               ref={ref => {
                  inputRefs.educationPicker = ref;
                }}
               value={educationLevel.value}
               useNativeAndroidPickerStyle={false}
               textInputProps={{ underlineColor: '#101D42' }}
               Icon={() => {
                 return <Ionicons name="md-arrow-down" size={24} style={{ marginRight: 5 }} color="grey" />;
               }}
             />
       </View>
      </View>

      <View style={styles.loanBox}>
        <Text style={styles.actionTextMobileLarge}>What's your employment status?</Text>
        <View style={{ width: '65%'}}>
          <RNPickerSelect
                placeholder={employmentStatusPlaceholder}
                items={employmentStatuses}
                selectedValue={employmentStatus.value}
                onValueChange={(value) => onPickerChange('employmentStatus', value)}
                style={employmentStatus.valid ?
                  {
                    inputAndroid: {
                      fontSize: 14,
                      borderWidth: '2px',
                      borderStyle: 'solid',
                      borderColor: '#69B679',
                      borderRadius: 22,
                      color: '#101D42',
                      padding: 10,
                      paddingRight: 30,
                    },
                    iconContainer: {
                      top: 10,
                      right: 12,
                    },
                  }  :
                  {
                    inputAndroid: {
                      fontSize: 14,
                      borderWidth: '2px',
                      borderStyle: 'solid',
                      borderColor: '#C3E0BE',
                      borderRadius: 22,
                      color: '#101D42',
                      padding: 10,
                      paddingRight: 30,
                    },
                    iconContainer: {
                      top: 10,
                      right: 12,
                    },
                  }}
                ref={ref => {
                   inputRefs.employmentStatusPicker = ref;
                 }}
                value={employmentStatus.value}
                useNativeAndroidPickerStyle={false}
                textInputProps={{ underlineColor: '#101D42' }}
                Icon={() => {
                  return <Ionicons name="md-arrow-down" size={24} style={{ marginRight: 5 }} color="grey" />;
                }}
              />
        </View>
      </View>

      <View style={styles.loanBox}>
        <Text style={styles.actionTextMobileLarge}>How often do you get paid?</Text>
        <View style={{ width: '65%'}}>
          <RNPickerSelect
                placeholder={employmentPayFrequencyPlaceholder}
                items={employmentPayFrequencies}
                selectedValue={employmentPayFrequency.value}
                onValueChange={(value) => onPickerChange('employmentPayFrequency', value)}
                style={employmentPayFrequency.valid ?
                  {
                    inputAndroid: {
                      fontSize: 14,
                      borderWidth: '2px',
                      borderStyle: 'solid',
                      borderColor: '#69B679',
                      borderRadius: 22,
                      color: '#101D42',
                      padding: 10,
                      paddingRight: 30,
                    },
                    iconContainer: {
                      top: 10,
                      right: 12,
                    },
                  }  :
                  {
                    inputAndroid: {
                      fontSize: 14,
                      borderWidth: '2px',
                      borderStyle: 'solid',
                      borderColor: '#C3E0BE',
                      borderRadius: 22,
                      color: '#101D42',
                      padding: 10,
                      paddingRight: 30,
                    },
                    iconContainer: {
                      top: 10,
                      right: 12,
                    },
                  }}
                ref={ref => {
                   inputRefs.employmentPayFrequencyPicker = ref;
                 }}
                value={employmentPayFrequency.value}
                useNativeAndroidPickerStyle={false}
                textInputProps={{ underlineColor: '#101D42' }}
                Icon={() => {
                  return <Ionicons name="md-arrow-down" size={24} style={{ marginRight: 5 }} color="grey" />;
                }}
              />
        </View>
      </View>

    </View>
  )
}

export default EmploymentForm
