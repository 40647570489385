import React, { Component } from 'react'
import { styles } from './SharedStyle.js'
import { Dimensions, View, Image, Text, ScrollView, TextInput, TouchableHighlight } from 'react-native'
import { Button } from 'native-base'
import Icon from 'react-native-vector-icons/FontAwesome'
import HTML from 'react-native-render-html'
import validate from 'valib'

class AdMatchDisclosure extends Component {

  render() {
    const { transitionBack, offer } = this.props

    return (
      <View style={styles.disclosureView}>
        <Button onPress={transitionBack} style={styles.closeBtn} full rounded>
          <Icon name="times" size={30} color="#000" />
        </Button>

        <View>
          <Text style={styles.headerText}>Advertiser Disclosure</Text>
          <ScrollView style={{ padding: 10 }}>
            <Text style={styles.consentText}>
              The offers that appear are from companies which Edukate, Inc. / FinancialStress and its partners receive compensation. This compensation may influence the selection, appearance, and order of appearance of the offers listed below. However, this compensation also facilitates the provision by FinancialStress of certain services to you at no charge. The offers shown below do not include all financial services companies or all of their available product and service offerings.
            </Text>
            <Text style={styles.consentText}>
              <Text style={styles.consentBoldText}>Loan Estimate Tool:</Text> Loan estimates are determined by the lowest estimated monthly payment.
            </Text>
            
            <Text style={styles.headerText}>Loan Disclaimer</Text>
            <Text style={styles.consentText}>
              <HTML html={!validate.Object.isEmpty(offer) ? offer.originator.disclaimer : null} imagesMaxWidth={Dimensions.get('window').width} />
            </Text>
          </ScrollView>
        </View>
      </View>
    )
  };
}

export default AdMatchDisclosure
