import React, { Component } from 'react'
import { styles } from './SharedStyle.js'
import { Dimensions, View, Image, Text, ScrollView, TextInput, TouchableHighlight } from 'react-native'
import { Button } from 'native-base'
const equalHousingLogo = require('./images/eho-black.png')

class MatchFooter extends Component {
  render() {
    const { transitionToTerms, transitionToPrivacyPolicy, transitionToSupport, transitionToDisclosure } = this.props
    return (
      <View style={[styles.matchRow, { marginTop: 20, backgroundColor: '#fff' }]}>
        <Text style={styles.footerText} onPress={transitionToTerms}>Terms</Text>
        <Text style={styles.footerText} onPress={transitionToPrivacyPolicy}>Privacy</Text>
        <Text style={styles.footerText} onPress={transitionToSupport}>Support</Text>
        <Text style={styles.footerText} onPress={transitionToDisclosure}>Disclosures</Text>
        <Image style={[{ marginLeft: 10, marginBottom: 10, width: 25, height: 25}]} source={equalHousingLogo} />
      </View>
    )
  };
}

export default MatchFooter
