import "@expo/match-media";
import { useMediaQuery } from "react-responsive";

const BrowserSniff = () => {
  const isMobileLargeDevice = useMediaQuery({
    minDeviceHeight: '801px',
    maxDeviceWidth: '880px',
  });

  const isMobileSmallDevice = useMediaQuery({
    maxDeviceHeight: '800px',
    maxDeviceWidth: '780px'
  });

  const isDesktopDevice = useMediaQuery({
    minDeviceHeight: '1224px',
  });

  return { isMobileLargeDevice, isDesktopDevice, isMobileSmallDevice }
}


export default BrowserSniff
