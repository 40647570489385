import React, { Component } from 'react'
import { Container, Content} from 'native-base';
import { connect } from 'react-redux'
import GameContainer from '../../common/GameContainer'
import SubscribeForm from '../components/SubscribeForm'
import BrandImage from '../components/BrandImage'
import * as WebBrowser from 'expo-web-browser';
import { trackClick } from '../../../mixins/Track'

const mapStateToProps = () => {
  return {
  }
}

const mapDispatchToProps = {
}

class SubscribeContainer extends Component {
  constructor(props) {
    super(props)
    this._transitionToArticle = this._transitionToArticle.bind(this)
    this._transitionToExit = this._transitionToExit.bind(this)
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
  }

  _transitionToArticle() {
    trackClick({ source: 'View Article Button Click', value: 'https://financialstress.com/how-is-my-financial-health/'})
    WebBrowser.openBrowserAsync('https://financialstress.com/how-is-my-financial-health/');
  }

  _transitionToExit() {

  }

  render() {
    return (
     <GameContainer>
        <BrandImage />
        <SubscribeForm
          transitionToArticle={this._transitionToArticle}
          transitionToExit={this._transitionToExit}
        />
     </GameContainer>
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscribeContainer)
