import logger from './logger'
import thunk from './thunk'

const middleware = [
  thunk
]

// logger is undefined in production environments
if (logger) middleware.push(logger)

export default middleware
