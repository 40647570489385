import React, { Component } from 'react'
import { styles } from './SharedStyle.js'
import { Dimensions, View, Image } from 'react-native'
import ProgressBar from 'react-native-progress/Bar';
import BrowserSniff from '../../common/BrowserSniff'

const QuizProgressBar = (props) => {
  const { isMobileLargeDevice, isDesktopDevice, isMobileSmallDevice } = BrowserSniff()
  const { progress, color } = props
  if(isMobileLargeDevice) {
    return (
    <View style={[styles.progressBar, {marginBottom: 20}]}>
        <ProgressBar
          borderColor={'#EBEBEB'}
          color={color || '#69B679'}
          unfilledColor={'#EBEBEB'}
          progress={progress}
          borderRadius={2}
          height={11}
          width={350}
        />
      </View>
    )
  }

  if(isMobileSmallDevice) {
    return (
      <View style={[styles.progressBar, {marginBottom: 20}]}>
        <ProgressBar
          borderColor={'#EBEBEB'}
          color={color || '#69B679'}
          unfilledColor={'#EBEBEB'}
          progress={progress}
          borderRadius={2}
          height={11}
          width={325}
        />
      </View>
    )
  }
  return (
    <View style={[styles.progressBar, {marginBottom: 20}]}>
      <ProgressBar
        borderColor={'#EBEBEB'}
        color={color || '#69B679'}
        unfilledColor={'#EBEBEB'}
        progress={progress}
        borderRadius={2}
        height={11}
        width={350}
      />
    </View>
  )
}

export default QuizProgressBar
