import React, { Component } from 'react'
import { Container, Header, Content, Button, Text } from 'native-base';
import { styles } from './SharedStyle.js'
import { Image, View, ImageBackground } from 'react-native';
import BrowserSniff from '../../common/BrowserSniff'

const backgroundUri = require('./images/background.png')
const textUri = require('./images/text.png')

const MatchHeader = (props) => {
  const { isMobileLargeDevice, isDesktopDevice, isMobileSmallDevice } = BrowserSniff()

  if(isMobileLargeDevice) {
    return (
      <View>
        <ImageBackground
          style={styles.featuredImageMobileLarge}
          source={backgroundUri}
        >
        <Image
          style={{ alignItems: 'center', justifyContent: 'center', width: "100%", height: 125, zIndex: 10000}}
          source={textUri}
        />
      </ImageBackground>
    </View>
    )
  }

  if(isMobileSmallDevice) {
    return (
      <View>
        <ImageBackground
          style={styles.featuredImageMobileSmall}
          source={backgroundUri}
        >
        <Image
          style={{ alignItems: 'center', justifyContent: 'center', width: "100%", height: 100, zIndex: 10000}}
          source={textUri}
        />
      </ImageBackground>
    </View>
    )
  }

  return (
    <View>
      <ImageBackground
        style={styles.featuredImage}
        source={backgroundUri}
      >
      <Image
        style={{ alignItems: 'center', justifyContent: 'center', width: "100%", height: 200, zIndex: 10000}}
        source={textUri}
      />
    </ImageBackground>
  </View>
  )
}

export default MatchHeader
