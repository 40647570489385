import React, { Component } from 'react'
import { styles } from './SharedStyle.js'
import { Dimensions, View, Image, Text, ImageBackground } from 'react-native'
import { Button } from 'native-base'
import BrowserSniff from '../../common/BrowserSniff'
import LaunchForm from '../../launch/components/LaunchForm'
import Footer from '../../launch/components/Footer'
const backgroundUri = require('./images/background.png')
const overlayUri = require('./images/success.png')
import GameContainer from '../../common/GameContainer'
import BrandImage from './BrandImage'
import QuizProgressBar from './QuizProgressBar'

const GreatNews = (props) => {
  const { isMobileLargeDevice, isDesktopDevice, isMobileSmallDevice } = BrowserSniff()
  const { progress, target, transitionToLoans, removeFieldsFromView, interpolated, transitionToGame, onChangeEmail,
         onChangeZipCode, state, eventHandler, errors, footerText, handlePolicyClick, handleTermsClick  } = props

    if(isMobileLargeDevice) {
      return (
        <View style={{height: 1200, backgroundColor: "#fff"}}>
        <View>
          <ImageBackground
            style={styles.featuredImageMobileLarge}
            source={{
              uri: backgroundUri,
            }}
          >
          <Image
            style={{ alignItems: 'center', justifyContent: 'center', width: "100%", height: 225, zIndex: 10000}}
            source={{
              uri: overlayUri,
            }}
          />
        </ImageBackground>
        <View style={styles.action}>
          <Text style={styles.headerText}>Great News!</Text>
          <View style={{ textAlign: 'center', marginLeft: 50, marginRight: 50 }} >
            <Text style={styles.actionText}>We think a personal loan would help you with your personal finances.</Text>
            <Text style={styles.actionText}>To customize loan offers, we'll need a little more information.</Text>
          </View>
        </View>
        <LaunchForm
          actionBtnText={"Match with Loan Offers"}
          removeFieldsFromView={false}
          transitionToGame={transitionToGame}
          onChangeZipCode={onChangeZipCode}
          onChangeEmail={onChangeEmail}
          errors={errors}
          interpolated={interpolated}
          state={state}
          eventHandler={eventHandler}
        />
        <Footer
          text={footerText}
          handlePolicyClick={handlePolicyClick}
          handleTermsClick={handleTermsClick}
          />
        </View>
      </View>
      )
    }

    if(isMobileSmallDevice) {
      return (
        <View style={{height: 1200, backgroundColor: "#fff"}}>
        <View>
          <ImageBackground
            style={styles.featuredImageMobileSmall}
            source={{
              uri: backgroundUri,
            }}
          >
          <Image
            style={{ alignItems: 'center', justifyContent: 'center', width: "100%", height: 175, zIndex: 10000}}
            source={{
              uri: overlayUri,
            }}
          />
        </ImageBackground>
        <View style={styles.action}>
          <Text style={styles.headerText}>Great News!</Text>
          <View style={{ textAlign: 'center', marginLeft: 50, marginRight: 50 }} >
            <Text style={styles.actionTextSmall}>We think a personal loan would help you with your personal finances.</Text>
            <Text style={styles.actionTextSmall}>To customize loan offers, we'll need a little more information.</Text>
          </View>
        </View>
        <LaunchForm
          actionBtnText={"Match with Loan Offers"}
          removeFieldsFromView={false}
          transitionToGame={transitionToGame}
          onChangeZipCode={onChangeZipCode}
          onChangeEmail={onChangeEmail}
          errors={errors}
          interpolated={interpolated}
          state={state}
          eventHandler={eventHandler}
        />
        <Footer
          text={footerText}
          handlePolicyClick={handlePolicyClick}
          handleTermsClick={handleTermsClick}
          />
          </View>
      </View>
      )
    }

    return (
      <GameContainer>
        <ImageBackground
          style={styles.featuredImage}
          source={{
            uri: backgroundUri,
          }}
        >
        <Image
          style={{ alignItems: 'center', justifyContent: 'center', width: "100%", height: 300, zIndex: 10000}}
          source={{
            uri: overlayUri,
          }}
        />
      </ImageBackground>
      <View style={styles.action}>
        <Text style={styles.headerText}>Great News!</Text>
        <View style={{ textAlign: 'center', marginLeft: 50, marginRight: 50 }} >
          <Text style={styles.actionText}>We think a personal loan would help you with your personal finances.</Text>
          <Text style={styles.actionText}>To customize loan offers, we'll need a little more information.</Text>
        </View>
      </View>
      <LaunchForm
        actionBtnText={"Match with Loan Offers"}
        removeFieldsFromView={false}
        transitionToGame={transitionToGame}
        onChangeZipCode={onChangeZipCode}
        onChangeEmail={onChangeEmail}
        errors={errors}
        interpolated={interpolated}
        state={state}
        eventHandler={eventHandler}
      />
      <Footer
        text={footerText}
        handlePolicyClick={handlePolicyClick}
        handleTermsClick={handleTermsClick}
        />
    </GameContainer>
    )
}

export default GreatNews
