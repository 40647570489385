import React, { Component } from 'react'
import { styles } from './SharedStyle.js'
import { Text, View, Image } from 'react-native';
import BrowserSniff from '../../common/BrowserSniff'
const imageUri = require('./images/logo.png')

const SubHeader = (props) => {
  const { isMobileLargeDevice, isDesktopDevice, isMobileSmallDevice } = BrowserSniff()
  const { logoClicked } = props

  if(isMobileLargeDevice) {
    return (
      <View style={{marginLeft: '125px', marginRight: '125px',flexDirection: 'row', flex: 1, alignItems: 'center', justifyContent: 'center'}}>
        <Text
          style={styles.subHeaderText}>
          Presented by:
        </Text>
        <Image
          onPress={logoClicked}
          style={{marginLeft: 10, marginTop: 10, width: 110, height: 20}}
          source={imageUri} />

     </View>
    )
  }

  if(isMobileSmallDevice) {
    return (
      <View style={{marginLeft: '125px', marginRight: '125px',flexDirection: 'row', flex: 1, alignItems: 'center', justifyContent: 'center'}}>
        <Text
          style={styles.subHeaderText}>
          Presented by:
        </Text>
        <Image
          onPress={logoClicked}
          style={{marginLeft: 10, marginTop: 10, width: 110, height: 20}}
          source={imageUri} />

     </View>
    )
  }

  return (
    <View style={{marginLeft: '125px', marginRight: '125px',flexDirection: 'row', flex: 1, alignItems: 'center', justifyContent: 'center'}}>
      <Text
        style={styles.subHeaderText}>
        Presented by:
      </Text>
      <Image
        onPress={logoClicked}
        style={{marginLeft: 10, marginTop: 10, width: 110, height: 20}}
        source={imageUri} />

   </View>
  )
}

export default SubHeader
