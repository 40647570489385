import React, { Component } from 'react'
import { styles } from './SharedStyle.js'
import { Image, Text, Dimensions, SafeAreaView, View } from 'react-native';
import { Card, CardItem, Body } from 'native-base';
import validate from 'valib'
import BrowserSniff from './BrowserSniff'

const SCREEN_WIDTH = Dimensions.get('window').width;

const QuizCard = (props) => {
  const { image, question, subQuestion, description, category } = props
  const { isMobileLargeDevice, isDesktopDevice, isMobileSmallDevice } = BrowserSniff()

  if(isMobileLargeDevice) {
    return (
      <SafeAreaView style={styles.card}>
        <Image
          source={image}
          style={styles.featuredImage}
        />
      </SafeAreaView>
    )
  }

  if(isMobileSmallDevice) {
    return (
      <SafeAreaView style={styles.card}>
        <Image
          source={image}
          style={styles.mobileFeaturedImage}
        />
      </SafeAreaView>
    )
  }

  return (
    <SafeAreaView style={styles.card}>
      <Image
        source={image}
        style={styles.featuredImage}
      />
    </SafeAreaView>
  )
}


export default QuizCard
