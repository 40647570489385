import React, { Component } from 'react'
import { styles } from './SharedStyle.js'
import { Dimensions, View, Image, Text, ScrollView, Picker,
  TextInput, TouchableHighlight } from 'react-native'
import { Button } from 'native-base'
import Icon from 'react-native-vector-icons/FontAwesome';
import { TextInputMask } from 'react-native-masked-text'
import AutoCompleteSelector from './../AutoCompleteSelector'
import StateData from '../../../mocks/StateData'
import { Ionicons } from '@expo/vector-icons'
import { pickerSelectStyles } from './CustomMenuStyle'
import RNPickerSelect, { defaultStyles } from 'react-native-picker-select'
import '@expo/match-media'
import MediaQuery from 'react-responsive'
import BrowserSniff from '../BrowserSniff'

const ID = 3
const stateDataplaceholder = {
  label: "Choose one...",
  value: "choose",
  color: '#C3E0BE',
}

class AddressForm extends Component {

  constructor(props) {
    super(props)
    this._address1Ref = null
    this._address2Ref = null
    this._cityRef = null
    this._zipcodeRef = null
    this._stateRef = null
    this.state = {
      visible: true
    }
    this._autoCompleteExitHandler = this._autoCompleteExitHandler.bind(this)
  }

  _autoCompleteExitHandler() {
    this.setState({ visible: false })
  }

  render() {
    const { transitionToAdDisclosure, onFocus, visibleScreen, onTextChange, onPickerChange, state, autoCompleteHandler, screenMap } = this.props
    const { address1Valid, address2Valid, zipcodeValid, cityValid, stateAbvValid, address1, address2, zipcode, city, stateAbv, autoCompleteResults } = state
    const { visible } = this.state
    const hasAutoCompleteResults = (autoCompleteResults!== undefined && autoCompleteResults.predictions !== undefined &&  autoCompleteResults.predictions.length > 0)
    if (visibleScreen !== ID) { return null }

    if(visible && hasAutoCompleteResults) {
      return (
        <AutoCompleteSelector
          address1={address1}
          autoCompleteHandler={autoCompleteHandler}
          autoCompleteResults={autoCompleteResults}
          autoCompleteExitHandler={this._autoCompleteExitHandler}
          onTextChange={onTextChange}
         />
      )
    }
    return (
      <View style={{justifyContent:'center', alignItems: 'center'}}>
        <Header />

        <View>
          <Text style={styles.stepText}>Step {ID-1} of {screenMap.length-1}</Text>
          <Text style={styles.stepDisclosureText} onPress={transitionToAdDisclosure}>Advertiser Disclosure</Text>
        </View>

        <View style={{width: '65%', marginTop: 10}}>
          <TextInput
            ref={ref => this._address1Ref = ref}
            style={address1.valid ? styles.itemValid : address1.error ? styles.itemError : styles.item}
            placeholder="Enter your street address"
            value={address1.value}
            onChangeText={(value) => onTextChange('address1', value)}
          />
        </View>

        <View style={{width: '65%'}}>
          <TextInput
            ref={ref => this._address2Ref = ref}
            style={address2.valid ? styles.itemValid : address2.error ? styles.itemError : styles.item}
            placeholder="apt, unit, etc"
            value={address2.value}
            onChangeText={(value) => onTextChange('address2', value)}
          />
        </View>

        <View style={{width: '65%'}}>
          <TextInputMask
            type={'custom'}
            options={{
              /**
               * mask: (String | required | default '')
               * the mask pattern
               * 9 - accept digit.
               * A - accept alpha.
               * S - accept alphanumeric.
               * * - accept all, EXCEPT white space.
              */
              mask: '99999'
            }}
            keyboardType={undefined}
            ref={ref => this._zipcodeRef = ref }
            style={zipcode.valid ? styles.itemValid : zipcode.error ? styles.itemError : styles.item}
            placeholder="ZIP Code"
            value={zipcode.value}
            onFocus={() => onFocus('zipcode')}
            onChangeText={(value) => onTextChange('zipcode', value)}
            onSubmitEditing={() => {

           }}
          />
        </View>

        <View style={{width: '65%'}}>
          <TextInput
            ref={ref => this._cityRef = ref }
            style={city.valid ? styles.itemValid : city.error ? styles.itemError : styles.item}
            placeholder="City"
            value={city.value}
            onChangeText={(value) => onTextChange('city', value)}
            onSubmitEditing={() => {

           }}
          />
        </View>

        <View style={{ width: '65%', marginTop: 10}}>
          <RNPickerSelect
                placeholder={stateDataplaceholder}
                items={StateData}
                selectedValue={stateAbv.value}
                onValueChange={(value) => onPickerChange('stateAbv', value)}
                style={stateAbv.valid ?
                  {
                    inputAndroid: {
                      fontSize: 14,
                      borderWidth: '2px',
                      borderStyle: 'solid',
                      borderColor: '#69B679',
                      borderRadius: 22,
                      color: '#101D42',
                      padding: 10,
                      paddingRight: 30,
                    },
                    iconContainer: {
                      top: 10,
                      right: 12,
                    },
                  }  :
                  {
                    inputAndroid: {
                      fontSize: 14,
                      borderWidth: '2px',
                      borderStyle: 'solid',
                      borderColor: '#C3E0BE',
                      borderRadius: 22,
                      color: '#101D42',
                      padding: 10,
                      paddingRight: 30,
                    },
                    iconContainer: {
                      top: 10,
                      right: 12,
                    },
                  }}
                value={stateAbv.value}
                useNativeAndroidPickerStyle={false}
                textInputProps={{ underlineColor: '#101D42' }}
                Icon={() => {
                  return <Ionicons name="md-arrow-down" size={24} style={{ marginRight: 5 }} color="grey" />;
                }}
              />
        </View>
      </View>
    )
  };
}

const Header = () => {
  const { isMobileLargeDevice, isDesktopDevice, isMobileSmallDevice } = BrowserSniff()
  return <Text style={styles.headerTextMobileLarge}>What is Your Address?</Text>
}

export default AddressForm
