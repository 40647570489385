import React, { Component, useRef } from 'react'
import { styles } from './SharedStyle.js'
import { Animated, Dimensions, View, Image, Text, TouchableOpacity, ScrollView } from 'react-native'
import { Button } from 'native-base'
import Icon from 'react-native-vector-icons/FontAwesome'
import BrowserSniff from '../../common/BrowserSniff'
import LoanProviderInfo from '../../../mocks/LoanProviderInfo'
import Loader from 'react-loader-spinner'
import * as Animatable from 'react-native-animatable'
import Swipeable from 'react-native-gesture-handler/Swipeable'

const PreloadingCard = (props) => {
  const { isMobileLargeDevice, isDesktopDevice, isMobileSmallDevice } = BrowserSniff()

  if(isMobileLargeDevice || isMobileSmallDevice) {
  return (
    <View style={[styles.offerCardMobileLarge, { left: 50, marginRight: 10, backgroundColor: '#fff' }]}>
      <View style={{flex: 2, justifyContent: 'center', alignItems: 'center'}}>
        <Loader type="ThreeDots" color="rgba(105, 182, 121, 1.0)" height="50" width="75" />
        <Animatable.View
          animation="pulse"
          delay={1400}
          iterationCount="infinite"
          easing="lightSpeedIn">
          <Text style={styles.preLoadingOfferText}>Loading Offers</Text>
        </Animatable.View>
      </View>
    </View>
    )
  }
  return (
    <View style={[styles.offerCard, { left: 40, marginRight: 10, backgroundColor: '#fff' }]}>
      <View style={{flex: 2, justifyContent: 'center', alignItems: 'center'}}>
        <Loader type="ThreeDots" color="rgba(105, 182, 121, 1.0)" height="50" width="75" />
        <Animatable.View
          animation="pulse"
          delay={1400}
          iterationCount="infinite"
          easing="lightSpeedIn">
          <Text style={styles.preLoadingOfferText}>Loading Offers</Text>
        </Animatable.View>
      </View>
    </View>
  )
}

export default PreloadingCard
