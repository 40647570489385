const getCreditRatingFromScore = (creditScore) => {
  let rating = ''
  if (creditScore >= 720 ) {
    rating = 'excellent'
  } else if (creditScore <= 719 && creditScore >= 660) {
    rating = 'good'
  } else if (creditScore <= 659 && creditScore >= 620) {
    rating = 'fair'
  } else {
    rating = 'poor'
  }
  return rating
}

const getCreditRatingFromSlider = (id) => {
  if(id === 1) {
    return 'Poor (< 620)'
  } else if(id === 2) {
    return 'Fair (620 - 659)'
  } else if(id === 3) {
    return 'Good (660 - 719)'
  } else if(id === 4) {
    return 'Excellent (720 +)'
  } else {
    return ''
  }
}

const getCreditEnumFromKey = (id) => {
  if(id === 1) {
    return 'poor'
  } else if(id === 2) {
    return 'fair'
  } else if(id === 3) {
    return 'good'
  } else if(id === 4) {
    return 'excellent'
  } else {
    return ''
  }
}
export { getCreditRatingFromScore, getCreditRatingFromSlider, getCreditEnumFromKey }
