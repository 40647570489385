import actions from '../constants'

export default function session(state = [], action) {
  switch (action.type) {
    default:
      return state


  case actions.IDENTIFY_LEAD_REQUEST:
    return {
      ...state,

    }

  case actions.IDENTIFY_LEAD_FAILURE:
    return {
      ...state,
      error: action.error
    }

  case actions.IDENTIFY_LEAD_SUCCESS:
    return {
      ...state,
      lead: action.lead
    }
  }
};
