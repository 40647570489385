import EventDomain from '../client/domain/EventDomain'
import Constants from 'expo-constants';
import * as Analytics from 'expo-firebase-analytics';
import AsyncStorage from '@react-native-community/async-storage'

const eventDomain = new EventDomain()

const trackPageView = (data = {}) => {
  Analytics.setCurrentScreen(data.source)
}

const trackUserProperty = (key, value) => {
  Analytics.setUserProperty(key, value)
}

const setUserID = (uuid) => {
  Analytics.setUserId(uuid)
}
const trackCardView = (data = {}) => {
  consume(data.source, {
    name: data.source,
    screen: 'Quiz',
  })
}

const trackAnswer = (data = {}) => {
  consume(data.source, {
    name: data.source,
    screen: 'Quiz',
    value: data.value
  })
}

const trackFocus = (data = {}) => {
  consume(data.source, {
    name: data.source,
  })
}

const trackClick = (data = {}) => {
  consume(data.source, {
    name: data.source,
  })
}

const trackSubmit = (data = {}) => {
  consume(data.source, {
    name: data.source,
  })
}

const trackError = (data = {}) => {
  consume(data.source, {
    name: data.source,
  })
}

const trackLoanTool = (data = {}) => {
  consume(data.source, {
    name: data.source,
    screen: 'Loan Tool',
    value: data.value
  })
}

const consume = (source, data) => {
  Analytics.logEvent(source, data)
}
export { trackPageView, trackFocus, trackSubmit, trackClick,
  trackError,  trackAnswer, trackCardView, trackUserProperty,
  setUserID }
