const b64Digits = [
  'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', // 0 -9
  'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', // 10 - 19
  'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd', // 20 - 29
  'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', // 30 - 39
  'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', // 40 - 49
  'y', 'z', '0', '1', '2', '3', '4', '5', '6', '7', // 50 - 59
  '8', '9', '+', '/' // 60 - 63
]
const hexDigits = [
  '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A',
  'B', 'C', 'D', 'E', 'F'
]

class Random {
  static id(length = 16, base = 64, digitArray = b64Digits) {
    const digits = new Array(length)
    for (let ndx = 0; ndx < digits.length; ndx++) {
      digits[ndx] = Random.digit(base, digitArray)
    }
    return digits.join('')
  }

  static hexDigit() {
    return hexDigits[Random.integer(0, 15)]
  }

  static digit(base = 64, digitArray = b64Digits) {
    if (base > digitArray.length) {
      throw new Error(`Range Error: Base is out of range [0, ${digitArray.length}]`)
    }
    return digitArray[Random.integer(0, base - 1)]
  }

  static float(min = 0.0, max = 1.0) {
    return Math.random() * (max - min) + min
  }

  static integer(min = 0, max = 255) {
    min = Math.ceil(min)
    max = Math.floor(max) + 1
    return Math.floor(Math.random() * (max - min)) + min
  }

  static choice(choiceArray) {
    if (choiceArray.length === 0) {
      return null
    }

    return choiceArray[Random.integer(0, choiceArray.length - 1)]
  }
}

export default Random
